import { takeEvery, call, put, delay } from "redux-saga/effects";
import { ApiService } from "../services/ApiService";
import {
    TOPIC_PERFORMANCE_STRENGTH_AREAS,
    PREPSCORES,
    CONTINUE_WHERE_YOU_END,
    GET_EXAM_DATE,
    SET_EXAM_DATE_PUT,
    GET_EXAM_CHART_DATA,
    DRUG_CHART_DOCS,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {
    PERFORMANCE_AREA,
    PERFORMANCE_AREA_SUCCESS,
    PREPSCORE,
    GET_WHERE_YOU_LEFT,
    GET_EXAM_DATES,
    SET_EXAM_DATE,
    GET_CHART_DATA,
    GET_CHART_DATA_SUCCESS,
    GET_DRUG_CHART,
} from "../definitions/performaceConstants";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import { topicStrengthAreas } from "../models/Performance/Weakness.model";
import { topicWeakAreas } from "../models/Performance/Strength.model";
import { CourseModel } from "../models/Performance/CurrentCourse.model";
import { Date } from "../models/Performance/Date.mode";
import { Results } from "../models/Performance/Chart.model";
import { DrugCharts } from "../models/DrugChart/DrugChart.model";

const apiService = new ApiService();

/* Executor Sagas */

function* getPerformance(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            TOPIC_PERFORMANCE_STRENGTH_AREAS(courseId)
        );
        if (response.data) {
            const strength = deserialize(topicStrengthAreas, response.data)
                .topicStrengthAreas;
            const weakness = deserialize(topicWeakAreas, response.data)
                .topicWeakAreas;
            const payload = {
                weakness,
                strength,
            };
            yield put({ type: PERFORMANCE_AREA_SUCCESS, payload });
            successCallback();
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getPrepScore(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, PREPSCORES(courseId));
        if (response.data) {
            successCallback(response["data"].student_prep_score);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getWhereIEndLast(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, CONTINUE_WHERE_YOU_END(courseId));
        if (response?.data) {
            const course = deserialize(CourseModel, response?.data);
            successCallback(course.courseModules);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error?.response?.data;
        errCallback(error);
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getExamDate(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, GET_EXAM_DATE(courseId));
        if (response.data) {
            const date = deserialize(Date, response?.data);
            successCallback(date);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* setExamDate(action: any) {
    const { payload, successCallback, errCallback } = action.payload;
    const { courseId, date } = payload;
    const data = {
        subscription: {
            exam_date: date,
        },
    };
    let response: any;
    try {
        response = yield call(
            apiService.put,
            SET_EXAM_DATE_PUT(courseId),
            data
        );
        if (response.data) {
            const date = deserialize(Date, response?.data);
            successCallback(date);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getChartData(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;

    let response: any;
    try {
        response = yield call(apiService.get, GET_EXAM_CHART_DATA(courseId));
        if (response.data) {
            if(response.data === null) {
                successCallback(null);
            }            
            const results = deserialize(Results, response?.data);
            yield put({
                type: GET_CHART_DATA_SUCCESS,
                payload: results.examAttempts,
            });
            successCallback(results.examAttempts);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error?.response?.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getDrugChart(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, DRUG_CHART_DOCS(courseId));
        if (response.data) {
            const results = deserialize(DrugCharts, response?.data);
            successCallback(results.drugCharts);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

/*Watcher sagas*/

export default function* performanceSaga() {
    yield takeEvery(PERFORMANCE_AREA, getPerformance);
    yield takeEvery(PREPSCORE, getPrepScore);
    yield takeEvery(GET_WHERE_YOU_LEFT, getWhereIEndLast);
    yield takeEvery(GET_EXAM_DATES, getExamDate);
    yield takeEvery(SET_EXAM_DATE, setExamDate);
    yield takeEvery(GET_CHART_DATA, getChartData);
    yield takeEvery(GET_DRUG_CHART, getDrugChart);
}
