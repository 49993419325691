import {CreateReducer} from "../../shared/utils/createReducer";
import {
    LIST_COUNTRY_META_SUCCESS,
    LIST_COURSE_META_SUCCESS,
    SET_SELECTED_COURSE,
    SET_USER_COURSE_SUCCESS
} from "../definitions/metaConstants";
import {CountryMeta} from "../models/Meta/CountryMeta/countryMeta.model";
import { Course } from "../models/Meta/CourseMeta/CourseMeta.model";

interface IMetaState {
    countryMetas: CountryMeta[];
    courseMetas: Course[];
    course: Course | undefined;
    userCourse: any;
}

const initState: IMetaState = {
    countryMetas: [],
    courseMetas: [],
    course: undefined,
    userCourse: undefined
};

const metaReducer = CreateReducer(initState, {
    [LIST_COUNTRY_META_SUCCESS](state: any, action: any) {
        return {...state, countryMetas:action.payload};
    },
    [LIST_COURSE_META_SUCCESS](state: any, action: any) {
        return { 
            ...state,
            courseMetas: action.payload
        }
    },
    [SET_SELECTED_COURSE](state: any, action: any)  {
        return {
            ...state,
            course: action.payload
        }
    },
    [SET_USER_COURSE_SUCCESS](state: any, action: any) {
        return {
            ...state,
            userCourse: action.payload
        }
    }
});

export default metaReducer;
