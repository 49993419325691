import {alias, primitive, serializable, object } from "serializr";
import { ExamAttempt, ExamBody } from "./Attempt.model";


class Question {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('content', primitive()))
    content?: string;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('answer_explanation', primitive()))
    answerExplanation?: string;
}

export class Answer {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('answer', primitive()))
    answer?: string;

    @serializable(alias('correct_answer', primitive()))
    correctAnswer?: string;

    @serializable(alias('answer_value', primitive()))
    answerValue?: string;
    
    @serializable(alias('question',object(Question)))
    question?: Question;

    @serializable(alias('exam_attempt', object(ExamBody)))
    examAttempt?: ExamBody;
}

export class ExamAnswer {
    @serializable(alias('exam_answer', object(Answer)))
    examAnswer?: Answer;
}
