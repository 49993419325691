import React from "react";
import {
	CompositeDecorator,
	ContentState,
	convertFromHTML,
	EditorState,
} from "draft-js";

const findImageEntities = (
	contentBlock: any,
	callback: any,
	contentState: any
) => {
	contentBlock.findEntityRanges((character: any) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === "IMAGE"
		);
	}, callback);
};

export const createtHTMLBlock = (html: string) => {
	const ImageComponent = (props: any) => {
		const { height, src, width } = props.contentState
			.getEntity(props.entityKey)
			.getData();

		return (
			<img
				src={src}
				height={height}
				width={width}
				style={{
					pointerEvents: "none",
					cursor: "default",
				}}
			/>
		);
	};
	const decorator = new CompositeDecorator([
		{
			strategy: findImageEntities,
			component: ImageComponent,
		},
	]);
	const blocksFromHTML = convertFromHTML(html);
	const state = ContentState.createFromBlockArray(
		blocksFromHTML.contentBlocks,
		blocksFromHTML.entityMap
	);
	return EditorState.createWithContent(state, decorator);
};
