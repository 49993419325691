import { serializable, alias, primitive, object, list } from "serializr";
import { StudentModuleDetails } from "../Module/studentModuleDetails.model";
import { QuizBody } from "../Module/quiz.model";

class Content {
    @serializable(alias("url", primitive()))
    url?: string;
}

class StudentCourseDetails {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("user_id", primitive()))
    userId?: number;

    @serializable(alias("course_id", primitive()))
    coursecourseId?: number;
}

export class ActiveTopic {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("topic_id", primitive()))
    topicId?: number;

    @serializable(alias("topic_name", primitive()))
    topicName?: string;

    @serializable(alias("topic_draft_id", primitive()))
    topicDraftId?: number;

    @serializable(alias("sort", primitive()))
    sort?: number;

    @serializable(alias("course_module_id", primitive()))
    courseModuleId?: number;

    @serializable(alias("video_url", primitive()))
    videoUrl?: string;

    @serializable(alias("video_embed_tag", primitive()))
    videoEmbedTag?: string;

    @serializable(alias("content", object(Content)))
    content?: Content;

    @serializable(alias("weight", primitive()))
    weight?: number;

    @serializable(alias("completion", primitive()))
    completion?: Float32Array;

    @serializable(alias("average", primitive()))
    average?: Float32Array;

    @serializable(alias("updated_at", primitive()))
    updatedAt?: string;
}

export class Course {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("completion", primitive()))
    completion?: Float32Array;

    @serializable(alias("active_topic", object(ActiveTopic)))
    activeTopic?: ActiveTopic;

    @serializable(
        alias("student_course_details", list(object(StudentCourseDetails)))
    )
    studentCourseDetails?: StudentCourseDetails[];

    @serializable(alias("course_modules", list(object(QuizBody))))
    courseModules?: QuizBody[];
}

export class CourseModel {
    @serializable(alias("course", object(Course)))
    courseModules?: Course;
}
