import React, { useEffect, Fragment, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MetaContainer from "../../../store/containers/metaContainer";
import ModuleContainer from "../../../store/containers/moduleContainer";
import BackButton from "../../../shared/components/BackButton";
import { Button } from "semantic-ui-react";
import { QUIZ, VIDEO } from "../../../routes/routeConstants/appRoutes";
import { pdfjs } from "react-pdf";
import Viewer, {
	SelectionMode,
	SpecialZoomLevel,
} from "@phuocng/react-pdf-viewer";
import Loader from "../../../shared/components/Loader";
import { layout } from "./PDFLayout";
import "./viewPDF.scss";
import NoPdfFound from "./NoPdfFound";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import "./viewPDF.scss";

const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry").default;

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface IViewPDF extends RouteComponentProps {
	course: any;
	getTopic: Function;
	topic: any;
	match: any;
}

function ViewPDF({
	history,
	match: { params },
	course,
	getTopic,
	topic,
}: IViewPDF) {
	const [numPages, setNumPages] = useState<any>(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [scale, setScale] = useState(1.5);
	useEffect(() => {
		const param: any = params;
		if (course) {
			setLoading(true);
			getTopic(
				course.id,
				param.id,
				(data: any) => {
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		}
	}, [course]);

	const handleTakeQuiz = () => {
		history.push({
			pathname: QUIZ(topic.id),
			state: {
				back: "to Topics",
			},
		});
	};

	const handleVideo = () => {
		history.push({
			pathname: VIDEO(topic.id),
			state: {
				back: "to Topics",
			},
		});
	};

	document.addEventListener("contextmenu", (event) => {
		event.preventDefault();
	});

	return (
		<div className="pdf-viewer__container">
			<Loader loading={loading} />
			<div className="topic-redirect">
				<div className="topic-top watch-video">
					<p>Watch lecture video</p>
					<Button primary onClick={handleVideo}>
						Watch
					</Button>
				</div>
				<div className="topic-top test-model">
					<p>Ready to test yourself?Take a quiz</p>
					<Button primary onClick={handleTakeQuiz}>
						Take
					</Button>
				</div>
			</div>
			<div className="pdf-view application-body">
				<div className="options">
					<BackButton text="Back to Module" />
					<div className="download"></div>
				</div>
				<div className="view-pdf">
					{topic ? (
						<Fragment>
							{topic?.content?.url ? (
								<Viewer
									fileUrl={topic?.content?.url}
									selectionMode={SelectionMode.Text}
									defaultScale={SpecialZoomLevel.ActualSize}
									layout={layout}
									onPageChange={(pageNumber: any) => {
										setPageNumber(pageNumber);
									}}
									renderError={(error) => {
										return (
											<div
												style={{ textAlign: "center" }}
											>
												Can't able to load PDF
											</div>
										);
									}}
								/>
							) : (
								<NoPdfFound />
							)}
						</Fragment>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default withRouter(MetaContainer(ModuleContainer(ViewPDF)));
