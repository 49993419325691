import { ApiService } from "../services/ApiService";
import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_ATTEMPT, GET_EXAM_QUESTIONS, UPDATE_ANSWER_OPTION, GET_RESULTS, GET_SINGLE_QUESTION } from "../../routes/routeConstants/apiRoutes";
import { ExamAttempt } from "../models/Exam/Attempt.model";
import { deserialize } from "serializr";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import { SET_EXAM_ATTEMPT_SUCCESS, SET_EXAM_ATTEMPT, SET_QUESTIONS, SET_QUESTIONS_SUCCESS, UPDATE_ANSWER, SET_RESULTS_SUCCESS, SET_RESULTS, GET_ANSWER, GET_A_QUESTION, SET_QUESTION_NO } from "../definitions/examConstants";
import { Questions } from "../models/Exam/Question.model";
import {  ExamAnswer } from "../models/Exam/Answer.model";
import { Results } from "../models/Exam/Result.model";
import { QuestionWithAnswer } from "../models/Exam/QuestionAnswer.model";
import * as QuestionDefinitions from "../definitions/questionContants";

const apiService = new ApiService();

function* postExamAttempt(action: any) {
    const { id, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.post, CREATE_ATTEMPT(id), {});
        if (response.data) {
            const examAttempy = deserialize(ExamAttempt, response.data);
            yield put({ type: SET_EXAM_ATTEMPT_SUCCESS, payload: examAttempy.examAttempt });
            yield put({ type: SET_QUESTION_NO, payload: 0 });
            successCallback(examAttempy.examAttempt);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback(errorResponse);
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* getExamQuestions(action: any) {
    const { id, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, GET_EXAM_QUESTIONS(id));
        if (response.data) {
            const questions = deserialize(Questions, response.data);
            yield put({ type: SET_QUESTIONS_SUCCESS, payload: questions.questions });
            yield put({
                type: QuestionDefinitions.SET_QUESTIONS,
                payload: questions.questions,
            });
            successCallback();
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {

        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* updateQuestionOption(action: any) {
    const { id, data, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.put, UPDATE_ANSWER_OPTION(id), data);
        if (response.data) {
            const answer = deserialize(ExamAnswer, response.data);
            // yield put({type: SET_QUESTIONS_SUCCESS, payload: answer});
            successCallback(answer.examAnswer);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* getQuestionOption(action: any) {
    const { id, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, UPDATE_ANSWER_OPTION(id));
        if (response.data) {
            const answer = deserialize(ExamAnswer, response.data);
            // yield put({type: SET_QUESTIONS_SUCCESS, payload: answer});
            successCallback(answer.examAnswer);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {

        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* getResults(action: any) {
    const { id, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, GET_RESULTS(id));
        if (response.data) {
            const results = deserialize(Results, response.data);
            yield put({ type: SET_RESULTS_SUCCESS, payload: results });
            console.log(successCallback);
            successCallback();
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {

        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* getSingleQuestion(action: any) {
    const { examAttemptId, questionId, successCallback, errCallback } = action.payload;

    let response: any;
    try {
        response = yield call(apiService.get, GET_SINGLE_QUESTION(examAttemptId, questionId));
        if (response.data) {
            const question = deserialize(QuestionWithAnswer, response.data);
            successCallback(question.question);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}


export default function* ExamSaga() {
    yield takeEvery(SET_EXAM_ATTEMPT, postExamAttempt);
    yield takeEvery(SET_QUESTIONS, getExamQuestions);
    yield takeEvery(GET_ANSWER, getQuestionOption);
    yield takeEvery(UPDATE_ANSWER, updateQuestionOption);
    yield takeEvery(SET_RESULTS, getResults);
    yield takeEvery(GET_A_QUESTION, getSingleQuestion);
}



