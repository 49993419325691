import { serializable, alias, primitive, list, object } from "serializr";

export class BlogTag {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("blog_name", primitive()))
    blogName?: string;

    @serializable(alias("tag_name", primitive()))
    tagName?: string;
}

class Image {
    @serializable(alias("url", primitive()))
    url?: string;
}

export class Blog {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("title", primitive()))
    title?: string;

    @serializable(alias("view_count", primitive()))
    viewCount?: string;

    @serializable(alias("content", primitive()))
    content?: string;

    @serializable(alias("banner_content", primitive()))
    bannerContent?: string;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("image", object(Image)))
    image?: Image;

    @serializable(alias("blog_tags", list(object(BlogTag))))
    blogTags?: BlogTag[];
}

export class Blogs {
    @serializable(alias("blogs", list(object(Blog))))
    blogs?: Blog[];
}
