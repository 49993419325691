import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
	getStrengthAndWeakness,
	getPrepScore,
	getWhereYouLeft,
	getExamDate,
	setExamDate,
	getChartData,
	getDrugChart
} from "../actions/performanceAction";

export default function PerformanceContainer(component: any) {
	return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators(
		{
			getStrengthAndWeakness,
			getPrepScore,
			getWhereYouLeft,
			getExamDate,
			setExamDate,
			getChartData,
			getDrugChart
		},
		dispatch
	);
}

function mapStateToProps(state: any, ownProps: any) {
	return {
		strength: state.performance.strength,
		weakness: state.performance.weakness,
		chart: state.performance.chart,
		...ownProps,
	};
}
