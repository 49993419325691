import React, { useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import "./styles/_main.scss";
import { Provider } from "react-redux";
import AppRoutes from "./routes";
import { AUTHENTICATED } from "./store/definitions/authConstants";
import { signOutAction } from "./store/actions/authActions";
import { store } from "./store";

let authToken, user;

if (localStorage.getItem("authToken")) {
  authToken = JSON.parse(localStorage.getItem("authToken") || "");
}

if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user") || "");
}

if (authToken && user) {
  store.dispatch({
    type: AUTHENTICATED,
    payload: { authToken, user },
  });
}

function App() {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
}

export default App;
