import React, { useEffect, useState, useCallback } from "react";
import Option from "./Option";
import UIButton from "../../../../shared/components/Button";
import examContainer from "../../../../store/containers/examContainer";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";
import {
	MOCK_EXAMS_RESULT,
	QUIZ,
} from "../../../../routes/routeConstants/appRoutes";
import Timer from "./Timer";
import "./questionPalette.scss";
import { Answer } from "../../../../store/models/Exam/Answer.model";
import { ExamBody } from "../../../../store/models/Exam/Attempt.model";
import Question from "./Question";
import ModuleContainer from "../../../../store/containers/moduleContainer";
import MetaContainer from "../../../../store/containers/metaContainer";
import Loader from "../../../../shared/components/Loader";
import { shuffle } from "lodash";
import { debug } from "console";
import { examMode } from "../../../ModulesLectures/ExamDescription/dist";
import { URLSearchParams } from "url";
import ButtonController from "../../Button";
import Parser from "html-react-parser";

type Mode = "study" | "mock" | "live";

interface IQuestionPalette extends RouteComponentProps {
	questions: Array<any>;
	questionNo: number;
	setVisited: Function;
	nextQuestion: Function;
	previousQuestion: Function;
	setFlag: Function;
	updateAnswer: Function;
	getAnswer: Function;
	getResults: Function;
	visitedQuestions: Array<any>;
	strikeThroungh: any;
	examSession: any;
	mode: Mode;
	results: any;
	calculatorHandler: any;
	getAQuestion: any;
	getSingleQuestion: Function;
	getAAnswer: Function;
	updateExamAnswer: Function;
	endTest: Function;
	course: any;
	calc: boolean;
	updateStrikeThrough: Function;
	reload: number;
}

function QuestionPalette({
	questions,
	questionNo,
	examSession,
	setVisited,
	nextQuestion,
	previousQuestion,
	setFlag,
	visitedQuestions,
	history,
	updateAnswer,
	getAnswer,
	results,
	strikeThroungh,
	calculatorHandler,
	mode,
	getResults,
	getAQuestion,
	getSingleQuestion,
	getAAnswer,
	course,
	updateExamAnswer,
	endTest,
	calc,
	updateStrikeThrough,
	reload,
}: IQuestionPalette) {
	const [selected, setSelected] = useState<string | undefined>(undefined);
	const [answerId, setAnswerId] = useState(undefined);
	const [choosed, setChoosed] = useState<any>(undefined);
	const [correct, setCorrect] = useState<any>(undefined);
	const [answer, setAnswer] = useState<any>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<any>([]);

	const RESULT_PAGE = `${MOCK_EXAMS_RESULT}${
		examSession?.quizMode ? `?type=${examSession?.quizMode}` : ""
	}`;

	useEffect(() => {
		console.log({ answer });
	}, [answer]);

	useEffect(() => {
		// setVisited(questions[questionNo].id, { visited: true });
		setOptions(questions[questionNo].optionKeys);
		if (
			examSession?.quizMode === "exam_mode" ||
			examSession?.quizMode === "study_mode"
		) {
			if (course) {
				setLoading(true);
				getSingleQuestion(
					course?.id,
					examSession?.id,
					questions[questionNo].id,
					(data: any) => {
						setAnswerId(data.examAnswerId.id);
						getAAnswer(
							course?.id,
							data.examAnswerId?.id,
							(data: Answer) => {
								setSelected(data.answer);
								if (examSession?.quizMode === "study_mode") {
									setChoosed(data?.answer);
									setCorrect(data?.correctAnswer);
									setAnswer(data);
								}
								setLoading(false);
							},
							() => {
								setLoading(false);
							}
						);
					},
					() => {
						setLoading(false);
					}
				);
			}
		} else {
			setLoading(true);
			getAQuestion(
				examSession.id,
				questions[questionNo].id,
				(data: any) => {
					setAnswerId(data.examAnswerId.id);
					getAnswer(
						data.examAnswerId.id,
						(data: Answer) => {
							setSelected(data.answer);
							setLoading(false);
						},
						() => {
							setLoading(false);
						}
					);
				},
				() => {
					setLoading(false);
				}
			);
		}
	}, [questionNo]);

	useEffect(() => {
		if (reload) {
			handleUpdateAnswer(() => {
				setChoosed(undefined);
				setCorrect(undefined);
			});
		}
	}, [reload]);

	const localStorageClean = () => {
		const user = localStorage.getItem("user") as string;
		const token = localStorage.getItem("authToken") as string;
		localStorage.clear();
		localStorage.setItem("user", user);
		localStorage.setItem("authToken", token);
	};

	const handleUpdateAnswer = (callback: Function) => {
		if (
			examSession?.quizMode === "exam_mode" ||
			examSession?.quizMode === "study_mode"
		) {
			if (course) {
				setLoading(true);
				updateExamAnswer(
					course.id,
					answerId,
					selected,
					(data: Answer) => {
						// setVisited(questions[questionNo].id, { visited: true });
						setSelected(data.answerValue);
						callback();
						setLoading(true);
					},
					() => {
						setLoading(true);
						endTest(
							course.id,
							examSession.id,
							() => {
								localStorageClean();
								history.push(RESULT_PAGE);
							},
							() => {
								setLoading(false);
							}
						);
						setLoading(false);
					}
				);
			}
		} else {
			setLoading(true);
			updateAnswer(
				answerId,
				selected,
				(data: Answer) => {
					// setVisited(questions[questionNo].id, { visited: true });
					setSelected(data.answerValue);
					callback();
					setLoading(false);
				},
				() => {
					getResults(
						examSession.id,
						() => {
							localStorageClean();
							history.push(RESULT_PAGE);
						},
						() => {}
					);
					setLoading(false);
				}
			);
		}
	};

	const handleTimeEnd = () => {
		setLoading(true);
		if (
			examSession?.quizMode === "exam_mode" ||
			examSession?.quizMode === "study_mode"
		) {
			endTest(
				course.id,
				examSession.id,
				() => {
					setLoading(false);
					localStorageClean();
					history.push(RESULT_PAGE);
				},
				() => {
					setLoading(false);
				}
			);
			return;
		}
		getResults(
			examSession.id,
			() => {
				setLoading(false);
				localStorageClean();
				history.push(RESULT_PAGE);
			},
			() => {
				setLoading(false);
			}
		);
	};

	const handleNextQuestion = () => {
		handleUpdateAnswer(() => {
			nextQuestion();
			setChoosed(undefined);
			setCorrect(undefined);
		});
	};

	const handlePreviousQuestion = () => {
		handleUpdateAnswer(() => {
			previousQuestion();
			setChoosed(undefined);
			setCorrect(undefined);
		});
	};

	const handleFlag = () => {
		setFlag(questions[questionNo].id);
	};

	const handleEndTest = () => {
		handleUpdateAnswer(() => {
			setLoading(true);
			if (
				examSession?.quizMode === "exam_mode" ||
				examSession?.quizMode === "study_mode"
			) {
				setLoading(true);
				endTest(
					course.id,
					examSession.id,
					() => {
						setLoading(false);
						localStorageClean();

						history.push(RESULT_PAGE);
					},
					() => {
						setLoading(false);
					}
				);
				return;
			}
			getResults(
				examSession.id,
				() => {
					setLoading(false);
					localStorageClean();
					history.push(RESULT_PAGE);
				},
				() => {
					setLoading(false);
				}
			);
		});
	};

	const handleSelect = (selected: string) => {
		setSelected(selected);
		setVisited(questions[questionNo].id, { visited: true });
		if (examSession?.quizMode === "study_mode") {
			updateExamAnswer(
				course.id,
				answerId,
				selected,
				() => {
					getSingleQuestion(
						course?.id,
						examSession?.id,
						questions[questionNo].id,
						(data: any) => {
							getAAnswer(
								course?.id,
								data.examAnswerId?.id,
								(data: Answer) => {
									setChoosed(data?.answer);
									setCorrect(data?.correctAnswer);
									setAnswer(data);
									setLoading(false);
								},
								() => {}
							);
						},
						() => {}
					);
				},
				() => {}
			);
		}
	};

	const onMouseUp = () => {
		const s = window.getSelection()?.toString();
		if (s === "") {
			return;
		}
	};

	const handleRightClick = (selected: string) => {
		updateStrikeThrough(selected, questionNo);
	};

	return (
		<div className="question-palette__container">
			<Loader loading={loading} />
			<div className="question-palette__container__header">
				<h1>
					{" "}
					{examSession?.quizMode === "study_mode"
						? "Study Mode"
						: examSession?.quizMode === "exam_mode"
						? "Exam Mode"
						: "Mock Exam"}
				</h1>
			</div>
			<div className="question-palette__inner__container">
				<div className="question-palette__header">
					<p>
						Question {questionNo + 1} of {questions.length}
					</p>
					<div className={mode === "study" ? "xs-disable" : ""}>
						{examSession?.quizMode === "study_mode" ? null : (
							<Timer
								seconds={examSession.durationInSec}
								timeEndHandler={handleTimeEnd}
							/>
						)}
					</div>
					<p
						className={`exam-mode ${
							mode === "mock" ? "xs-disable" : ""
						}`}
					>
						{examSession?.quizMode === "study_mode"
							? "Study Mode"
							: examSession?.quizMode === "exam_mode"
							? "Exam Mode"
							: "Mock Exam"}
					</p>
				</div>
				<div
					className={`question-palette__body ${
						questions[questionNo].questionType.category === "case"
							? "question-palette__split__screen"
							: ""
					}`}
				>
					<div
						className={`question-palette__case__left ${
							questions[questionNo].questionType.category ===
							"case"
								? ""
								: "question-no-padding remove-case__description"
						}`}
					>
						<Question
							text={questions[questionNo].questionType.content}
							mouseUpHandler={onMouseUp}
							questionNo={questionNo}
							type="left"
							questions={questions}
						/>
					</div>
					<div className="question-palette__case__right">
						<div className="question-palette__ques">
							<Question
								text={questions[questionNo].content}
								mouseUpHandler={onMouseUp}
								questionNo={questionNo}
								type="right"
							/>
							<div>
								<div
									className={`question-palette__calculator ${
										calc
											? "question-palette__calculator-active"
											: ""
									}`}
									onClick={calculatorHandler}
								>
									<i className="calculator icon" />
									Calculator
								</div>
							</div>
						</div>
						<div className="question-palette__options">
							{options?.map((option: any, index: number) => {
								if (examSession?.quizMode === "study_mode") {
									return (
										<Option
											key={index}
											position={index}
											index={option}
											name="selected"
											selectHandler={handleSelect}
											selected={option === selected}
											correct={option === correct}
											isCorrect={correct}
											rightClickHandler={handleRightClick}
											mode={examSession?.quizMode}
											wrong={
												option === choosed &&
												choosed !== correct
											}
										>
											{
												questions[questionNo].options[
													option
												]
											}
										</Option>
									);
								}
								return (
									<Option
										key={index}
										position={index}
										index={option}
										name="selected"
										selectHandler={handleSelect}
										selected={option === selected}
										rightClickHandler={handleRightClick}
										isCorrect={correct}
										strikeThrough={
											strikeThroungh[`a${questionNo}`][
												option
											]
										}
										mode={examSession?.quizMode}
									>
										{questions[questionNo].options[option]}
									</Option>
								);
							})}
						</div>
					</div>
				</div>

				<div className="question-palette__footer">
					<div className="question-palette__controllers">
						{/* <UIButton
							type="submit"
							icon="arrow left icon"
							iconLeft
							classes="question-palette-xl"
							disabled={questionNo === 0}
							clickHandler={handlePreviousQuestion}
						>
							Previous
						</UIButton> */}
						<ButtonController
							icon="arrow left icon"
							classes="question-palette-controller-xl"
							disabled={questionNo === 0}
							clickHandler={handlePreviousQuestion}
						>
							Previous
						</ButtonController>
						<ButtonController
							icon="arrow left icon"
							classes="question-palette-controller-sm"
							disabled={questionNo === 0}
							clickHandler={handlePreviousQuestion}
						>
							Prev
						</ButtonController>
						<ButtonController
							icon="flag icon"
							iconPrimary
							clickHandler={handleFlag}
						>
							{visitedQuestions[questions[questionNo].id].flagged
								? "Unflag"
								: "Flag"}
						</ButtonController>
						<ButtonController
							icon="arrow right icon"
							disabled={questionNo === questions.length - 1}
							clickHandler={handleNextQuestion}
							iconRight
						>
							Next
						</ButtonController>
					</div>
					<UIButton
						type="submit"
						secondary
						classes="end-quiz"
						clickHandler={handleEndTest}
					>
						End Quiz
					</UIButton>
					{/* )} */}
				</div>
				{examSession?.quizMode === "study_mode" && choosed ? (
					<div className="display-exam-answer">
						<p className="answer-explanation__text">Explanation</p>
						{Parser(answer?.question?.answerExplanation)}
					</div>
				) : null}
			</div>
		</div>
	);
}

export default withRouter(
	examContainer(ModuleContainer(MetaContainer(QuestionPalette)))
);
