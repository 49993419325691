import {alias, primitive, serializable, object} from "serializr";

export class ExamBody {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('user_id', primitive()))
    userId?: number;
    
    @serializable(alias('exam_id', primitive()))
    examId?: number;

    @serializable(alias('duration_in_sec', primitive()))
    durationInSec?: any;
}


export class ExamAttempt {
    @serializable(alias('exam_attempt', object(ExamBody)))
    examAttempt?: ExamBody
}