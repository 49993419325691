import { PERFORMANCE_AREA, PREPSCORE, GET_WHERE_YOU_LEFT, GET_EXAM_DATES, SET_EXAM_DATE, GET_CHART_DATA, GET_DRUG_CHART } from "../definitions/performaceConstants";

export function getStrengthAndWeakness(courseId: number, successCallback: Function, errCallback: Function) {
    return {
        type: PERFORMANCE_AREA,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}

export function getPrepScore(courseId: number, successCallback: Function, errCallback: Function) {
    return {
        type: PREPSCORE,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}

export function getWhereYouLeft(courseId: number, successCallback: Function, errCallback: Function) {
    return {
        type: GET_WHERE_YOU_LEFT,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}

export function getExamDate(courseId: number, successCallback: Function, errCallback: Function) {
    return {
        type: GET_EXAM_DATES,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}

export function setExamDate(payload: any, successCallback: Function, errCallback: Function) {
    return {
        type: SET_EXAM_DATE,
        payload: {
            payload,
            successCallback,
            errCallback
        }
    }
}

export function getChartData(courseId: any, successCallback: Function, errCallback: Function) {
    return {
        type: GET_CHART_DATA,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}

export function getDrugChart(courseId: any, successCallback: Function, errCallback: Function) {
    return {
        type: GET_DRUG_CHART,
        payload: {
            courseId,
            successCallback,
            errCallback
        }
    }
}





