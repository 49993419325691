import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Card from "../../../../shared/components/Card";
// import Percem
import "./prepScore.scss";

interface IPerpScore {
    score: number;
}

function PrepScore({ score }: IPerpScore) {
    const color = score >= 70 ? "#44C37A" : score >= 50 ? "#F5BA26" : "#A9070B";
    const progressBarStyle = buildStyles({
        strokeLinecap: "butt",
        pathTransitionDuration: 0.5,
        pathColor: color,
        trailColor: "#EAEDF2",
        backgroundColor: "transparent",
        textColor: color,
        textSize: "inherit",
    });
    return (
        <Card title="PrepSCORE">
            <div className="prep-score__container">
                <CircularProgressbar
                    styles={progressBarStyle}
                    value={score}
                    text={`${score % 1 === 0 ? score : score.toFixed(2)}%`}
                    counterClockwise
                />
                <p>
                    PrepSCORE is a cumulative score that displays how READY you
                    are based on your individual average scores on each topic
                    weighted according to the real exams competencies.
                </p>
            </div>
        </Card>
    );
}

export default PrepScore;
