import {serializable, alias, primitive, object} from 'serializr';

export class StudentModuleDetails {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('user_id', primitive()))
    userId?: number;

    @serializable(alias('course_module_id', primitive()))
    courseModuleId?: number;

}
