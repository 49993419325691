import React from "react";
import Box from "./box";
import "./moduleContent.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TOPICS } from "../../../../routes/routeConstants/appRoutes";

interface IModules extends RouteComponentProps {
    no: number;
    module: any;
}

function Module({ no, module, history }: IModules) {

    const handleClick = () => {
        history.push({
            pathname: TOPICS(module.id),
            state: {
                no
            }
        });
    }

    return (
        <div className="module-container" onClick={handleClick}>
            <div className="progress-section">
                {module.topics.map((quiz: any) => {
                    const { completion } = quiz;

                    return (
                        <Box
                            color={
                                completion >= 70
                                    ? "success"
                                    : completion >= 50
                                        ? "warning"
                                        : "default"
                            }
                        />
                    );
                })}
            </div>
            <div className="module-description">
                <p className="module-description__title">MODULE {no ? no : ""}</p>
                <p
                    className={
                        Math.round(module?.completion) >= 70
                            ? "test-completed"
                            : Math.round(module?.completion) >= 50
                                ? "warning-test"
                                : ""
                    }
                >
                    {module?.name}
                </p>
            </div>
            <div className="module-next">
                <i className="arrow right icon" />
            </div>
        </div>
    );
}

export default withRouter(Module);
