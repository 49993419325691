import React from "react";
import "./questionNumber.scss";

interface IQuestionNumber {
	id?: any;
	questionNo: any;
	completed?: boolean;
	flag?: boolean;
	current?: boolean;
	classes?: string,
	index?: number;
	clickHandler?: (index: any, id: any) => void;
}

function QuestionNumber({
	index,
	id,
	questionNo,
	completed,
	flag,
	current,
	classes,
	clickHandler = () => {}
}: IQuestionNumber) {
	return (
		<div
			onClick={() => clickHandler(index, id)}
			className={`question-number-container ${
				completed && "question-number__completed"
				} ${
				current && "question-number__current"
				} ${flag && "question-number__flag"} ${classes}`}
		>
			{flag && <i className="flag icon question-flagged" />}
			<p>{questionNo}</p>
		</div>
	);
}

export default QuestionNumber;
