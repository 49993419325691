import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    signInAction,
    signOutAction,
    forgotPasswordAction,
    resetPasswordAction,
    validateTokenAction,
    registerUserAction,
    emailVerifyAction,
    resendVerifyEmailAction,
    makePayment,
    getUserDetails,
    updateUser,
    purchaseCourse,
    disableNavigation,
    validateCouponCodeAction
} from "../actions/authActions";

export default function AuthContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            signInAction,
            signOutAction,
            forgotPasswordAction,
            resetPasswordAction,
            validateTokenAction,
            registerUserAction,
            emailVerifyAction,
            resendVerifyEmailAction,
            makePayment,
            getUserDetails,
            updateUser,
            purchaseCourse,
            disableNavigation,
            validateCouponCodeAction,
        },
        dispatch
    );
}

function mapStateToProps(state: any, ownProps: any) {
    return {
        successMessage: state.auth.successMessage,
        errorMessage: state.auth.error,
        authToken: state.auth.authToken,
        user: state.auth.user,
        authenticated: state.auth.authenticated,
        disable: state.auth.disable,
        ...ownProps
    };
}
