import React from "react";
import "./icon.scss"

export const CorrectIcon = ({ classes }: any) => {
    return (
        <i className={`check circle icon correct-icon ${classes}`}/>
    )
}

export const WrongIcon = ({ classes }: any) => {
    return (
        <i className={`times circle icon worng-icon ${classes}`}/>
    )
}

