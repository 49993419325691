import { takeEvery, call, put } from "redux-saga/effects";
import {
    AUTHENTICATED,
    REQUEST_FORGOT_PASSWORD,
    REQUEST_LOGIN,
    REQUEST_LOGIN_WITH_REFRESH_TOKEN,
    REQUEST_LOGOUT,
    REQUEST_REGISTER_ACCOUNT,
    REQUEST_RESET_PASSWORD,
    REQUEST_VALIDATE_TOKEN,
    REQUEST_VERIFY_EMAIL,
    RESEND_EMAIL_VERIFICATION,
    UNAUTHENTICATED,
    MAKE_PAYMENT,
    GET_USER,
    UPDATE_USER,
    PURCHASE_COURSE,
    VALIDATE_COUPON_CODE,
} from "../definitions/authConstants";
import { deserialize, serialize } from "serializr";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import { appHistory } from "../../routes";
import { ApiService } from "../services/ApiService";
import axios from "axios"
import {
    FORGOT_PASSWORD_API_URL,
    LOGIN_API_URL,
    LOGOUT_API_URL,
    RESEND_EMAIL_VERIFICATION_API_URL,
    RESET_PASSWORD_API_URL,
    USERS_API_URL,
    VALIDATE_TOKEN_API_URL,
    VERIFY_EMAIL_API_URL,
    PAYMENT_API,
    GET_USER_API_URL,
    PURCHASE_COURSE_API,
    GET_IS_VALID_COUPON_URL
} from "../../routes/routeConstants/apiRoutes";
import { AuthToken } from "../models/AuthToken/authToken.model";
import { User } from "../models/User/user.model";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { LoginTypeEnum } from "../enums/loginType.enum";
import { Login } from "../models/Login/login.model";
import { signOutAction } from "../actions/authActions";
import { Transaction } from "../models/Transaction/transaction.model";
import { Redirect } from "react-router-dom";
import { store } from "../../store";

const apiService = new ApiService();

function* loginFlow(action: any) {
    const { login, successCallback, errCallback } = action.payload;
    let response;
    try {
        const loginJSON = { user: serialize(login) };
        response = yield call(apiService.post, LOGIN_API_URL, loginJSON);
        if (response.status >= 200 && response.status <= 299) {

            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "success", message: "Successfully logged in" },
            });
            const user = deserialize(User, response.data["user"]);
            const authToken = deserialize(AuthToken, response.data["token"]);
            localStorage.setItem("authToken", JSON.stringify(authToken));
            localStorage.setItem("user", JSON.stringify(user));
            yield put({ type: AUTHENTICATED, payload: { authToken, user } });
            successCallback();
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback(error);
    }
    return response;
}

function* createAccount(action: any) {
    const { user, successCallback, errCallback } = action.payload;
    let response;
    try {
        const newUserJSON = { user: serialize(user) };
        response = yield call(apiService.post, USERS_API_URL, newUserJSON);
        if (response.status >= 200 && response.status <= 299) {
            // message to add
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "success", message: "User " },
            });
            const user = deserialize(User, response.data["user"]);
            const authToken = deserialize(AuthToken, response.data["token"]);
            const transaction = deserialize(
                Transaction,
                response.data["transaction"]
            );
            localStorage.setItem("authToken", JSON.stringify(authToken));
            localStorage.setItem("user", JSON.stringify(user));
            yield put({ type: AUTHENTICATED, payload: { authToken, user } });
            successCallback(transaction);
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}


function orginalRequest(originalRequest: any, token: { access_token: string,  }) {
    axios.defaults.headers.common["Authorization"] =
        "bearer " + token.access_token;
    originalRequest.headers["Authorization"] =
        "bearer " + token.access_token;
    return axios(originalRequest);
}

function* loginWithRefreshTokenFlow(action: { type: string, payload: any }) {
    let authToken;
    if (localStorage.getItem("authToken")) {
        authToken = JSON.parse(localStorage.getItem("authToken") || "");
    }
    let login = new Login();
    login.refreshToken = authToken.refreshToken;
    login.grantType = LoginTypeEnum.REFRESH_TOKEN;
    let response;
    try {
        const loginJSON = { user: serialize(login) };
        response = yield call(apiService.post, LOGIN_API_URL, loginJSON);
        if (response.status >= 200 && response.status <= 299) {
            const user = deserialize(User, response.data["user"]);
            const authToken = deserialize(AuthToken, response.data["token"]);
            localStorage.setItem("authToken", JSON.stringify(authToken));
            localStorage.setItem("user", JSON.stringify(user));
            yield put({ type: AUTHENTICATED, payload: { authToken, user } });
            orginalRequest(action.payload, response.data["token"]);
        } else {
            yield put({ type: UNAUTHENTICATED, payload: {} });
            yield call(redirectToPage, appRoutes.LOGIN);
        }
    } catch (error) {
        yield put({ type: UNAUTHENTICATED, payload: {} });
        yield call(redirectToPage, appRoutes.LOGIN);
    }
    return response;
}

function* forgotPasswordFlow(action: any) {
    const { login, successCallback, errCallback } = action.payload;
    let response;
    try {
        const loginJSON = { user: serialize(login) };
        response = yield call(
            apiService.post,
            FORGOT_PASSWORD_API_URL,
            loginJSON
        );
        if (response.status >= 200 && response.status <= 299) {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "success", message: response.data["success"] },
            });
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* resetPasswordFlow(action: any) {
    const { login, successCallback, errCallback } = action.payload;
    let response;
    try {
        const loginJSON = { user: serialize(login) };
        response = yield call(
            apiService.put,
            RESET_PASSWORD_API_URL,
            loginJSON
        );
        if (response.status >= 200 && response.status <= 299) {
            yield put({
                type: INVOKE_TOASTER,
                payload: {
                    type: "success",
                    message: "Password updated successfully",
                },
            });
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* validateTokenFlow(action: any) {
    const { login, successCallback, errCallback } = action.payload;
    let response;
    try {
        const loginJSON = { user: serialize(login) };
        response = yield call(
            apiService.post,
            VALIDATE_TOKEN_API_URL,
            loginJSON
        );
        if (response.status >= 200 && response.status <= 299) {
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            window.location.replace("/home");
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* registerUserFlow(action: any) {
    const { user, successCallback, errCallback } = action.payload;
    let response;
    try {
        const userJSON = { user: serialize(user) };
        response = yield call(apiService.post, USERS_API_URL, userJSON);
        if (response.status >= 200 && response.status <= 299) {
            yield put({
                type: INVOKE_TOASTER,
                payload: {
                    type: "success",
                    message:
                        "Your account has been created. Please enter the OTP you received via email",
                },
            });
            const user = deserialize(User, response.data["user"]);
            const authToken = deserialize(AuthToken, response.data["token"]);
            localStorage.setItem("authToken", JSON.stringify(authToken));
            localStorage.setItem("user", JSON.stringify(user));
            yield put({ type: AUTHENTICATED, payload: { authToken, user } });
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* verifyEmailFlow(action: any) {
    const { user, successCallback, errCallback } = action.payload;
    let response;
    try {
        const userJSON = { user: serialize(user) };
        response = yield call(apiService.post, VERIFY_EMAIL_API_URL, userJSON);
        if (response.status >= 200 && response.status <= 299) {
            yield put({
                type: INVOKE_TOASTER,
                payload: {
                    type: "success",
                    message: "Email verified successfully",
                },
            });
            const user = deserialize(User, response.data["user"]);
            localStorage.setItem("user", JSON.stringify(user));
            yield put({ type: AUTHENTICATED, payload: { user } });
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* resendVerifyEmailFlow(action: any) {
    const { successCallback, errCallback } = action.payload;
    let response;
    try {
        response = yield call(
            apiService.post,
            RESEND_EMAIL_VERIFICATION_API_URL,
            {}
        );
        if (response.status >= 200 && response.status <= 299) {
            yield put({
                type: INVOKE_TOASTER,
                payload: {
                    type: "success",
                    message: "OTP has been sent to your registered email",
                },
            });
            successCallback();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
            errCallback();
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* logoutFlow() {
    let response;
    try {
        response = yield call(apiService.delete, LOGOUT_API_URL);
        if (response.status >= 200 && response.status <= 299) {
            localStorage.clear();
            yield put({ type: UNAUTHENTICATED, payload: {} });
            yield call(redirectToPage, appRoutes.LOGIN);
            window.location.reload();
        } else {
            const message = response["message"]
                ? response["message"]
                : "Something went wrong. Please try again.";
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
    return response;
}

function* makePayment(action: any) {
    const { paymentId, successCallback, errCallback } = action.payload;
    let response;
    try {
        const payload = {
            card: {
                pm_id: paymentId,
            },
        };

        response = yield call(apiService.post, PAYMENT_API, payload);
        if (response.status >= 200 && response.status <= 299) {
            // message to add
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "success", message: "Payment successful" },
            });
            // const user = deserialize(User, response.data["user"]);
            // const authToken = deserialize(AuthToken, response.data["token"]);
            // const transaction = deserialize(
            //     Transaction,
            //     response.data["transaction"]
            // );
            // localStorage.setItem("authToken", JSON.stringify(authToken));
            // localStorage.setItem("user", JSON.stringify(user));
            // yield put({ type: AUTHENTICATED, payload: { authToken, user } });
            successCallback();
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}

function* getUser(action: any) {
    const { userId, successCallback, errCallback } = action.payload;
    let response;
    try {
        response = yield call(apiService.get, GET_USER_API_URL(userId));
        if (response.status >= 200 && response.status <= 299) {
            // message to add
            const user = deserialize(User, response.data?.user);
            successCallback(user);
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}

function* updateUser(action: any) {
    const { userId, data, successCallback, errCallback } = action.payload;
    const { firstName, lastName, address } = data;
    let response;
    try {
        response = yield call(apiService.put, GET_USER_API_URL(userId), {
            first_name: firstName,
            last_name: lastName,
            address,
        });
        if (response.status >= 200 && response.status <= 299) {
            const user = deserialize(User, response.data?.user);
            successCallback(user);
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}

function* purchaseCourse(action: any) {
    const { courseId, paymentId, successCallback, errCallback } = action.payload;
    const payload = {
        "transaction": {
            "course_id": courseId,
            "pm_id": paymentId,
            "save_card": false
        }
    }
    let response;
    try {
        response = yield call(apiService.post, PURCHASE_COURSE_API, payload);
        if (response.status >= 200 && response.status <= 299) {
            successCallback();
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}

function redirectToPage(location: string) {
    localStorage.clear();
    appHistory.push(location);
}

function* validateCouponCode(action: any) {
    const { couponCode, successCallback, errCallback } = action.payload;
    let response;
    try {
        response = yield call(apiService.get, GET_IS_VALID_COUPON_URL(couponCode));
        if (response.status >= 200 && response.status <= 299) {
            const discount = response.data.discount;
            successCallback(discount);
        } else {
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
            yield put({ type: UNAUTHENTICATED });
            errCallback();
        }
    } catch (error) {
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: response["message"] },
        });
        yield put({ type: UNAUTHENTICATED });
        errCallback();
    }

    return response;
}

export default function* AuthSaga() {
    yield takeEvery(REQUEST_LOGIN, loginFlow);
    yield takeEvery(
        REQUEST_LOGIN_WITH_REFRESH_TOKEN,
        loginWithRefreshTokenFlow
    );
    yield takeEvery(REQUEST_LOGOUT, logoutFlow);
    yield takeEvery(REQUEST_FORGOT_PASSWORD, forgotPasswordFlow);
    yield takeEvery(REQUEST_VALIDATE_TOKEN, validateTokenFlow);
    yield takeEvery(REQUEST_RESET_PASSWORD, resetPasswordFlow);
    yield takeEvery(REQUEST_REGISTER_ACCOUNT, createAccount);
    yield takeEvery(REQUEST_VERIFY_EMAIL, verifyEmailFlow);
    yield takeEvery(RESEND_EMAIL_VERIFICATION, resendVerifyEmailFlow);
    yield takeEvery(MAKE_PAYMENT, makePayment);
    yield takeEvery(GET_USER, getUser);
    yield takeEvery(UPDATE_USER, updateUser);
    yield takeEvery(PURCHASE_COURSE, purchaseCourse);
    yield takeEvery(VALIDATE_COUPON_CODE, validateCouponCode)
}