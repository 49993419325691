// export const URL = "http://68.183.94.100:3000";
export const URL = "https://admin.pharmaepass.com";
export const BASE_URL = URL + "/api/v1";
// export const BASE_URL = "https://edc7cbf76aaf.ngrok.io/api/v1";

/* Auth API Endpoints */
export const USERS_API_URL = BASE_URL + "/user_management/users";
export const LOGIN_API_URL = BASE_URL + "/user_management/oauth/login";
export const LOGOUT_API_URL = BASE_URL + "/user_management/oauth/logout";
export const FORGOT_PASSWORD_API_URL =
  BASE_URL + "/user_management/users/reset_password";
export const RESET_PASSWORD_API_URL =
  BASE_URL + "/user_management/users/password";
export const VALIDATE_TOKEN_API_URL =
  BASE_URL + "/user_management/users/validate_reset_password_token";
export const VERIFY_EMAIL_API_URL = BASE_URL + "/user_management/users/verify";
export const RESEND_EMAIL_VERIFICATION_API_URL =
  BASE_URL + "/user_management/users/resend_confirmation_token";
export const GET_USER_API_URL = (userId: number) =>
  BASE_URL + "/user_management/users/" + userId;
export const GET_IS_VALID_COUPON_URL = (couponCode: string) => 
  BASE_URL + "/user_management/users/is_valid_coupon/" + couponCode;

/* Meta API Endpoints */
export const COUNTRY_META_API_URL = BASE_URL + "/meta/countries";

/* Exam Endpoints */
export const CREATE_ATTEMPT = (courseId: number | string) =>
  `exam_management/courses/${courseId}/exam_attempts`;
export const GET_EXAM_QUESTIONS = (examId: number) =>
  `/exam_management/exam_attempts/${examId}/questions`;
export const UPDATE_ANSWER_OPTION = (questionId: number) =>
  `/exam_management/exam_answers/${questionId}`;
export const GET_RESULTS = (attemptId: number) =>
  `exam_management/exam_attempts/${attemptId}/results`;
export const GET_SINGLE_QUESTION = (
  examAttemptId: number,
  questionId: number
) => `exam_management/exam_attempts/${examAttemptId}/questions/${questionId}`;
export const COURSE_META_API_URL = BASE_URL + "/meta/courses";
export const GET_COURSE_FOR_USER = BASE_URL + "/module_management/courses";

/* Modules Lectures */
export const MODULE_LECTURES = (id: any) =>
  `/module_management/courses/${id}/modules`;
export const GET_MODULE_LECTURE = (courseId: any, moduleId: number) =>
  `/module_management/courses/${courseId}/modules/${moduleId}`;
export const GET_TOPIC = (courseId: number, moduleId: number) =>
  `/module_management/courses/${courseId}/topics/${moduleId}`;
export const GET_QUIZ = (courseId: number, topicId: number, quizId: number) =>
  `/module_management/courses/${courseId}/topics/${topicId}/${quizId}/quiz`;
export const CREATE_EXAM_ATTEMPT = (courseId: number, quizId: number) =>
  `module_management/courses/${courseId}/quizzes/${quizId}/quiz_attempts`;
export const GET_ALL_QUESTIONS = (courseId: number, quizzAttemptId: number) =>
  `/module_management/courses/${courseId}/quiz_attempts/${quizzAttemptId}/questions`;
export const GET_SINGLE_QUESTION_QUIZ = (
  courseId: number,
  quizAttemptId: number,
  questionId: number
) =>
  `/module_management/courses/${courseId}/quiz_attempts/${quizAttemptId}/questions/${questionId}`;
export const GET_SINGLE_ANSWER_QUIZ = (courseId: number, answerId: number) =>
  `/module_management/courses/${courseId}/quiz_answers/${answerId}`;
export const UPDATE_A_QUIZ = (courseId: number, questionId: number) =>
  `/module_management/courses/${courseId}/quiz_answers/${questionId}`;
export const END_QUIZ = (courseId: number, quizAttemptId: number) =>
  `/module_management/courses/${courseId}/quiz_attempts/${quizAttemptId}/results`;

// Analytics

export const TOPIC_PERFORMANCE_STRENGTH_AREAS = (courseId: number) =>
  `/module_management/courses/${courseId}/topics_performance`;

export const PREPSCORES = (courseId: number) =>
  `module_management/courses/${courseId}/prepscores`;

// Dashboard
export const CONTINUE_WHERE_YOU_END = (courseId: number) =>
  `/module_management/courses/${courseId}`;
export const GET_EXAM_DATE = (courseId: number) =>
  `/module_management/courses/${courseId}/external_exam_date`;
export const SET_EXAM_DATE_PUT = (courseId: number) =>
  `/module_management/courses/${courseId}/set_external_exam_date`;
export const GET_EXAM_CHART_DATA = (courseId: number) =>
  `/exam_management/courses/${courseId}/exam_attempts`;

export const DRUG_CHART_DOCS = (courseId: number) =>
  `/module_management/courses/${courseId}/drug_charts`;

// Payment
export const PAYMENT_API = "/payment/cards";

export const PURCHASE_COURSE_API = "/payment/transactions";

// Search

export const SEARCH_API = (courseId: number) =>
  `/module_management/courses/${courseId}/search`;

export const QUIZ_FILTER_GET_API = (courseId: any, quizId: any) =>
  `/module_management/courses/${courseId}/quizzes/${quizId}/quiz_attempts/quiz_filter_questions`;

export const QUIZ_FILTER_POST_API = (courseId: any, quizId: any) =>
  `/module_management/courses/${courseId}/quizzes/${quizId}/quiz_attempts/quiz_filter_attempts`;

export const LIST_BLOG = "/blogs";

export const LIST_BLOGS_BY_PAGE_NO = (pageNumber: number) =>
  `${LIST_BLOG}?page=${pageNumber}`;

export const LIST_SUBSCRIPTION = "/api/v1/meta/courses";

export const GET_BLOG = (blogId: any) => `${LIST_BLOG}/${blogId}`;
