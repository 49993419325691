import {
    GET_MODULE,
    GET_MODULES,
    GET_TOPIC,
    GET_QUIZ,
    EXAM_ATTEMPT,
    SET_ALL_QUESTION,
    GET_QUESTION,
    GET_A_ANSWER,
    UPDATE_QUIZ_ANSWER,
    END_TEST,
    GET_FILTER_QUIZ_ATTEMPT,
    START_FILTER_QUIZ_ATTEMPT,
} from "../definitions/moduleConstants";

export function getModules(
    id: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_MODULES,
        payload: {
            id,
            successCallback,
            errCallback,
        },
    };
}

export function getModule(
    courseId: any,
    moduleId: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_MODULE,
        payload: {
            courseId,
            moduleId,
            successCallback,
            errCallback,
        },
    };
}

export function getTopic(
    courseId: number,
    moduleId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_TOPIC,
        payload: {
            courseId,
            moduleId,
            successCallback,
            errCallback,
        },
    };
}

export function getQuiz(
    courseId: number,
    topicId: number,
    quizId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_QUIZ,
        payload: {
            courseId,
            topicId,
            quizId,
            successCallback,
            errCallback,
        },
    };
}

export function createQuizAttempt(
    courseId: number,
    quizId: number,
    payload: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: EXAM_ATTEMPT,
        payload: {
            courseId,
            quizId,
            payload,
            successCallback,
            errCallback,
        },
    };
}

export function getAllQuestions(
    courseId: number,
    quizAttemptId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: SET_ALL_QUESTION,
        payload: {
            courseId,
            quizAttemptId,
            successCallback,
            errCallback,
        },
    };
}

export function getSingleQuestion(
    courseId: number,
    examAttemptId: number,
    questionId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_QUESTION,
        payload: {
            courseId,
            examAttemptId,
            questionId,
            successCallback,
            errCallback,
        },
    };
}

export function getAAnswer(
    courseId: number,
    answerId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_A_ANSWER,
        payload: {
            courseId,
            answerId,
            successCallback,
            errCallback,
        },
    };
}

export function updateExamAnswer(
    courseId: number,
    questionId: number,
    option: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: UPDATE_QUIZ_ANSWER,
        payload: {
            courseId,
            questionId,
            data: {
                quiz_answer: {
                    answer: option,
                },
            },
            successCallback,
            errCallback,
        },
    };
}

export function endTest(
    courseId: number,
    quizAttemptId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: END_TEST,
        payload: {
            courseId,
            quizAttemptId,
            successCallback,
            errCallback,
        },
    };
}

export function getFilteredAttempt(
    courseId: number,
    quizId: number,
    mode: string,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_FILTER_QUIZ_ATTEMPT,
        payload: {
            courseId,
            quizId,
            mode,
            successCallback,
            errCallback,
        },
    };
}

export function createFilterAttempt(
    courseId: number,
    quizId: number,
    payload: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: START_FILTER_QUIZ_ATTEMPT,
        payload: {
            courseId,
            quizId,
            payload,
            successCallback,
            errCallback,
        },
    };
}
