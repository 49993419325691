import React, { useEffect, useState } from "react";
import Module from "./Module";
import ModuleContainer from "../../../store/containers/moduleContainer";
import MetaContainer from "../../../store/containers/metaContainer";
import "./modules.scss";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { withRouter } from "react-router-dom";
import examContainer from "../../../store/containers/examContainer";
import { ExamBody } from "../../../store/models/Exam/Attempt.model";
import Loader from "../../../shared/components/Loader";

function Modules({
	courseModules,
	getModules,
	history,
	createAttempt,
	setQuestions,
	course,
}: any) {
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (course) {
			setLoading(true);
			getModules(
				course.id,
				() => {
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		}
	}, [course]);

	const handleClickToExam = () => {
		if (course) {
			createAttempt(
				course.id,
				(examSession: ExamBody) => {
					setQuestions(
						examSession.id,
						() => {
							history.push({path: AppRoutes.MOCK_EXAMS_EXAM, state: {
								back: "to Modules"
							}});
						},
						() => { }
					);
				},
				() => { }
			);
		}
	};

	return (
		<div className="modules-container">
			<Loader loading={loading} />
			<div className="modules__header mt-5">
				<h1>Modules</h1>
			</div>
			<div className="modules-body mt-5">
				{courseModules
					? courseModules.map((module: any, index: number) => {
						return <Module no={index + 1} module={module} />;
					})
					: null}
			</div>
			<div className="modules-text mt-5">
				<h1>Generate Mock Exam</h1>
				<p>I am Ready!</p>
			</div>
			<div className="modules-exam-mode mt-5" onClick={handleClickToExam}>
				<p>Exam Mode</p>
				<i className="arrow right icon"></i>
			</div>
		</div>
	);
}

export default withRouter(
	ModuleContainer(examContainer(MetaContainer(Modules)))
);
