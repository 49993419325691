import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TimerIcon from "../../../../../assets/icon/timer-icon.svg";

interface ITimer extends RouteComponentProps {
	seconds: number;
	timeEndHandler: Function;
}

const styles = {
	timer: {
		display: "block",
	},
};
function Timer({ seconds, timeEndHandler }: ITimer) {
	const [timer, setTimer] = useState<number>(seconds);
	let timeInterval: any;
	useEffect(() => {
		timeInterval = setInterval(() => {
			setTimer((oldTime: number) => {
				if (oldTime === 0) {
					clearInterval(timeInterval);
					timeEndHandler();
					return 0;
				}
				return oldTime - 1;
			});
		}, 1000);
		return () => {
			clearInterval(timeInterval);
		};
	}, []);

	const secondsToHHMMSS = (sec: number) => {
		const dateObj = new Date(sec * 1000);
		const hours = dateObj.getUTCHours().toString().padStart(2, "0");
		const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");
		const seconds = dateObj.getSeconds().toString().padStart(2, "0");
		let timeString = "";
		timeString = hours !== "00" ? timeString + hours + ":" : "";
		timeString =
			hours === "00" && minutes === "00"
				? ""
				: timeString + minutes + ":";
		timeString += seconds;
		if (timeString === "00") {
			clearInterval(timeInterval);
			return "";
		}
		return timeString;
	};

	return (
		<p style={{ display: "flex" }}>
			<img src={TimerIcon} style={{ display: "block", marginRight: 5 }} />
			<div>
				<span style={styles.timer}>Time Remaining</span>
				<span>{secondsToHHMMSS(timer)}</span>
			</div>
		</p>
	);
}

export default withRouter(Timer);
