import React from 'react';
import { useToasts } from 'react-toast-notifications'
import ToastContainer from "../../../store/containers/toastContainer";
import './appToast.scss';

const AppToast = (props: any) => {
    const {type, message} = props;
    const { addToast } = useToasts();
    return (
        (type && message) ?
            <div className={`app-toast ${type}`} > { message } </div> : null
    )
};

export default ToastContainer(AppToast)
