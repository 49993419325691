import { serializable, alias, primitive, object, list } from 'serializr';
import { StudentModuleDetails } from "./studentModuleDetails.model"

export class Completion {
    @serializable(alias('completion_value', primitive()))
    completionValue?: number;

    @serializable(alias('completion_message', primitive()))
    completionMessage?: string;
}

export class QuizBody {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('completion', object(Completion)))
    completion?: Completion;
}

export class Quiz {
    @serializable(alias('quiz', object(QuizBody)))
    quiz?: QuizBody;
}