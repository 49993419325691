import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ModuleContainer from "../../../store/containers/moduleContainer";
import MetaContainer from "../../../store/containers/metaContainer";
import ModuleCard from "../Modules/Module";
import "./module.scss";

interface IModule extends RouteComponentProps {
    course: any;
    getModule: Function;
}

const Module: FC<IModule> = ({ match, getModule, course }) => {
    const [module, setModule] = useState<any>(null);

    useEffect(() => {
        const params: any = match?.params;
        getModule(
            course?.id,
            params?.id,
            (module: any) => {
                setModule(module);
            },
            () => {}
        );
    }, [course?.id]);
    return (
        <div className="module__container">
            <div className="title">
                <h1>Module</h1>
            </div>
            <div className="module-content mt-5">{module ? <ModuleCard no={0} module={module} /> : null}</div>
        </div>
    );
};

export default MetaContainer(ModuleContainer(Module));
