import React, { Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Tick from "../../../assets/images/tick.svg";
import { PURCHASE_COURSE } from "../../../routes/routeConstants/appRoutes";
import { CREATE_BASIC_DETAILS } from "../../../routes/routeConstants/appRoutes";
import metaContainer from "../../../store/containers/metaContainer";
import "./buyCourseCard.scss";

interface Feature {
    module: string;
    isAvailabel: boolean;
}

export interface IBuyCourseCard extends RouteComponentProps {
    courseName: string;
    accessPeriod: number;
    features: Feature[];
    amount: any;
    purchaseCourseModal?: Function;
    btnText?: string;
    isAvailable: boolean;
    getCoursesAction: any;
}

function BuyCourseCard({
    history,
    courseName,
    accessPeriod,
    features,
    amount,
    purchaseCourseModal = () => {},
    btnText = "Buy Now",
    isAvailable = false,
    location,
    getCoursesAction,
}: IBuyCourseCard) {
    const handlePurchaseCourse = () => {
        purchaseCourseModal();
        if (location.pathname === "/enroll-courses") {
            getCoursesAction(
                (courses: any) => {
                    const [{ id }] = courses.filter(
                        (course: any) =>
                            course?.name?.toUpperCase() === courseName
                    );
                    history.push(`${CREATE_BASIC_DETAILS}?course=${id}`);
                },
                () => {}
            );
            return;
        }
        history.push(`${PURCHASE_COURSE}?course=${courseName}`);
    };

    return (
        <div className="buy-course__container">
            <div className="course-heading">
                <h1>{courseName} Course</h1>
            </div>
            {isAvailable ? (
                <Fragment>
                    <div className="monthly-access">
                        <p>{`${accessPeriod}-month Access`}</p>
                    </div>
                    <div className="feature-contents">
                        {features?.map((feature) => (
                            <div className="feature-content">
                                <p>{feature.module}</p>
                                <img
                                    src={feature?.isAvailabel ? Tick : ""}
                                    alt=""
                                    className="tick-icon"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="buttom-item">
                        <div className="price">
                            <p>${amount}</p>
                        </div>
                        <div className="buy-now" onClick={handlePurchaseCourse}>
                            <p>{btnText}</p>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className="coming-soon">
                    <h1>Coming Soon </h1>
                </div>
            )}
        </div>
    );
}

export default withRouter(metaContainer(BuyCourseCard));
