import React from 'react'
import { Loader } from 'semantic-ui-react'
import "./loader.scss"

interface ILoader {
    loading?: boolean;
}

export default function index({ loading = false }: ILoader) {
    if(loading) {
        return (
            <div className="loader-container">
                <Loader inverted active={loading}/>
            </div>
        )
    }
    return null;
}
