import React from "react";
import { Pagination, PaginationProps } from "semantic-ui-react";
import "./uiPagenation.scss";

interface IPagenation {
  defaultActivePage: number;
  totalPages: number;
  nextItem?: boolean;
  prevItem?: boolean;
  onPageChange?: (e: any, paginationProps: PaginationProps) => void;
}

export default function UIPagenation({
  nextItem = false,
  prevItem = false,
  ...otherProps
}: IPagenation) {
  return otherProps.totalPages > 1 ? (
    <div className="pagenation__container">
      <Pagination
        {...otherProps}
        onPageChange={otherProps.onPageChange}
        nextItem={nextItem && "Next"}
        prevItem={prevItem && "Prev"}
      />
    </div>
  ) : null;
}
