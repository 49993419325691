import React, { FC, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./examDescription.scss";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import examContainer from "../../../store/containers/examContainer";
import { ExamBody } from "../../../store/models/Exam/Attempt.model";
import MetaContainer from "../../../store/containers/metaContainer";
import Loader from "../../../shared/components/Loader";

interface IExamDescription extends RouteComponentProps {
  createAttempt: Function;
  examSession: any;
  setQuestions: Function;
  course: any;
}

export const localStorageClean = () => {
  const user = localStorage.getItem("user") as string;
  const token = localStorage.getItem("authToken") as string;
  localStorage.clear();
  localStorage.setItem("user", user);
  localStorage.setItem("authToken", token);
};

function ExamDescription({
  history,
  createAttempt,
  setQuestions,
  course,
}: IExamDescription) {
  const [loading, setLoading] = useState(false);
  const handleClickToExam = () => {
    if (course) {
      setLoading(true);
      createAttempt(
        course.id,
        (examSession: ExamBody) => {
          setQuestions(
            examSession.id,
            () => {
              history.push(AppRoutes.MOCK_EXAMS_EXAM);
              localStorageClean();
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        },
        () => {
          setLoading(false);
        }
      );
    } else {
    }
  };

  const onMouseUp = () => {
    const s = window.getSelection();
  };

  return (
    <div className="exam-description__container">
      <Loader loading={loading} />
      <div className="exam-description__header mb-5">
        <p>MOCK EXAMS</p>
        <h1>Exam Generator</h1>
      </div>
      <div className="exam-description__question mb-4p">
        <p>Ready to take your skills?</p>
      </div>
      <div className="exam-description__details mb-4p">
        <p onMouseUp={onMouseUp}>
          Generate a 200-question timed mock Exam composed of questions on every
          topic to simulate the real exam.
        </p>
        <p>This tool allows you to take as many practice exams as you wish!</p>
      </div>
      <div className="exam-description__ready mb-4p">
        <h2>Generate a Mock Exam</h2>
        <p>I am ready!</p>
      </div>
      <div
        className={`exam-description__start__exam mt-2 ${
          !course ? "exam-description__disable" : ""
        }`}
        onClick={handleClickToExam}
      >
        <p>Exam Mode</p>
        <i className="arrow right icon" />
      </div>
    </div>
  );
}

export default examContainer(MetaContainer(ExamDescription));
