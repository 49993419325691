interface Feature {
    module: string;
    isAvailabel: boolean;
}

interface ICourseInfo {
    courseName: string;
    accessPeriod: number;
    amount: number;
    features: Feature[];
    isAvailable: boolean;
}

export const EEInfo: ICourseInfo = {
    courseName: "EE",
    accessPeriod: 6,
    features: [
        {module: "160 Learning Modules", isAvailabel: true},
        {module: "Over 3000 Sample Q", isAvailabel: true},
        {module: "Over 75 Video Lectures", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: false},
        {module: "Condensed Content", isAvailabel: false},
        {module: "Condensed Content", isAvailabel: false},
        {module: "Condensed Content", isAvailabel: false},
    ],
    amount: 50,
    isAvailable: true
}


export const MCQInfo: ICourseInfo = {
    courseName: "MCQ",
    accessPeriod: 6,
    features: [
        {module: "160 Learning Modules", isAvailabel: true},
        {module: "Over 3000 Sample Q", isAvailabel: true},
        {module: "Over 75 Video Lectures", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
        {module: "Condensed Content", isAvailabel: true},
    ],
    amount: 40,
    isAvailable: true
}

export const OSCEInfo: ICourseInfo = {
    courseName: "OSCE",
    accessPeriod: 0,
    features: [
    ],
    amount: 0,
    isAvailable: false
}