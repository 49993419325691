import { serializable, alias, primitive } from "serializr";

export class Login {
    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("password", primitive()))
    password?: string;

    @serializable(alias("new_password", primitive()))
    newPassword?: string;

    @serializable(alias("confirm_password", primitive()))
    confirmPassword?: string;

    @serializable(alias("reset_password_token", primitive()))
    resetPasswordToken?: string;

    @serializable(alias("grant_type", primitive()))
    grantType?: string;

    @serializable(alias("refresh_token", primitive()))
    refreshToken?: string;

    @serializable(alias("redirect_url", primitive()))
    redirectUrl?: string;
}
