import React, { useState } from "react";
import "./option.scss";
import { CorrectIcon, WrongIcon } from "../../../ExamResult/Reveal/Icon";

const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

interface IOption {
    text?: string;
    children?: string;
    index: string;
    selected?: boolean;
    name?: string;
    selectHandler: Function;
    correct?: boolean;
    wrong?: boolean;
    position?: number;
    rightClickHandler?: Function;
    strikeThrough?: boolean;
    isCorrect?: any;
    mode?: "study_mode" | "exam_mode"
}

function Option({
    isCorrect,
    text,
    children,
    index,
    selected,
    selectHandler,
    correct,
    wrong,
    position,
    rightClickHandler = () => { },
    strikeThrough = false,
    mode
}: IOption) {
    const handleSelect = () => {
        selectHandler(index);
    };

    const handleRightClick = (event: any) => {
        rightClickHandler(index);
        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    return (
        <div
            className={`question-palette-option__container ${
                selected && "question-palette-option-selected"
                } ${strikeThrough ? "text-strikethrougn" : ""} ${mode === "study_mode" && isCorrect ? "disable-option" : ""}`}
            onClick={handleSelect}
            onContextMenu={handleRightClick}
        >
            <div className="display-content">
                <div className="question-palette-option">
                    <span>{options[position || 0]}</span>
                </div>
                <p>{children || text}</p>
            </div>
            <div className="answer-status">
                {correct && <CorrectIcon />}
                {correct ? "" : wrong && <WrongIcon />}
            </div>
        </div>
    );
}

export default Option;
