import { SET_EXAM_ATTEMPT_SUCCESS } from "../definitions/dist/examConstants";
import {
    SET_QUESTIONS,
    SET_VISITED,
    NEXT_QUESTION,
    PREVIOUS_QUESTION,
    SET_FLAG,
    SET_QUESTION_NO,
    SET_EXAM_ATTEMPT,
    UPDATE_ANSWER,
    SET_RESULTS,
    GET_ANSWER,
    GET_A_QUESTION,
    UPDATE_STRIKE_THROUNG,
    SET_RESULTS_SUCCESS,
} from "../definitions/examConstants";

export function createAttempt(
    id: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: SET_EXAM_ATTEMPT,
        payload: {
            id,
            successCallback,
            errCallback,
        },
    };
}

export function setExamAttempt(
    data: any
) {
    return {
        type: SET_EXAM_ATTEMPT_SUCCESS,
        payload: {
            ...data,
        },
    };
}

export function setQuestions(
    id: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: SET_QUESTIONS,
        payload: {
            id,
            successCallback,
            errCallback,
        },
    };
}

export function setQuestionNo(id: any) {
    return {
        type: SET_QUESTION_NO,
        payload: id,
    };
}

export function nextQuestion() {
    return {
        type: NEXT_QUESTION,
    };
}

export function previousQuestion() {
    return {
        type: PREVIOUS_QUESTION,
    };
}

export function setVisited(id: any, data: any) {
    return {
        type: SET_VISITED,
        payload: {
            id,
            data,
        },
    };
}

export function setFlag(id: any) {
    return {
        type: SET_FLAG,
        payload: {
            id,
        },
    };
}

export function updateAnswer(
    id: number,
    option: string,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: UPDATE_ANSWER,
        payload: {
            id,
            data: {
                exam_answer: {
                    answer: option,
                },
            },
            successCallback,
            errCallback,
        },
    };
}

export function getAnswer(
    id: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_ANSWER,
        payload: {
            id,
            successCallback,
            errCallback,
        },
    };
}

export function getResults(
    id: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: SET_RESULTS,
        payload: {
            id,
            successCallback,
            errCallback,
        },
    };
}

export function getAQuestion(
    examAttemptId: number,
    questionId: number,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_A_QUESTION,
        payload: {
            examAttemptId,
            questionId,
            successCallback,
            errCallback,
        },
    };
}

export function updateStrikeThrough(
    option: any,
    questionNo: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: UPDATE_STRIKE_THROUNG,
        payload: {
            option,
            questionNo,
            successCallback,
            errCallback,
        },
    };
}

export function setResults(results: any) {
    return {
        type: SET_RESULTS_SUCCESS,
        payload: results
    }
}