import React from "react";
import ExamDescription from "./ExamDescription";
import { Switch, Route, Redirect } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import ExamForm from "./ExamForm";
import ExamResult from "./ExamResult";
import DisplayAnswer from "./ExamResult/DisplayAnswer";
import MetaContainer from "../../store/containers/metaContainer";
import "./mockExam.scss";

function MockExam() {
    return (
        <div className="application-body">
            <Switch>
                <Route
                    exact
                    path={appRoutes.MOCK_EXAMS_INTRO}
                    component={(props: any) => <ExamDescription {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.MOCK_EXAMS_EXAM}
                    component={(props: any) => <ExamForm {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.MOCK_EXAMS_RESULT}
                    component={(props: any) => <ExamResult {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.MOCK_EXAMS_DISPLAY_ANSWER}
                    component={(props: any) => <DisplayAnswer {...props} />}
                />
                <Redirect to={appRoutes.MOCK_EXAMS_INTRO} />
            </Switch>
        </div>
    );
}

export default MetaContainer(MockExam);
