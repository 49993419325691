import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as yup from "yup";
import {Formik} from "formik";
import {Button, Form} from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import {Link} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/containers/authContainer";
import {Login} from "../../../store/models/Login/login.model";
import './createPasswordForm.scss';
import {useLockBodyScroll} from "../../../shared/components/LockBodyScroll";
import qs from 'qs';
import {deserialize} from "serializr";

const createPasswordValidationSchema = yup.object({
    newPassword: yup.string().required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
        .required('Password confirmation is required')
});

interface ICreatePasswordForm {
    newPassword: string;
    confirmPassword: string;
    refreshToken: string;
}

function CreatePasswordForm(props: any){
    useLockBodyScroll();
    const queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
    });
    let login: Login = deserialize(Login, queryParams);
    const [isValidationCompleted, setValidationCompleteStatus] = useState(false);
    const [isValidToken, setValidToken] = useState(false);
    const initialValues: ICreatePasswordForm = {
        newPassword: '',
        confirmPassword: '',
        refreshToken: ''
    };
    useEffect(() => {
        props.validateTokenAction(login, () => {
            setValidationCompleteStatus(true);
            setValidToken(true);
        }, () => {
        });
    }, []);
    return (
        <div>
            {(isValidationCompleted && isValidToken) ?
                <React.Fragment>
                    <h3 className='mt-2 mb-3 text-center text-light-grey'> Create new Password </h3>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={createPasswordValidationSchema}
                        onSubmit={(values, actions) => {
                            const resetPasswordLogin = Object.assign(new Login(),values);
                            resetPasswordLogin.resetPasswordToken = login.resetPasswordToken;
                            resetPasswordLogin.email = login.email;
                            props.resetPasswordAction(resetPasswordLogin, ()=> {
                                props.history.push(appRoutes.LOGIN);
                            }, () => {

                            });
                        }}>
                        {({ handleSubmit, handleChange, values, errors, isValid, dirty}) => {
                            return(
                                <div className='create-password-form'>
                                    <Form onSubmit={ handleSubmit }>
                                        <Form.Field>
                                            <input
                                                name='newPassword'
                                                placeholder='Password'
                                                type='password'
                                                value={ values.newPassword }
                                                onChange={ handleChange }/>
                                            <FormErrorMessage message={ errors.newPassword }/>
                                        </Form.Field>
                                        <Form.Field>
                                            <input
                                                name='confirmPassword'
                                                placeholder='Confirm Password'
                                                type='password'
                                                value={ values.confirmPassword }
                                                onChange={ handleChange }/>
                                            <FormErrorMessage message={ errors.confirmPassword }/>
                                        </Form.Field>
                                        <Button primary className='w-100'
                                                type='submit'
                                                disabled={ (!isValid) || !dirty }> Submit Password </Button>
                                    </Form>
                                    <div className='text-center mt-2 mb-2'>
                                        Back to
                                        <Link to={appRoutes.LOGIN} className='text-primary ml-2'>
                                            Sign in
                                        </Link>
                                    </div>
                                </div>
                            );
                        }}
                    </Formik>
                </React.Fragment> : isValidationCompleted ?
                <div className='text-center text-danger'> Invalid reset token. Please try again</div> : null
            }
        </div>
    );
}

CreatePasswordForm.propTypes = {};

export default AuthContainer(CreatePasswordForm);
