import { alias, primitive, serializable, list, object } from "serializr";

export class Course {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('label_name', primitive()))
    displayName?: string;

    @serializable(alias('amount_cents', primitive()))
    amount?: string;

    @serializable(alias('amount_dollars', primitive()))
    amountDollars?: Float32Array;

    @serializable(alias('amount_currency', primitive()))
    currency?: string;
}

export class Courses {
    @serializable(alias('courses', list(object(Course))))
    courses?: Course[];
}
