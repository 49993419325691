import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ModuleContainer from "../../../store/containers/moduleContainer";
import Quiz from "./Quiz";
import BackButton from "../../../shared/components/BackButton";
import "./quiz.scss";
import MetaContainer from "../../../store/containers/metaContainer";
import Loader from "../../../shared/components/Loader";

interface IQuizs extends RouteComponentProps {
    getTopic: Function;
    course: any;
    getModules: Function;
}

function Quizs({
    match: { params },
    location,
    course,
    getModules,
    getTopic,
}: IQuizs) {
    const [topics, setTopic] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const nos: any = location.state;

    useEffect(() => {
        const param: any = params;
        if (course) {
            setLoading(true);
            getTopic(
                course.id,
                param.id,
                (topic: any) => {
                    setTopic(topic);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            );
        }
    }, [params, course]);

    return (
        <div className="list-topic__container">
            <Loader loading={loading} />
            <BackButton text="Back to Modules" />
            <div className="topic-header">
                <p>TOPIC {nos?.no}</p>
                <h2>{topics?.name}</h2>
            </div>
            <div className="list-topics">
                {topics?.quizzes.map((topic: any, index: number) => {
                    return (
                        <Quiz nos={index + 1} topicId={topic.id} quiz={topic} topicDetails={{no: nos?.no, topicName: topics?.name}} />
                    );
                })}
            </div>
        </div>
    );
}

export default ModuleContainer(MetaContainer(Quizs));
