import React, { useState, useRef, Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./reminder.scss";
import PerformanceContainer from "../../../store/containers/performaceContainer";
import MetaContainer from "../../../store/containers/metaContainer";
import { Icon, Popup, Grid } from "semantic-ui-react";

interface IReminder {
    img: any;
    head: string;
    body: string;
    datePicker?: boolean;
    setExamDate: Function;
    course: any;
    getExamDate: any;
    calender?: boolean;
}

function Reminder({
    img,
    head,
    body,
    datePicker = false,
    setExamDate,
    course,
    getExamDate,
    calender = false,
}: IReminder) {
    const [date, setDate] = useState(new Date());
    const dateRef: any = useRef(null);
    const [title, setTitle] = useState<any>(0);

    useEffect(() => {
        setTitle(head ?? 0);
    }, [head]);

    const handleChange = (date: any) => {
        let parsedDate: any = new Date(date);
        parsedDate =
            parsedDate.getFullYear() +
            "-" +
            (parsedDate.getMonth() + 1) +
            "-" +
            parsedDate.getDate();
        if (course) {
            setExamDate(
                {
                    date: parsedDate,
                    courseId: course?.id,
                },
                () => {
                    getExamDate(
                        course?.id,
                        (data: any) => {
                            setTitle(data.daysRemaining);
                        },
                        () => {}
                    );
                    setDate(date);
                },
                () => {
                    setTitle(0);
                }
            );
        }
    };

    const handleDate = () => {
        dateRef?.current?.setFocus();
    };

    return (
        <div className="reminder-container">
            <div className="display-icon">
                {datePicker ? (
                    <Fragment>
                        <Popup
                            trigger={
                                <img src={img} alt="" onClick={handleDate} />
                            }
                            content="Click the calendar to select your exam date"
                            position="top left"
                        />
                        <DatePicker
                            className="date-picker-input"
                            selected={date}
                            onChange={handleChange}
                            ref={dateRef}
                            disabledKeyboardNavigation
                        />
                    </Fragment>
                ) : calender ? (
                    <a href="mailto:email-support@pharmaepass.com?subject=Support for pharmaepass (as of now) will confirm with client.">
                        <Popup
                            trigger={<img src={img} alt="" />}
                            content="Click on the email icon to send us a message"
                            position="top left"
                        />
                    </a>
                ) : (
                    <Popup
                        trigger={<img src={img} alt="" />}
                        content="Click on the email icon to send us a message"
                        position="top center"
                    />
                )}
            </div>
            <div className="display-text">
                <h1>{title}</h1>
                <p>{body}</p>
            </div>
        </div>
    );
}

export default PerformanceContainer(MetaContainer(Reminder));
