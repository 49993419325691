import {serializable, alias, primitive, object, list} from 'serializr';
import { StudentModuleDetails } from "./studentModuleDetails.model"
import { QuizBody } from './quiz.model';

class Content {
    @serializable(alias('url', primitive()))
    url?: string;
}

class Quizz {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class Topic {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('course_module_id', primitive()))
    courseModuleId?: number;

    @serializable(alias('video_url', primitive()))
    videoUrl?: string;

    @serializable(alias('video_embed_tag', primitive()))
    videoEmbedTag?: string;

    @serializable(alias('content', object(Content)))
    content?: Content;

    @serializable(alias('weight', primitive()))
    weight?: number;

    @serializable(alias('completion', primitive()))
    completion?: Float32Array;

    @serializable(alias('average', primitive()))
    average?: Float32Array;

    @serializable(alias('active_quizzes', list(object(QuizBody))))
    quizzes?: QuizBody[];

    @serializable(alias('student_topic_details', list(object(StudentModuleDetails))))
    studentTopicDetails?: StudentModuleDetails[];
    
}
