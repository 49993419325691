import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	Editor,
	EditorState,
	convertToRaw,
	RichUtils,
	convertFromRaw,
} from "draft-js";
import questionContainer from "../../../../../store/containers/questionContainer";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { createtHTMLBlock } from "../../../../../shared/utils/createHTMLComponent";

type ContentType = "left" | "right";

interface IQuestion {
	text: string;
	questionNo: any;
	questions: any;
	type: ContentType;
}

const styleMap = {
	HIGHLIGHT: {
		backgroundColor: "#faed27",
	},
};

function Question({ text, questionNo, questions, type }: IQuestion) {
	const editor = useRef(null);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const updateData = () => {
		const content = localStorage.getItem(`a${questionNo}${type}`);
		if (content) {
			try {
				const contentState = convertFromRaw(
					JSON.parse(
						localStorage.getItem(`a${questionNo}${type}`) as string
					)
				);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {}
		} else {
			if (text) {
				setEditorState(createtHTMLBlock(text));
			}
		}
	};
	useEffect(() => {
		updateData();
	}, [questionNo]);

	const handleKeyCommand = (command: any) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			handleOnChange(newState);
			return "handled";
		}
		return "not-handled";
	};

	const prepopulateLeft = (currentContent: string, contentState: any) => {
		const maxLength = questions?.length;
		if (
			questionNo + 1 < maxLength &&
			questions[questionNo + 1].questionType.content === currentContent
		) {
			localStorage.setItem(
				`a${questionNo + 1}${type}`,
				JSON.stringify(contentState)
			);
		}

		if (
			questionNo + 2 < maxLength &&
			questions[questionNo + 2].questionType.content === currentContent
		) {
			localStorage.setItem(
				`a${questionNo + 2}${type}`,
				JSON.stringify(contentState)
			);
		}

		if (
			questionNo - 1 > maxLength &&
			questions[questionNo - 1].questionType.content === currentContent
		) {
			localStorage.setItem(
				`a${questionNo - 1}${type}`,
				JSON.stringify(contentState)
			);
		}

		if (
			questionNo - 2 > maxLength &&
			questions[questionNo - 2].questionType.content === currentContent
		) {
			localStorage.setItem(
				`a${questionNo - 2}${type}`,
				JSON.stringify(contentState)
			);
		}
	};

	const handleOnChange = (newEditorState: any) => {
		const newblocks = convertToRaw(newEditorState.getCurrentContent())
			.blocks;
		const oldBlocks = convertToRaw(editorState.getCurrentContent()).blocks;
		if (newblocks[0]?.text.trim() === oldBlocks[0]?.text.trim()) {
			setEditorState(newEditorState);
			const contentState = convertToRaw(
				newEditorState.getCurrentContent()
			);
			localStorage.setItem(
				`a${questionNo}${type}`,
				JSON.stringify(contentState)
			);
			if (type === "left") {
				prepopulateLeft(newblocks[0]?.text.trim(), contentState);
			}
		} else {
			updateData();
		}
	};

	const handleHighlight = () => {
		handleOnChange(RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"));
	};

	return (
		<Fragment>
			<ContextMenuTrigger id={`same_unique_identifier_${type}`}>
				<div>
					<Editor
						customStyleMap={styleMap}
						ref={editor}
						editorState={editorState}
						handleKeyCommand={handleKeyCommand}
						onChange={(editorState) => {
							handleOnChange(editorState);
						}}
					/>
				</div>
			</ContextMenuTrigger>
			<ContextMenu
				id={`same_unique_identifier_${type}`}
				className="highlighter-background"
			>
				<MenuItem
					data={{ foo: "Highlight" }}
					className="content-highlighter"
					onClick={handleHighlight}
				>
					Highlight text
				</MenuItem>
				<MenuItem />
			</ContextMenu>
		</Fragment>
	);
}

export default questionContainer(Question);
