import React, { FC } from "react";
import "./button.scss";

interface Options {
	icon?: string;
	iconRight?: boolean;
	classes?: string;
	iconPrimary?: boolean;
	disabled?: boolean;
	clickHandler: Function;
}

const ButtonController: FC<Options> = ({
	icon,
	iconPrimary,
	iconRight,
	disabled,
	clickHandler,
	children,
	classes = "",
}) => {
	return (
		<div
			className={`button-controller__wrapper ${
				disabled ? "button-controller-disabled" : ""
			} ${iconRight ? "button-controller-right" : ""} ${classes} ${
				iconPrimary && "button-controller__primary"
			}`}
			onClick={() => clickHandler()}
		>
			<i className={icon} />
			<p>{children}</p>
		</div>
	);
};

export default ButtonController;
