import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ModuleContainer from "../../../store/containers/moduleContainer";
import Topic from "./Topic";
import BackButton from "../../../shared/components/BackButton";
import MetaContainer from "../../../store/containers/metaContainer";
import Loader from "../../../shared/components/Loader";
import "./topics.scss";

interface ITopics extends RouteComponentProps {
    courseModules: any;
    getTopic: Function;
    getModules: Function;
    course: any;
}

function Topics({ match: { params }, location, course, getModules }: ITopics) {
    const [module, setModule] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const nos: any = location.state;
    useEffect(() => {
        if (course) {
            setLoading(true);
            getModules(
                course.id,
                (data: any) => {
                    const param: any = params;
                    const [result] = data.filter(
                        (course: any) => course?.id === parseInt(param.id)
                    );
                    setModule(result);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            );
        }
    }, [params, course]);

    return (
        <div className="list-topic__container">
            <Loader loading={loading} />
            <BackButton text="Back to Modules" />
            <div className="topic-header">
                {nos?.no ? <p>MODULE {nos.no}</p> : null}
                <h2>{module?.name}</h2>
            </div>

            <div className="list-topics">
                {module?.topics.map((topic: any, index: number) => {
                    return <Topic nos={index + 1} topic={topic} />;
                })}
            </div>
        </div>
    );
}

export default ModuleContainer(MetaContainer(Topics));
