export const BASE_URL = "http://68.183.94.100";
export const APP_URL = "https://app.pharmaepass.com";

/* Auth Routes */
export const AUTH = "/auth";
export const LOGIN = AUTH + "/login";
export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const CREATE_NEW_PASSWORD = AUTH + "/create-new-password";
export const VERIFY_EMAIL = AUTH + "/verify-email";
export const CREATE_ACCOUNT = AUTH + "/create-account";
export const CREATE_BASIC_DETAILS = CREATE_ACCOUNT + "/basic-details";

/* Home Routes*/
export const HOME = "/";

/* Module Lecture */
export const MODULES_LECTURES = "/modules-lectures";
export const MODULES = MODULES_LECTURES + "/modules";
export const MODULE = (id?: number) =>
  `${MODULES_LECTURES}/module/${id ? id : ":id"}`;
export const TOPICS = (id?: number) =>
  `${MODULES_LECTURES}/topics/${id ? id : ":id"}`;
export const TOPIC = (id?: number) =>
  `${MODULES_LECTURES}/topic/${id ? id : ":id"}`;
export const TOPIC_PDF = (id?: number) =>
  `${MODULES_LECTURES}/topics/${id ? id : ":id"}/pdf`;
export const QUIZ = (topicId?: number) =>
  `${MODULES_LECTURES}/topic/${topicId ? topicId : ":id"}/quiz`;
export const VIDEO = (topicId?: number) =>
  `${MODULES_LECTURES}/topic/${topicId ? topicId : ":id"}/video`;
export const EXAM = (quizId?: number) =>
  `${MODULES_LECTURES}/topic/${quizId ? quizId : ":id"}/exam`;

/* Mock Exams */
export const MOCK_EXAMS = "/exam";
export const MOCK_EXAMS_INTRO = "/exam/index";
export const MOCK_EXAMS_EXAM = "/exam/exams";
export const MOCK_EXAMS_RESULT = "/exam/result";
export const MOCK_EXAMS_DISPLAY_ANSWER = "/exam/result/:id";

/* Analytics */
export const ANALYTICS = "/analytics";
export const PERFORMANCE_METRICES = ANALYTICS + "/performance-metrices";

/* Drug Chart*/
export const DRUGS_CHART = "/drug-chart";

/* Purchase Billing */
export const PURCHASE_COURSE = "/purchase-course";

/* Landing Page */
// export const LANDING_PAGE = "/home";

// export const COURSE_PAGE = (courseId?: number) =>
//   `/course/${courseId ? courseId : ":id"}`;

// export const PURCHASE_COURSES = "/enroll-courses";

// export const ABOUT_US = "/about-us";

// export const REFERENCES = "/references";

// export const TERMS_AND_CONDITIONS = "/terms-and-conditions";

export const LIST_BLOG = "/blog";

export const SHOW_BLOG = (blogId?: any) =>
  `/show-blog/${blogId ? blogId : ":id"}`;

// export const FAQ = "/faq";

export const PROFILE = "/profile";
