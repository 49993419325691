import React, { useEffect, useState } from "react";
import "./examResult.scss";
import ResultProgress from "./ResulltProgress";
import Reveal from "./Reveal";
import examContainer from "../../../store/containers/examContainer";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { Results, Result } from "../../../store/models/Exam/Result.model";
import { MOCK_EXAMS_INTRO } from "../../../routes/routeConstants/appRoutes";
import { Question } from "../../../store/models/Module/Question.model";

interface IExamResult extends RouteComponentProps {
	examSession: any;
	results: any;
	questions: Question[];
	setResults: (results: any) => void;
}

function ExamResult({
	examSession,
	results,
	history,
	questions,
	setResults,
	location,
}: IExamResult) {
	const [result, setResult] = useState<Result[]>([]);

	useEffect(() => {
		const length = questions.length;
		const result = Array(length).fill(null);
		questions?.forEach((question: any, index: number) => {
			let res: any = null;
			for (res of results?.examQuestionAnswers) {
				if (question?.id === res?.questionId) {
					result[index] = res;
					return;
				}
			}
		});
		setResult(result);
		setResults({
			...results,
			examQuestionAnswers: result,
		});
	}, []);

	if (!examSession) {
		history.push(MOCK_EXAMS_INTRO);
		return null;
	}

	return (
		<div className="exam-result-container">
			<div className="exam-result__header">
				<h1>
					{examSession?.quizMode === "exam_mode"
						? "Exam Mode"
						: examSession?.quizMode === "study_mode"
						? "Study Mode"
						: "Mock Exam"}
				</h1>
				<p>RESULTS</p>
			</div>
			<div className="exam-result__progress">
				<ResultProgress result={results?.result} />
			</div>
			<div className="exam-result__body">
				<Reveal results={result} />
			</div>
		</div>
	);
}

export default withRouter(examContainer(ExamResult));
