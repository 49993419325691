import { takeEvery, put, delay } from 'redux-saga/effects'
import {INVOKE_TOASTER, REMOVE_TOAST, SET_TOAST} from "../definitions/toastConstants";

function* invokeToasterFlow (action: any) {
    const { type, message } = action.payload;
    yield put({ type: SET_TOAST, payload: { type, message } });
    yield delay(5000);
    yield put({ type: REMOVE_TOAST, payload: {} });
}

export default function* ToastSaga() {
    yield takeEvery(INVOKE_TOASTER,invokeToasterFlow);
}
