import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";

const Content = ({ text, sno }: any) => {
	const [content, setContent] = useState("");

	const checkText = (text: string) => {
		if (text?.split("<p>")?.length === 1) {
			return `<p>${text}</p>`;
		}
		return text;
	};
	useEffect(() => {
		const content: any = Parser(checkText(text));
		let displayText = "";
		if (Array.isArray(content)) {
			content?.forEach((c: any) => {
				if (
					c?.props?.children &&
					c?.props?.children !== " " &&
					typeof c?.props?.children === "string"
				) {
					displayText += c?.props?.children;
				}
			});
			setContent(displayText);
			return;
		}
		if (
			content?.props?.children &&
			content?.props?.children !== " " &&
			typeof content?.props?.children === "string"
		) {
			displayText += content?.props?.children;
			setContent(displayText);
		}
	}, []);

	return (
		<p>
			{sno} {content}
		</p>
	);
};

export default Content;
