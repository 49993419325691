import {alias, primitive, serializable, object, list } from "serializr";

class Attachment {
    @serializable(alias('url', primitive()))
    url?: string;   
}

export class Course {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('completion', primitive()))
    completion?: Float32Array;

    @serializable(alias('active_topic', primitive()))
    activeTopic?: any;
}

export class DrugChart {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('attachment', object(Attachment)))
    attachment?: Attachment;

    @serializable(alias('course', object(Course)))
    course?: Course;
}

export class DrugCharts {
    @serializable(alias('drug_charts', list(object(DrugChart))))
    drugCharts?: DrugChart;
}