"use strict";
exports.__esModule = true;
exports.UPDATE_STRIKE_THROUNG = exports.GET_A_QUESTION = exports.SET_RESULTS_SUCCESS = exports.SET_RESULTS = exports.GET_ANSWER_SUCCESS = exports.GET_ANSWER = exports.UPDATE_ANSWER_SUCCESS = exports.UPDATE_ANSWER = exports.SET_EXAM_ATTEMPT_SUCCESS = exports.SET_EXAM_ATTEMPT = exports.SET_QUESTION_NO = exports.SET_FLAG = exports.PREVIOUS_QUESTION = exports.NEXT_QUESTION = exports.SET_VISITED = exports.SET_QUESTIONS_SUCCESS = exports.SET_QUESTIONS = void 0;
exports.SET_QUESTIONS = "SET_QUESTIONS";
exports.SET_QUESTIONS_SUCCESS = "SET_QUESTIONS_SUCCESS";
exports.SET_VISITED = "SET_VISITED";
exports.NEXT_QUESTION = "NEXT_QUESTION";
exports.PREVIOUS_QUESTION = "PREVIOUS_QUESTION";
exports.SET_FLAG = "SET_FLAG";
exports.SET_QUESTION_NO = "SET_QUESTION_NO";
exports.SET_EXAM_ATTEMPT = "SET_EXAM_ATTEMPT";
exports.SET_EXAM_ATTEMPT_SUCCESS = "SET_EXAM_ATTEMPT_SUCCESS";
exports.UPDATE_ANSWER = "UPDATE_ANSWER";
exports.UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS";
exports.GET_ANSWER = "GET_ANSWER";
exports.GET_ANSWER_SUCCESS = "GET_ANSWER_SUCCESS";
exports.SET_RESULTS = "SET_RESULTS";
exports.SET_RESULTS_SUCCESS = "SET_RESULTS_SUCCESS";
exports.GET_A_QUESTION = "GET_A_QUESTION";
exports.UPDATE_STRIKE_THROUNG = "update_strike_through";
