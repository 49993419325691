import React, { useState, useCallback, Fragment, useEffect } from "react";
import QuestionPalette from "./QuestionPalette";
import QuestionNumberPannel from "./QuestionNumberPannel";
import Toggler from "./QuestionNumberPannel/QuestionNumber";
import "./examForm.scss";
import examContainer from "../../../store/containers/examContainer";
import { RouteComponentProps } from "react-router-dom";
import { MOCK_EXAMS_INTRO } from "../../../routes/routeConstants/appRoutes";
import Draggable from "react-draggable";
const Calculator = require("awesome-react-calculator").default;

const style = {
	height: "40vh",
	width: "50vh",
};

interface IExamForm extends RouteComponentProps {
	examSession: any;
}

const  warningMessage = "Exam cannot be continued and this attempt will not be considered for score.";


const confirmationOnReload = (e: {
	returnValue: string;
	preventDefault: Function;
}) => {
	e.preventDefault();
	e.returnValue = warningMessage
};



function ExamForm({ examSession, history, location }: IExamForm) {
	const [reload, setReload] = useState(0);
	const [show, setShow] = useState<boolean>(true);
	const [showCalc, setShowCalc] = useState<boolean>(false);

	useEffect(() => {
		window.addEventListener("beforeunload", confirmationOnReload);
		return () => {
			window.removeEventListener("beforeunload", confirmationOnReload);
		};
	}, []);

	const handleShowCalculator = useCallback(() => {
		setShowCalc((state) => !state);
	}, []);
	const handleClick = useCallback(() => {
		setShow((state) => !state);
	}, []);
	if (!examSession) {
		history.push(MOCK_EXAMS_INTRO);
		return null;
	}

	const handleInput = (input: any) => {};

	const onResultChange = (newResult: any) => {};

	const handleReload = () => {
		setReload((current) => current + 1);
	};

	return (
		<Fragment>
			<div className="exam-form-container">
				<div className="exam-form__header">
					<h1>
						{" "}
						{examSession?.quizMode === "study_mode"
							? "Study Mode"
							: examSession?.quizMode === "exam_mode"
							? "Exam Mode"
							: "Mock Exam"}
					</h1>
					<Toggler
						classes="exam-form-drop-down-icon"
						completed
						clickHandler={handleClick}
						questionNo={
							<i
								className={`chevron ${
									show ? "up" : "down"
								} icon`}
							/>
						}
					/>
				</div>
				<div className="exam-form__wrapper">
					<QuestionPalette
						mode="mock"
						calculatorHandler={handleShowCalculator}
						calc={showCalc}
						reload={reload}
					/>
					{show && (
						<QuestionNumberPannel reloadHandler={handleReload} />
					)}
				</div>
			</div>
			{showCalc && (
				<Draggable
					defaultPosition={{ x: 0, y: -500 }}
					grid={[25, 25]}
					scale={1}
				>
					<div
						className="handle calculator-container"
						style={{ ...style, position: "relative" }}
					>
						<Calculator
							onNewInput={handleInput}
							onResultChange={onResultChange}
						/>
					</div>
				</Draggable>
			)}
		</Fragment >
	);
}

export default examContainer(ExamForm);
