export const checkWebSite = (): boolean => {
    const { pathname } = window?.location;
    const key = pathname.split("/")[1];
    return (
        key === "home" ||
        key === "course" ||
        key === "enroll-courses" ||
        key === "about-us" ||
        key === "references" ||
        key === "terms-and-conditions" ||
        key === "blog" ||
        key === "faq" ||
        key === "show-blog"
    );
};
