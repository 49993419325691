import React, { useEffect, useState, Fragment } from "react";
import "./displayAnswer.scss";
import UIButton from "../../../../shared/components/Button";
import { RouteComponentProps } from "react-router-dom";
import examContainer from "../../../../store/containers/examContainer";
import Option from "../../ExamForm/QuestionPalette/Option";
import { Icon } from "semantic-ui-react";
import {
	MOCK_EXAMS_RESULT,
	MOCK_EXAMS_INTRO,
} from "../../../../routes/routeConstants/appRoutes";
import { ExamBody } from "../../../../store/models/Exam/Attempt.model";
import { Link } from "react-router-dom";
import ModuleContainer from "../../../../store/containers/moduleContainer";
import MetaContainer from "../../../../store/containers/metaContainer";
import  Parser from "html-react-parser";

interface IDisplayAnswer extends RouteComponentProps {
	getAnswer: Function;
	getAQuestion: Function;
	results: any;
	examSession: any;
	getSingleQuestion: Function;
	getAAnswer: Function;
	course: any;
}

function DisplayAnswer({
	history,
	match,
	getAQuestion,
	getAnswer,
	results,
	examSession,
	getSingleQuestion,
	getAAnswer,
	course
}: IDisplayAnswer) {
	const [data, setData] = useState<any>(undefined);
	const { id }: any = match.params;
	// const options = JSON.parse(JSON.stringify(questions[questionNo].options))
	// const keys = Object.keys(options);
	
	useEffect(() => {
		if (match.params) {
			const { id }: any = match.params;
			if (!id || !examSession) {
				history.push(MOCK_EXAMS_INTRO);
				return;
			} 
		if (examSession?.quizMode === "exam_mode" || examSession?.quizMode ==="study_mode" ) {
				if (course) {
					getSingleQuestion(
						course.id,
						examSession.id,
						id,
						(data1: any) => {
							getAAnswer(
								course.id,
								data1.examAnswerId.id,
								(data2: any) => {
									setData({
										...data1,
										...data2,
									});
								},
								() => { }
							);
						},
						() => { }
					);
				}
			} else {
				getAQuestion(
					examSession.id,
					id,
					(data1: any) => {
						getAnswer(
							data1.examAnswerId.id,
							(data2: any) => {
								setData({
									...data1,
									...data2,
								});
							},
							() => { }
						);
					},
					() => { }
				);
			}
		}
	}, []);

	const handleSelect = () => { };

	const handleNext = () => {
		results.examQuestionAnswers.forEach((result: any, index: number) => {
			const { id }: any = match.params;
			if (result.questionId === parseInt(id)) {
				history.push(
					`${MOCK_EXAMS_RESULT}/${
					results.examQuestionAnswers[index + 1].questionId
					}`
				);
			}
		});
	};

	const handlePrevious = () => {
		results.examQuestionAnswers.forEach((result: any, index: number) => {
			const { id }: any = match.params;
			if (result.questionId === parseInt(id)) {
				history.push(
					`${MOCK_EXAMS_RESULT}/${
					results.examQuestionAnswers[index - 1].questionId
					}`
				);
			}
		});
	};

	if (!examSession || !results) {
		history.push(MOCK_EXAMS_INTRO);
		return null;
	}
	if (!data) {
		return null;
	}

	return (
		<div className="answer-container">

			<Link className="back-button" to={MOCK_EXAMS_RESULT}>
				<i className="arrow left icon" /> Back
			</Link>

			<div className="result-answer__container">
				<div className="result-answer__inner__container">
					<div className="result-answer__header">
						<p>Question</p>
					</div>
					<div
						className={`result-answer__body ${
							data.questionType.category === "case"
								? "result-answer__split__case "
								: ""
							}`}
					>
						<div className="result-answer__case__right">
							{data.questionType.category === "case" ? (
								<p>{Parser(data.questionType.content)}</p>
							) : null}
						</div>
						<div className="result-answer__case__left">
							<div className="result-answer__question">
								<p>{Parser(data?.content)}</p>
							</div>
							<div className="question-palette__options">
								{Object.keys(JSON.parse(JSON.stringify(data?.options))).map(
									(option: any, index: number) => {
										return (
											<Option
												key={index}
												index={option}
												name="selected"
												selectHandler={handleSelect}
												selected={option === data.answer}
												correct={
													option === data.correctAnswer
												}
												wrong={
													option === data.answer &&
													data.answer !== data.correct
												}
												position={index}
											>
												{data.options[option]}
											</Option>
										);
									}
								)}
							</div>
						</div>
					</div>
					<div className="result-answer__footer">
						<UIButton
							type="submit"
							icon="arrow left icon"
							iconLeft
							classes="question-palette-xl"
							disabled={
								results.examQuestionAnswers[0].questionId ===
								parseInt(id)
							}
							clickHandler={handlePrevious}
						>
							Previous Answer
					</UIButton>
						<UIButton
							type="submit"
							icon="arrow left icon"
							iconLeft
							classes="question-palette-sm"
							disabled={
								results.examQuestionAnswers[0].questionId ===
								parseInt(id)
							}
							clickHandler={handlePrevious}
						>
							Previous
					</UIButton>
						<UIButton
							type="submit"
							icon="arrow right icon"
							classes="question-palette-sm"
							disabled={
								results.examQuestionAnswers[
									results.examQuestionAnswers.length - 1
								].questionId === parseInt(id)
							}
							clickHandler={handleNext}
						>
							Next
					</UIButton>
						<UIButton
							type="submit"
							icon="arrow right icon"
							classes="question-palette-xl"
							disabled={
								results.examQuestionAnswers[
									results.examQuestionAnswers.length - 1
								].questionId === parseInt(id)
							}
							clickHandler={handleNext}
						>
							Next Answer
					</UIButton>
					</div>
					<div className="result-answer__answer">
						<p>{Parser(data?.question.answerExplanation)}</p>
					</div>
				</div>
			</div>
		</div>

	);
}

export default examContainer(ModuleContainer(MetaContainer(DisplayAnswer)));
