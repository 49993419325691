import React from 'react'
import ListItem from "./ListItem"
import "./list.scss"

interface IList {
    items: Array<any>;
    minimal?: boolean;
    avegrage?: boolean;
    description?: boolean;
}

function List({ items, minimal, avegrage = false, description }: IList) {
    return (
        <div className="list-container">
            {
                items?.map((item: any, index: number) => (
                    <ListItem item={item} index={index} description={description} minimal={minimal}  avegrage={avegrage}/>
                ))
            }
        </div>
    )
}

export default List