import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
    setQuestions,
    setVisited,
    nextQuestion,
    previousQuestion,
    setFlag,
    setQuestionNo,
    createAttempt,
    updateAnswer,
    getResults,
    getAnswer,
    getAQuestion,
    updateStrikeThrough,
    setResults,
    setExamAttempt
} from "../actions/examAction";

export default function examContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            nextQuestion,
            setQuestionNo,
            previousQuestion,
            setQuestions,
            setVisited,
            setFlag,
            createAttempt,
            updateAnswer,
            getResults,
            getAnswer,
            getAQuestion,
            updateStrikeThrough,
            setResults,
            setExamAttempt,
        },
        dispatch
    );
}

function mapStateToProps(state: any, ownProps: any) {
    const {
        questions,
        questionNo,
        visitedQuestions,
        bunchQuestions,
        examSession,
        results,
        strikeThroungh,
    } = state.exam;
    return {
        results,
        questions,
        questionNo,
        visitedQuestions,
        strikeThroungh,
        bunchQuestions,
        examSession,
        ...ownProps,
    };
}
