"use strict";
exports.__esModule = true;
exports.PROFILE = exports.FAQ = exports.SHOW_BLOG = exports.LIST_BLOG = exports.TERMS_AND_CONDITIONS = exports.REFERENCES = exports.ABOUT_US = exports.PURCHASE_COURSES = exports.COURSE_PAGE = exports.LANDING_PAGE = exports.PURCHASE_COURSE = exports.DRUGS_CHART = exports.PERFORMANCE_METRICES = exports.ANALYTICS = exports.MOCK_EXAMS_DISPLAY_ANSWER = exports.MOCK_EXAMS_RESULT = exports.MOCK_EXAMS_EXAM = exports.MOCK_EXAMS_INTRO = exports.MOCK_EXAMS = exports.EXAM = exports.VIDEO = exports.QUIZ = exports.TOPIC_PDF = exports.TOPIC = exports.TOPICS = exports.MODULE = exports.MODULES = exports.MODULES_LECTURES = exports.HOME = exports.CREATE_BASIC_DETAILS = exports.CREATE_ACCOUNT = exports.VERIFY_EMAIL = exports.CREATE_NEW_PASSWORD = exports.FORGOT_PASSWORD = exports.LOGIN = exports.AUTH = exports.BASE_URL = void 0;
exports.BASE_URL = "http://68.183.94.100";
/* Auth Routes */
exports.AUTH = "/auth";
exports.LOGIN = exports.AUTH + "/login";
exports.FORGOT_PASSWORD = exports.AUTH + "/forgot-password";
exports.CREATE_NEW_PASSWORD = exports.AUTH + "/create-new-password";
exports.VERIFY_EMAIL = exports.AUTH + "/verify-email";
exports.CREATE_ACCOUNT = exports.AUTH + "/create-account";
exports.CREATE_BASIC_DETAILS = exports.CREATE_ACCOUNT + "/basic-details";
/* Home Routes*/
exports.HOME = "/";
/* Module Lecture */
exports.MODULES_LECTURES = "/modules-lectures";
exports.MODULES = exports.MODULES_LECTURES + "/modules";
exports.MODULE = function (id) {
    return exports.MODULES_LECTURES + "/module/" + (id ? id : ":id");
};
exports.TOPICS = function (id) {
    return exports.MODULES_LECTURES + "/topics/" + (id ? id : ":id");
};
exports.TOPIC = function (id) {
    return exports.MODULES_LECTURES + "/topic/" + (id ? id : ":id");
};
exports.TOPIC_PDF = function (id) {
    return exports.MODULES_LECTURES + "/topics/" + (id ? id : ":id") + "/pdf";
};
exports.QUIZ = function (topicId) {
    return exports.MODULES_LECTURES + "/topic/" + (topicId ? topicId : ":id") + "/quiz";
};
exports.VIDEO = function (topicId) {
    return exports.MODULES_LECTURES + "/topic/" + (topicId ? topicId : ":id") + "/video";
};
exports.EXAM = function (quizId) {
    return exports.MODULES_LECTURES + "/topic/" + (quizId ? quizId : ":id") + "/exam";
};
/* Mock Exams */
exports.MOCK_EXAMS = "/exam";
exports.MOCK_EXAMS_INTRO = "/exam/index";
exports.MOCK_EXAMS_EXAM = "/exam/exams";
exports.MOCK_EXAMS_RESULT = "/exam/result";
exports.MOCK_EXAMS_DISPLAY_ANSWER = "/exam/result/:id";
/* Analytics */
exports.ANALYTICS = "/analytics";
exports.PERFORMANCE_METRICES = exports.ANALYTICS + "/performance-metrices";
/* Drug Chart*/
exports.DRUGS_CHART = "/drug-chart";
/* Purchase Billing */
exports.PURCHASE_COURSE = "/purchase-course";
/* Landing Page */
exports.LANDING_PAGE = "/home";
exports.COURSE_PAGE = function (courseId) {
    return "/course/" + (courseId ? courseId : ":id");
};
exports.PURCHASE_COURSES = "/enroll-courses";
exports.ABOUT_US = "/about-us";
exports.REFERENCES = "/references";
exports.TERMS_AND_CONDITIONS = "/terms-and-conditions";
exports.LIST_BLOG = "/blog";
exports.SHOW_BLOG = function (blogId) { return "/show-blog/" + (blogId ? blogId : ":id"); };
exports.FAQ = "/faq";
exports.PROFILE = "/profile";
