import {combineReducers} from 'redux';
import authReducer from "./authReducer";
import toastReducer from "./toastReducer";
import metaReducer from "./metaReducer";
import examReducer from './examReducer';
import moduleReducer from './moduleReducer';
import performanceReducer from './performanceReducer';
import questionReducer from './questionsReducer';

const RootReducer = combineReducers({
    auth: authReducer,
    toast: toastReducer,
    meta: metaReducer,
    exam: examReducer,
    module: moduleReducer,
    performance: performanceReducer,
    question: questionReducer,
});

export default RootReducer;
