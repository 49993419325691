export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_QUESTIONS_SUCCESS = "SET_QUESTIONS_SUCCESS";
export const SET_VISITED = "SET_VISITED";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const PREVIOUS_QUESTION = "PREVIOUS_QUESTION";
export const SET_FLAG = "SET_FLAG";
export const SET_QUESTION_NO = "SET_QUESTION_NO";
export const SET_EXAM_ATTEMPT = "SET_EXAM_ATTEMPT";
export const SET_EXAM_ATTEMPT_SUCCESS = "SET_EXAM_ATTEMPT_SUCCESS";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS";
export const GET_ANSWER = "GET_ANSWER";
export const GET_ANSWER_SUCCESS = "GET_ANSWER_SUCCESS";
export const SET_RESULTS = "SET_RESULTS";
export const SET_RESULTS_SUCCESS = "SET_RESULTS_SUCCESS";
export const GET_A_QUESTION = "GET_A_QUESTION";
export const UPDATE_STRIKE_THROUNG = "update_strike_through";