import React from "react";
import { CorrectIcon } from "../../../MockExam/ExamResult/Reveal/Icon";
import { Progress } from "semantic-ui-react";
import "./topic.scss";
import ProgressBar from "../../../../shared/components/Progressbar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TOPIC_PDF } from "../../../../routes/routeConstants/appRoutes";

interface ITopic extends RouteComponentProps {
	topic: any;
	nos: number;
	title?: string;
}

function Topic({ topic, nos, history, title }: ITopic) {
	const handleClick = () => {
		history.push({
			pathname: TOPIC_PDF(topic.id),
			state: {
				no: nos,
				back: "to Topics"
			}
		})
	}

	return (
		<div className="topic-container" onClick={handleClick}>
			{/* {topic?.completion >= 50 ? ( */}
			<ProgressBar
				percentage={topic?.completion}
				type={topic?.completion >= 70 ? "success" : topic?.completion >= 50 ? "warning" : "danger"}
			/>
			{/* ) : null} */}

			<div className="text-container">
				<div className="text">
					<p>{title ? title : "TOPIC"} {nos}</p>
					<h2
						className={
							topic?.completion >= 70
								? "success-text"
								: topic?.completion >= 50
									? "warning-text"
									: ""
						}
					>
						{topic.name}{" "}
						{topic.completion === 100 && <CorrectIcon />}
					</h2>
				</div>
				<i className="right arrow icon"></i>
			</div>
		</div>
	);
}

export default withRouter(Topic);
