import React, { useEffect, useState } from "react";
import DownloadIcon from "../../assets/images/download.svg";
import PerformanceContainer from "../../store/containers/performaceContainer";
import MetaContainer from "../../store/containers/metaContainer";
import { DrugChart as DrugChartModel } from "../../store/models/DrugChart/DrugChart.model";
import Loader from "../../shared/components/Loader";
import "./drugChart.scss";

interface IDrugChart {
  getDrugChart: Function;
  course: any;
}

function DrugChart({ getDrugChart, course }: IDrugChart) {
  const [drugs, setDrugs] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (course) {
      setLoading(true);
      getDrugChart(
        course?.id,
        (data: DrugChartModel[]) => {
          setDrugs(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [course]);

  return (
    <div className="application-body drug-chart__container">
      <Loader loading={loading} />
      <div className="header">
        <h1>Announcements</h1>
      </div>
      <div className="content">
        <p>
          This section is used to inform you of any upcoming changes/updates
          including information we recommend you review
        </p>
      </div>

      <div className="download">
        {drugs?.map((drug: DrugChartModel) => {
          const [fileName]: any = drug.attachment?.url
            ?.split("/")
            .reverse()[0]
            ?.split("?");
          return (
            <a href={drug.attachment?.url} download target="_blank">
              <div className="download__btn">
                <div className="text">
                  <p>DOWNLOAD</p>
                  <h3>{fileName}</h3>
                </div>
                <div className="download-image">
                  <img src={DownloadIcon} alt="" />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default PerformanceContainer(MetaContainer(DrugChart));
