import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { getCountriesAction, getCoursesAction, setSelectedCourse, getUserCourse, updateSelectedCourse, search } from "../actions/metaActions";

export default function MetaContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        getCountriesAction,
        getCoursesAction,
        setSelectedCourse,
        getUserCourse,
        updateSelectedCourse,
        search
    }, dispatch);
}

function mapStateToProps(state: any, ownProps: any) {
    return {
        countryMetas: state.meta.countryMetas,
        courseMetas: state.meta.courseMetas,
        course: state.meta.course,
        userCourse: state.meta.userCourse,
        ...ownProps
    };
}
