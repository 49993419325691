import React, { useEffect, useState } from "react";
import { CorrectIcon } from "../../../MockExam/ExamResult/Reveal/Icon";
import { Progress } from "semantic-ui-react";
import "./quiz.scss";
import ProgressBar from "../../../../shared/components/Progressbar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TOPIC_PDF, EXAM } from "../../../../routes/routeConstants/appRoutes";
import ModuleContainer from "../../../../store/containers/moduleContainer";
import MetaContainer from "../../../../store/containers/metaContainer";

interface ITopic extends RouteComponentProps {
	topic: any;
	nos: number;
	getQuiz: Function;
	course: any;
	topicId: number;
	quiz: any;
	topicDetails: {
		no: number, 
		topicName: string
	};
}

function Topic({
	topic,
	nos,
	history,
	getQuiz,
	course,
	topicId,
	quiz,
	topicDetails,
}: ITopic) {
	const handleClick = () => {
		history.push({
			pathname: EXAM(quiz.id),
			state: {topicDetails, back: "to Quizs"},
		});
	};
	
	return (
		<div className="topic-container" onClick={handleClick}>
			{/* {quiz?.completion >= 50 ? ( */}
			<ProgressBar
				percentage={
					quiz?.completion?.completionValue >= 100
						? 100
						: quiz?.completion?.completionValue
				}
				type={
					quiz?.completion?.completionValue >= 70
						? "success"
						: quiz?.completion?.completionValue >= 50
						? "warning"
						: "danger"
				}
			/>
			{/* ) : null} */}

			<div className="text-container">
				<div className="text">
					<p> Quiz {nos}</p>
					<h2
						className={
							quiz?.completion?.completionValue >= 70
								? "success-text"
								: quiz?.completion?.completionValue >= 50
								? "warning-text"
								: ""
						}
					>
						{quiz?.name}{" "}
						{quiz?.completion?.completionValue === 100 && (
							<CorrectIcon />
						)}
					</h2>
				</div>
				<i className="right arrow icon"></i>
			</div>
			<p className="completion-nessage">
				{quiz?.completion?.completionMessage}
			</p>
		</div>
	);
}

export default withRouter(MetaContainer(ModuleContainer(Topic)));
