import { UPDATE_QUESTIONS } from "../definitions/questionContants";

export function updateQuestion(
    question: any,
    questionNo: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: UPDATE_QUESTIONS,
        payload: {
            question,
            questionNo,
            successCallback,
            errCallback,
        },
    };
}
