import { CreateReducer } from "../../shared/utils/createReducer";
import {
    GET_MODULES_SUCCESS,
    GET_MODULE_SUCCESS,
    GET_TOPIC_SUCCESS,
} from "../definitions/moduleConstants";

interface IModuleState {
    courseModules: any;
    courseModule: any;
    topic: any;
}

interface IAction {
    action: any;
    payload: any;
}

const initState: IModuleState = {
    courseModules: null,
    courseModule: null,
    topic: null,
};

const moduleReducer = CreateReducer(initState, {
    [GET_MODULES_SUCCESS](state: IModuleState, action: IAction) {
        return {
            ...state,
            courseModules: action.payload,
        };
    },
    [GET_TOPIC_SUCCESS](state: IModuleState, action: IAction) {
        return {
            ...state,
            topic: action.payload,
        };
    },
});

export default moduleReducer;
