import React from "react";
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteChildrenProps,
} from "react-router-dom";
import Modules from "./Modules";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import Topics from "./Topics";
import ViewPDF from "./ViewPDF";
import Quiz from "./Quizs";
import Video from "./Video";
import ExamDescription from "./ExamDescription";
import Module from "./Module";
import Topic from "./Topic";
import "./modulesLectures.scss"

function ModulesLectures({ location }: RouteChildrenProps) {
    const path = location.pathname.split("/");
    return (
        <div
            className={
                path[path.length - 1] === "pdf" ||
                path[path.length - 1] === "video"
                    ? ""
                    : "application-body"
            }
        >
            <Switch>
                <Route
                    exact
                    path={appRoutes.MODULES}
                    component={(props: any) => <Modules {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.MODULE()}
                    component={(props: any) => <Module {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.TOPIC()}
                    component={(props: any) => <Topic {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.TOPICS()}
                    component={(props: any) => <Topics {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.TOPIC_PDF()}
                    component={(props: any) => <ViewPDF {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.QUIZ()}
                    component={(props: any) => <Quiz {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.VIDEO()}
                    component={(props: any) => <Video {...props} />}
                />
                <Route
                    exact
                    path={appRoutes.EXAM()}
                    component={(props: any) => <ExamDescription {...props} />}
                />
                <Redirect to={appRoutes.MODULES} />
            </Switch>
        </div>
    );
}

export default withRouter(ModulesLectures);
