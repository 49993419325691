import { takeEvery, call, put } from "redux-saga/effects";
import { ApiService } from "../services/ApiService";
import { deserialize } from "serializr";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import {
    GET_MODULES,
    GET_MODULES_SUCCESS,
    GET_TOPIC,
    GET_TOPIC_SUCCESS,
    GET_QUIZ,
    EXAM_ATTEMPT,
    SET_ALL_QUESTION,
    GET_QUESTION,
    GET_A_ANSWER,
    UPDATE_QUIZ_ANSWER,
    END_TEST,
    GET_MODULE,
    GET_MODULE_SUCCESS,
    GET_FILTER_QUIZ_ATTEMPT,
    START_FILTER_QUIZ_ATTEMPT,
} from "../definitions/moduleConstants";
import {
    MODULE_LECTURES,
    CREATE_EXAM_ATTEMPT,
    GET_ALL_QUESTIONS,
    GET_SINGLE_QUESTION_QUIZ,
    GET_SINGLE_ANSWER_QUIZ,
    UPDATE_A_QUIZ,
    END_QUIZ,
    QUIZ_FILTER_POST_API,
} from "../../routes/routeConstants/apiRoutes";
import {
    CourseModule,
    CourseModules,
} from "../models/Module/courseModule.model";
import {
    GET_TOPIC as GET_SIGNLE_TOPIC,
    GET_QUIZ as GET_SINGLE_QUIZ,
} from "../../routes/routeConstants/apiRoutes";
import { Topic } from "../models/Module/topic.model";
import { Quiz } from "../models/Module/quiz.model";
import { ExamAttempt } from "../models/Module/Attempt.model";
import {
    SET_EXAM_ATTEMPT_SUCCESS,
    SET_QUESTIONS_SUCCESS,
    SET_RESULTS_SUCCESS,
    SET_QUESTION_NO,
} from "../definitions/examConstants";
import { Questions } from "../models/Module/Questions.model";
import { Question } from "../models/Module/Question.model";
import { ExamAnswer } from "../models/Module/Answer.model";
import { Results } from "../models/Module/Result.model";
import {
    GET_MODULE_LECTURE,
    QUIZ_FILTER_GET_API,
} from "../../routes/routeConstants/apiRoutes";

const apiService = new ApiService();

/* Executor Sagas */

function* getModule(action: any) {
    const { id, successCallback, errCallback } = action.payload;
    let response: any;
    if (id) {
        try {
            response = yield call(apiService.get, MODULE_LECTURES(id));
            if (response.data) {
                const deserializedCourseData = deserialize(
                    CourseModules,
                    response.data
                );
                yield put({
                    type: GET_MODULES_SUCCESS,
                    payload: deserializedCourseData.courseModules,
                });

                successCallback(deserializedCourseData.courseModules);
            } else {
                errCallback();
                yield put({
                    type: INVOKE_TOASTER,
                    payload: { type: "error", message: response["message"] },
                });
            }
        } catch (error) {
            const errorResponse = error.response.data;
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: errorResponse["error"] },
            });
        }
    }
}

function* getSingleModule(action: any) {
    const { courseId, moduleId, successCallback, errCallback } = action.payload;
    let response: any;
    if (courseId && moduleId) {
        try {
            response = yield call(
                apiService.get,
                GET_MODULE_LECTURE(courseId, moduleId)
            );
            if (response.data) {
                const deserializedCourseData = deserialize(
                    CourseModule,
                    response.data?.course_module
                );
                successCallback(deserializedCourseData);
            } else {
                errCallback();
                yield put({
                    type: INVOKE_TOASTER,
                    payload: { type: "error", message: response["message"] },
                });
            }
        } catch (error) {
            const errorResponse = error.response.data;
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: errorResponse["error"] },
            });
        }
    }
}

function* getTopic(action: any) {
    const { courseId, moduleId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            GET_SIGNLE_TOPIC(courseId, moduleId)
        );

        if (response.data) {
            const deserializedTopic = deserialize(
                Topic,
                response.data["topic"]
            );
            yield put({ type: GET_TOPIC_SUCCESS, payload: deserializedTopic });
            successCallback(deserializedTopic);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getQuiz(action: any) {
    const {
        courseId,
        topicId,
        quizId,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            GET_SINGLE_QUIZ(courseId, topicId, quizId)
        );
        if (response?.data) {
            const quizz = deserialize(Quiz, response?.data);
            successCallback(quizz.quiz);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback(error);
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* createExamAttempt(action: any) {
    const {
        courseId,
        quizId,
        payload,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.post,
            CREATE_EXAM_ATTEMPT(courseId, quizId),
            payload
        );
        if (response.data) {
            const examAttempy = deserialize(
                ExamAttempt,
                response.data["quiz_attempt"]
            );
            yield put({ type: SET_EXAM_ATTEMPT_SUCCESS, payload: examAttempy });
            yield put({ type: SET_QUESTION_NO, payload: 0 });
            successCallback(examAttempy);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getAllQuestions(action: any) {
    const {
        courseId,
        quizAttemptId,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            GET_ALL_QUESTIONS(courseId, quizAttemptId)
        );
        if (response.data) {
            const questions = deserialize(Questions, response.data);
            yield put({
                type: SET_QUESTIONS_SUCCESS,
                payload: questions.questions,
            });
            successCallback();
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getSingleQuestion(action: any) {
    const {
        courseId,
        examAttemptId,
        questionId,
        successCallback,
        errCallback,
    } = action.payload;

    let response: any;
    try {
        response = yield call(
            apiService.get,
            GET_SINGLE_QUESTION_QUIZ(courseId, examAttemptId, questionId)
        );
        if (response.data) {
            const question = deserialize(Question, response.data);
            successCallback(question.question);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getAAnswer(action: any) {
    const { courseId, answerId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            GET_SINGLE_ANSWER_QUIZ(courseId, answerId)
        );
        if (response.data) {
            const answer = deserialize(ExamAnswer, response.data);
            successCallback(answer.examAnswer);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* updateQuestionOption(action: any) {
    const {
        courseId,
        questionId,
        data,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.put,
            UPDATE_A_QUIZ(courseId, questionId),
            data
        );
        if (response.data) {
            const answer = deserialize(ExamAnswer, response.data);
            successCallback(answer.examAnswer);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getResults(action: any) {
    const {
        courseId,
        quizAttemptId,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            END_QUIZ(courseId, quizAttemptId)
        );
        if (response.data) {
            const results = deserialize(Results, response.data);
            yield put({ type: SET_RESULTS_SUCCESS, payload: results });
            successCallback();
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* getFilteredAttempt(action: any) {
    const {
        courseId,
        quizId,
        mode,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.get,
            `${QUIZ_FILTER_GET_API(courseId, quizId)}?quiz_mode=${mode}`
        );
        if (response.data) {
            successCallback(response?.data?.questions_count);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error?.response?.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

function* createFilterExamAttempt(action: any) {
    const {
        courseId,
        quizId,
        payload,
        successCallback,
        errCallback,
    } = action.payload;
    let response: any;
    try {
        response = yield call(
            apiService.post,
            QUIZ_FILTER_POST_API(courseId, quizId),
            payload
        );
        if (response.data) {
            const examAttempy = deserialize(
                ExamAttempt,
                response.data["quiz_attempt"]
            );
            yield put({ type: SET_EXAM_ATTEMPT_SUCCESS, payload: examAttempy });
            yield put({ type: SET_QUESTION_NO, payload: 0 });
            successCallback(examAttempy);
        } else {
            errCallback();
            yield put({
                type: INVOKE_TOASTER,
                payload: { type: "error", message: response["message"] },
            });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message: errorResponse["error"] },
        });
    }
}

/*Watcher sagas*/

export default function* MetaSaga() {
    yield takeEvery(GET_MODULES, getModule);
    yield takeEvery(GET_MODULE, getSingleModule);
    yield takeEvery(GET_TOPIC, getTopic);
    yield takeEvery(GET_QUIZ, getQuiz);
    yield takeEvery(EXAM_ATTEMPT, createExamAttempt);
    yield takeEvery(SET_ALL_QUESTION, getAllQuestions);
    yield takeEvery(GET_QUESTION, getSingleQuestion);
    yield takeEvery(GET_A_ANSWER, getAAnswer);
    yield takeEvery(UPDATE_QUIZ_ANSWER, updateQuestionOption);
    yield takeEvery(END_TEST, getResults);
    yield takeEvery(GET_FILTER_QUIZ_ATTEMPT, getFilteredAttempt);
    yield takeEvery(START_FILTER_QUIZ_ATTEMPT, createFilterExamAttempt);
}
