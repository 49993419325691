
import {serializable, alias, primitive, object, list } from 'serializr';

export class topicStrengthArea {
    @serializable(alias('topic_name', primitive()))
    name?: string;

    @serializable(alias('topic_average', primitive()))
    completion?: string;
}

export class topicStrengthAreas {
    @serializable(alias('topic_strength_areas', list(object(topicStrengthArea))))
    topicStrengthAreas?: topicStrengthArea[];
}