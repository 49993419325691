import React, {useEffect} from 'react';
import './authWrapper.scss';
import PropTypes from 'prop-types';
import AuthRoutes from "../authRoutes";
import { withRouter } from "react-router-dom";
import AuthContainer from "../../../store/containers/authContainer";
import companyLogo from "../../../assets/images/pharma-epass-logo.png";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

function AuthWrapper(props: any) {
    const { authenticated, history, user } = props;
    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__logo-section">
                <div className="auth-wrapper__logo-section-content">
                    <img src={companyLogo} alt='Pharma E pass logo'/>
                </div>
            </div>
            <div className="auth-wrapper__auth-section">
                <AuthRoutes/>
            </div>
        </div>
    );
}

AuthWrapper.propTypes = {};

export default AuthContainer(withRouter(AuthWrapper));
