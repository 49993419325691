import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import { Formik, Field } from "formik";
import { Form } from "semantic-ui-react";
import FormErrorMessage from "../../shared/components/FormErrorMessage";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as yup from "yup";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Button from "../../shared/components/Button";
import AuthContainer from "../../store/containers/authContainer";
import { withRouter, useLocation } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import "./purchaseCourse.scss";
import metaContainer from "../../store/containers/dist/metaContainer";
import MetaContainer from "../../store/containers/metaContainer";

const ValidationSchema = yup.object({
    nameOnCard: yup.string().required("Name on card is required"),
    billingAddress: yup.string().required("Billing address is required"),
});

const purchaseCourseInitialValue = {
    nameOnCard: "",
    billingAddress: "",
};

function PurchaseCourse({ getUserCourse, history, purchaseCourse, getCoursesAction, disableNavigation }: any) {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const CardOptions = {
        hidePostalCode: true,
        style: {
            border: "1px solid red",
            base: {
                color: "#172B4D",
                fontSize: "18px",
                "::placeholder": {
                    color: "rgba(34,36,38,.3)",
                },

            },
        },
    };

    const onSubmitHandler = async (values: any) => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement: any = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: values.nameOnCard ?? "",
                email: values.email,
                address: {
                    line1: values.billingAddress,
                    country: values.country?.name,
                    postal_code: values.postalCode,
                },
            },
        });

        if (error) {
            return;
        }

        getCoursesAction((courses: any) => {
            const courseName = new URLSearchParams(location.search).get("course")?.toUpperCase();
            const meta = courses?.find((course: any) => course?.name?.toUpperCase() === courseName)
            purchaseCourse({
                courseId: meta?.id,
                paymentId: paymentMethod?.id,

            }, () => {
                getUserCourse(() => {
                    disableNavigation(false)
                    history.push(appRoutes.HOME);
                }, () => {

                })
            }, () => {

            })
        }, () => {

        })
    };

    return (
        <div className="application-body">
            <div className="purchase-course__container">
                <div className="logo">
                    <img src={Logo} alt="" />
                </div>
                <div className="header">
                    <h1>
                        Get started with the most effective and efficient way to
                        study
                    </h1>
                    <p>
                        Sign up now for instant access to PharmaEpass, your
                        secret weapon for passing the big test.
                    </p>
                </div>
                <div className="billing-info">
                    <h2>Billing Information</h2>
                    <Formik
                        initialValues={purchaseCourseInitialValue}
                        validationSchema={ValidationSchema}
                        onSubmit={onSubmitHandler}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                        }) => {
                            return (
                                <div>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Field className="input-payment">
                                            <input
                                                name="nameOnCard"
                                                placeholder="Enter Name on Card"
                                                type="text"
                                                value={values.nameOnCard}
                                                onChange={handleChange}
                                            />
                                            <FormErrorMessage
                                                message={errors.nameOnCard}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <CardElement
                                                options={CardOptions}
                                                className="card-field"
                                            />
                                            <span className="text-light-grey mt-1 possible-text">
                                                <sup>*</sup>
                                                {"Visa, Master card & Amex"}
                                            </span>
                                        </Form.Field>
                                        <Form.Field className="input-payment">
                                            <input
                                                name="billingAddress"
                                                placeholder="Billing Address"
                                                type="text"
                                                value={values.billingAddress}
                                                onChange={handleChange}
                                            />
                                            <FormErrorMessage
                                                message={errors.billingAddress}
                                            />
                                        </Form.Field>
                                        <Button
                                            classes="payment-btn"
                                            type="submit"
                                            label="Make Payment"
                                            secondary
                                        />
                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export const PurchaseCourseWrapper = (props: any) => {
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
    );

    return (
        <Elements stripe={stripePromise}>
            <PurchaseCourse {...props} />
        </Elements>
    );
};
export default withRouter(AuthContainer(MetaContainer(PurchaseCourseWrapper)));
