export const AUTHENTICATED: string = 'authenticated_user';
export const UNAUTHENTICATED: string = 'unauthenticated_user';
export const REQUEST_LOGIN: string = "login_request";
export const REQUEST_LOGIN_WITH_REFRESH_TOKEN: string = "request_refresh_token";
export const REQUEST_LOGOUT: string = "logout_request";
export const REQUEST_FORGOT_PASSWORD: string = "forgot_password_request";
export const REQUEST_RESET_PASSWORD: string = "reset_password_request";
export const REQUEST_REGISTER_ACCOUNT: string = "request_register_account";
export const REQUEST_VALIDATE_TOKEN: string = "request_validate_token";
export const REQUEST_VERIFY_EMAIL: string = "request_verify_email";
export const RESEND_EMAIL_VERIFICATION: string = "request_resend_email_verification";
export const MAKE_PAYMENT = "make_payment";
export const PURCHASE_COURSE = "purchase_course";
export const GET_USER = "get_user";
export const UPDATE_USER = "update_user";
export const DISABLE_NAVIGATION = "disable_navigation";
export const VALIDATE_COUPON_CODE: string = "validate_coupon_code"