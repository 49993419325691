import React, { Fragment, useEffect, useState } from "react";
import DefaultProfile from "../../assets/icon/default-profile.svg";
import authContainer from "../../store/containers/authContainer";
import UICustomButton from "./UICustomButton";
import { removeUndefined } from "../../shared/utils/removeUndefined";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import "./profile.scss";
import PerformanceContainer from "../../store/containers/performaceContainer";
import MetaContainer from "../../store/containers/metaContainer";
import { profileValidation } from "./validation";
interface UserDetails {
	getUserDetails: Function;
	getExamDate: Function;
	updateUser: Function;
	course: any;
	signOutAction: () => void;
}

function UserProfile({
	getUserDetails,
	getExamDate,
	course,
	updateUser,
	signOutAction,
}: UserDetails) {
	const [user, setUser] = useState<any>({});
	const [edit, setEdit] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [initialState, setInitialValue] = useState<any>({
		firstName: user?.fullName || "",
		lastName: user?.lastName || "",
		address: user?.address || "",
	});

	const getExamDates = () =>
		new Promise((resolve, reject) => {
			if (course) {
				getExamDate(
					course?.id,
					(data: any) => {
						setUser((existingData: any) => ({
							...existingData,
							daysRemaining: data?.examDate,
						}));
						resolve(data);
					},
					() => {
						reject();
					}
				);
			}
		});

	const userDetails = () =>
		new Promise((resolve, reject) => {
			const user = JSON.parse(localStorage.getItem("user") as string);
			getUserDetails(
				user?.id,
				(userDetails: any) => {
					setUser(removeUndefined(userDetails));
					setInitialValue({
						firstName: userDetails?.firstName,
						lastName: userDetails?.lastName,
						address: userDetails?.address,
					});
					resolve(userDetails);
				},
				() => {
					reject();
				}
			);
		});

	useEffect(() => {
		(async () => {
			await userDetails();
			await getExamDates();
		})();
	}, [course]);

	const handleEdit = () => {
		setEdit((state) => !state);
	};

	const handleSubmit = (values: any) => {
		const user = JSON.parse(localStorage.getItem("user") as string);
		setLoading(true);
		handleEdit();
		updateUser(
			user?.id,
			values,
			(data: any) => {
				setUser((existing: any) => {
					return {
						...existing,
						...data,
					};
				});
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	};

	const handleLogout = () => {
		signOutAction();
	};

	console.log({
		user
	});
	
	return (
		<Formik
			initialValues={initialState}
			onSubmit={handleSubmit}
			validationSchema={profileValidation}
			enableReinitialize
		>
			<Form>
				<div className="application-body profile-container">
					<div className="user-wrapper">
						<div className="img-container">
							<img src={DefaultProfile} alt="" />
						</div>
						<h1>
							{edit ? (
								<div className="edit-input__container">
									<div className="edit-input__wrapper">
										<Field
											className="edit-input"
											name="firstName"
											placeholder="Enter first name"
										/>
										<ErrorMessage name="firstName">
											{(message) => (
												<div className="error">
													{message}
												</div>
											)}
										</ErrorMessage>
									</div>
									<div>
										<Field
											className="edit-input"
											name="lastName"
											placeholder="Enter last name"
										/>
										<ErrorMessage name="lastName">
											{(message) => (
												<div className="error">
													{message}
												</div>
											)}
										</ErrorMessage>
									</div>
								</div>
							) : (
								<Fragment>
									{user?.firstName} {user?.lastName}
								</Fragment>
							)}
						</h1>
						<p>{user?.email}</p>
						<div className={`btn ${edit && "btn-center"}`}>
							{edit ? (
								<UICustomButton>
									<button type="submit">Save</button>
								</UICustomButton>
							) : (
								<UICustomButton clickHandler={handleEdit}>
									{loading ? "Saving" : "Edit"}
								</UICustomButton>
							)}
							{!edit && (
								<UICustomButton clickHandler={handleLogout}>
									Sign Out
								</UICustomButton>
							)}
						</div>
					</div>
					<div className="user-course-info">
						<div className="info">
							<p className="title">Address</p>
							{edit ? (
								<div className="edit-input__wrapper__address">
									<Field
										className="edit-input"
										name="address"
										placeholder="Enter address"
									/>
									<ErrorMessage name="address">
										{(message) => (
											<div className="error">
												{message}
											</div>
										)}
									</ErrorMessage>
								</div>
							) : (
								<p className="content">{user?.address}</p>
							)}
						</div>
						<div className="info">
							<p className="title">Country</p>
							<p className="content">{user?.country?.name}</p>
						</div>
						<div className="info">
							<p className="title">Postal Code</p>
							<p className="content">{user?.postalCode}</p>
						</div>
						<div className="info">
							<p className="title">Exam Date</p>
							<p className="content">
								{user?.daysRemaining
									? moment(user?.daysRemaining).format(
											"ddd, MMM D, YYYY"
									  )
									: "-"}
							</p>
						</div>
						<div className="info">
							<p className="title">Valid until</p>
							<p className="content">
								{user?.activeSubscription
									? moment(
											user?.activeSubscription[0]
												?.expiryDate
									  ).format("ddd, MMM D, YYYY")
									: "-"}
							</p>
						</div>
					</div>
				</div>
			</Form>
		</Formik>
	);
}

export default authContainer(PerformanceContainer(MetaContainer(UserProfile)));
