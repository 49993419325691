import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AuthContainer from "../../../store/containers/authContainer";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

export const requireAuth = (ComposedComponent: any) => {
	class Authentication extends Component<any, any> {
		componentDidMount() {
			const { user, authenticated, history } = this.props;
			if (
				!authenticated &&
				history.location.pathname !== appRoutes.LOGIN
			) {
				history.push(appRoutes.LOGIN);
			} else {
				if (
					!user &&
					history.location.pathname !== appRoutes.VERIFY_EMAIL
				) {
					history.push(appRoutes.VERIFY_EMAIL);
				}
			}
		}

		componentDidUpdate(nextProps: any) {
			const { user, authenticated, history } = nextProps;
			if (
				!nextProps.authenticated &&
				history.location.pathname !== appRoutes.LOGIN
			) {
				history.push(appRoutes.LOGIN);
			} else {
				if (
					!user &&
					history.location.pathname !== appRoutes.VERIFY_EMAIL
				) {
					history.push(appRoutes.VERIFY_EMAIL);
				}
			}
		}

		render() {
			const { authenticated, history } = this.props;
			if (
				!authenticated &&
				history.location.pathname !== appRoutes.LOGIN
			) {
				return null;
			}
			return <ComposedComponent {...this.props} />;
		}
	}

	return withRouter(AuthContainer(Authentication));
};
