import React from "react";
import "./progressbar.scss";

type Type = "success" | "warning" | "danger";

interface IProgressBar {
	percentage: number;
	type: Type;
}

function ProgressBar({ percentage, type }: IProgressBar) {
	return (
		<div className="topic-progress">
			<div
				className={`bar ${
					type === "success"
						? "success-progress"
						: type === "warning"
							? "warning-progress"
							: type === "danger"
								? "danger-progress"
								: ""
					}`}
				style={{ width: `${percentage ? percentage : 0}%` }}
			/>
		</div>
	);
}

export default ProgressBar;
