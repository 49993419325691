import React, { useEffect, useState } from "react";
import Card from "../../../shared/components/Card";
import PerformanceContainer from "../../../store/containers/performaceContainer";
import MetaContainer from "../../../store/containers/metaContainer";
import DatePicker from "react-datepicker";
import Button from "../../../shared/components/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Calender from "../../../assets/images/calender.svg";
import Email from "../../../assets/images/email.svg";
import "./content.scss";
import Reminder from "../Reminder";
import Loader from "../../../shared/components/Loader";
import moment from "moment";
import {
    MODULES,
    DRUGS_CHART,
    MOCK_EXAMS_INTRO,
    PERFORMANCE_METRICES,
    TOPIC_PDF,
} from "../../../routes/routeConstants/appRoutes";
import { Date } from "../../../store/models/Performance/Date.mode";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { QUIZ } from "../../../routes/routeConstants/appRoutes";

interface IContent extends RouteComponentProps {
    getWhereYouLeft: Function;
    course: any;
    getExamDate: any;
    getPrepScore: Function;
}

function Content({
    getWhereYouLeft,
    course,
    getExamDate,
    getPrepScore,
    history,
}: IContent) {
    const [lastCourseChoosed, setLastCourseChoosed] = useState<any>(null);
    const [remaningDate, setRemaningDate] = useState<any>(null);
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState<any>(false);

    const color = score >= 70 ? "#44C37A" : score >= 50 ? "#F5BA26" : "#A9070B";
    const progressBarStyle = buildStyles({
        strokeLinecap: "butt",
        pathTransitionDuration: 0.5,
        pathColor: color,
        trailColor: "#EAEDF2",
        backgroundColor: "transparent",
        textColor: color,
        textSize: "inherit",
    });

    const continueWhereYouLeft = () =>
        new Promise((resolve, reject) => {
            getWhereYouLeft(
                course?.id,
                (data: any) => {
                    setLastCourseChoosed(data);
                    resolve(data);
                },
                (error: any) => {
                    reject();
                }
            );
        });

    const getExamDates = () =>
        new Promise((resolve, reject) => {
            getExamDate(
                course?.id,
                (data: Date) => {
                    setRemaningDate(data.daysRemaining);
                    resolve(data);
                },
                () => {
                    reject();
                }
            );
        });

    const getPerpScores = () =>
        new Promise((resolve, reject) => {
            getPrepScore(
                course?.id,
                (score: number) => {
                    setLoading(false);
                    setScore(score ? score : 0);
                    resolve(score);
                },
                () => {
                    setLoading(false);
                    reject();
                }
            );
        });

    useEffect(() => {
        (async () => {
            try {
                if (course) {
                    setLoading(true);
                    await continueWhereYouLeft();
                    await getExamDates();
                    await getPerpScores();
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        })();
    }, [course]);

    const continueLearning = (id: any) => {
        history.push(TOPIC_PDF(id));
        return;
    };

    return (
        <div className="dashboard-inner-container">
            <Loader loading={loading} />
            <div className="dashboard__container">
                <div className="left">
                    <div className="last-seen">
                        <Card
                            title="CONTINUE WHERE YOU LAST LEFT OFF:"
                            secondary
                            titleWhite
                        >
                            <div className="last-seen__inner__container">
                                <div>
                                    <p className="left-of-course-name">
                                        {lastCourseChoosed?.activeTopic?.topicName ||
                                            "You're yet to start the topics."}
                                    </p>
                                    {
                                        lastCourseChoosed?.activeTopic?.topicName ? (
                                            <p>
                                                Last Viewed:{" "}
                                                {moment(
                                                    lastCourseChoosed?.activeTopic
                                                        ?.updatedAt
                                                ).format("MMM, D YYYY")}
                                            </p>
                                        ) : null
                                    }
                                </div>
                                {
                                    lastCourseChoosed?.activeTopic?.topicName ? (
                                        <Button
                                            type="submit"
                                            classes="last-seen-btn"
                                            clickHandler={() =>
                                                continueLearning(
                                                    lastCourseChoosed?.activeTopic?.topicId
                                                )
                                            }
                                        >
                                            Continue Learning
                                        </Button>
                                    ) : null
                                }
                            </div>
                        </Card>
                    </div>
                    <div className="inner-navigation">
                        <Card title="MY COURSE" secondary titleWhite>
                            <div className="nav-one">
                                <Link to={MODULES} className="nav-item">
                                    <p>Modules</p>
                                    <i className="arrow right icon" />
                                </Link>
                                <Link to={DRUGS_CHART} className="nav-item">
                                    <p>Announcements</p>
                                    <i className="arrow right icon" />
                                </Link>
                            </div>
                            <div className="nav-two">
                                <Link
                                    to={MOCK_EXAMS_INTRO}
                                    className="nav-item"
                                >
                                    <p>Mock Exam</p>
                                    <i className="arrow right icon" />
                                </Link>
                                <Link
                                    to={PERFORMANCE_METRICES}
                                    className="nav-item"
                                >
                                    <p>Analytics</p>
                                    <i className="arrow right icon" />
                                </Link>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="right">
                    <div className="prep-score">
                        <Card title="PERFORMANCE" secondary titleWhite>
                            <div className="performance-score">
                                <p>PrepSCORE</p>
                                <CircularProgressbar
                                    styles={progressBarStyle}
                                    value={score}
                                    text={`${
                                        score % 1 === 0
                                            ? score
                                            : score.toFixed(2)
                                        }%`}
                                    counterClockwise
                                />
                                <Button type="submit" classes="last-seen-btn" clickHandler={() => history.push(PERFORMANCE_METRICES)}>
                                    Go to personalized progress{" "}
                                    <i className="arrow right icon" />
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="reminders">
                <div className="calender">
                    <Card title="KEEP STUDYING" secondary titleWhite>
                        <Reminder
                            img={Calender}
                            head={remaningDate}
                            body="Days left until your exam"
                            datePicker
                        />
                    </Card>
                </div>
                <div className="email">
                    <Card title="GOT A QUESTION? ASK A PHARMACIST" secondary titleWhite>
                        <Reminder
                            img={Email}
                            head="24/7"
                            body="Email Support"
                            calender
                        />
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PerformanceContainer(MetaContainer(Content)));
