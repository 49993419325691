import {all} from 'redux-saga/effects';
import AuthSaga from "./authSaga";
import ToastSaga from "./toastSaga";
import MetaSaga from "./metaSaga";
import ExamSaga from './examSaga';
import ModuleSaga from "./moduleSaga";
import performanceSaga from "./performaceSaga";
import blogSaga from './blogSaga';

export default function* RootSaga() {
    yield  all([
        AuthSaga(),
        ToastSaga(),
        MetaSaga(),
        ExamSaga(),
        ModuleSaga(),
        performanceSaga(),
        blogSaga(),
    ]);
}
