import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import * as routes from "../../routes/routeConstants/appRoutes";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import CreatePasswordForm from "./CreatePasswordForm";
import CreateAccount from "./CreateAccount";
import VerifyEmailForm from "./VerifyEmailForm";
import { requireAuth } from "../../shared/components/HOC/requireAuth";
import AuthContainer from "../../store/containers/authContainer";

function AuthRoutes({ authenticated }: { authenticated: boolean }) {
	const history = useHistory();

	useEffect(() => {
		if (authenticated) {
			history.push(routes.HOME);
		}
	}, []);

	return (
		<div>
			<Switch>
				<Redirect exact from={routes.AUTH} to={routes.LOGIN} />
				<Route exact path={routes.LOGIN} component={LoginForm} />
				<Route
					exact
					path={routes.CREATE_NEW_PASSWORD}
					component={CreatePasswordForm}
				/>
				<Route
					exact
					path={routes.FORGOT_PASSWORD}
					component={ForgotPasswordForm}
				/>
				<Route
					exact
					path={routes.VERIFY_EMAIL}
					component={requireAuth(VerifyEmailForm)}
				/>
				<Route path={routes.CREATE_ACCOUNT} component={CreateAccount} />
			</Switch>
		</div>
	);
}

export default AuthContainer(AuthRoutes);
