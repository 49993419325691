import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import "./notification.scss";
import { VERIFY_EMAIL } from '../../../routes/routeConstants/appRoutes';

type Type = "success" | "error" | "warning";

interface INotification {
    message: string;
    link?: string;
    type: Type;
    onClose: () => void;
}

const Notification: FC<INotification> = ({ message, link, onClose }) => {
    return (
        <div className="notification__container">
            <p>{message}</p>
            <div className="notification__right">
                <Link className="notification__right__link" to={VERIFY_EMAIL}>
                    {link}
                </Link>
                <div onClick={onClose}>
                    <Icon name="close" />
                </div>
            </div>
        </div>
    )
}

export default Notification;