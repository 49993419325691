import React, { FC, Fragment, useEffect, useState } from "react";
import BrandNew from "../BrandNew";
import bgImage from "../../../../assets/images/blog-banner.svg";
import BlogImage from "../../../../assets/images/blogimg.jpeg";
import "./listBlog.scss";
import BrandCard from "../blogCard";
import UIPagenation from "../../../../shared/components/UIPagenation";
import Footer from "../../../../shared/components/Layouts/Footer";
import blogContainer from "../../../../store/containers/blogContainer";
import { Blog } from "../../../../store/models/Blog/Blog.model";
import Logo from "../../../../assets/images/logo-border.png";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { PaginationProps } from "semantic-ui-react";
import { PaginationMeta } from "../../../../store/models/Meta/PaginationMeta/PaginationMeta.model";

interface IListBlog {
  getAllBlogs: Function;
  getBlogById: Function;
  getBlogsByPage: Function;
}

const ListBlog: FC<IListBlog> = ({
  getAllBlogs,
  getBlogById,
  getBlogsByPage,
}) => {
  const [blogs, setBlogs] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [nextItem, setNextItem] = useState(false);
  const [prevItem, setPrevItem] = useState(false);

  const handlePageChange = (e: any, pagination: PaginationProps) => {
    const { activePage } = pagination;
    setPage(Number(activePage));
  };

  useEffect(() => {
    getBlogsByPage(
      {
        pageNumber: page,
      },
      (blogs: Blog[], paginationMeta: PaginationMeta) => {
        const { totalPages, nextPage, prevPage } = paginationMeta;
        setBlogs(blogs);
        setTotalPage(Number(totalPages));
        setNextItem(Boolean(nextPage));
        setPrevItem(Boolean(prevPage));
      },
      () => {}
    );
  }, [page]);

  return (
    <Fragment>
      <header className="wraper-header position-fixed w-100 z-index-9">
        <div className="header-main">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg isnav">
              <a className="navbar-brand" href="https://pharmaepass.com">
                <img className="logo-img" src={Logo} alt="logo" />
                <p className="logo-text">PharmaEpass</p>
              </a>
              <div className="trigger-menu-wrapper">
                <button className="trigger-menu">
                  <span id="nav-icon3">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              </div>
              <div className="collapse navbar-collapse">
                <div className="ml-auto main-navbar-content">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a
                        href="https://pharmaepass.com/ee-course.html"
                        className="nav-link"
                      >
                        EE Course
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://pharmaepass.com/mcq-course.html"
                        className="nav-link"
                      >
                        MCQ Course
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://pharmaepass.com/osce.html"
                        className="nav-link"
                      >
                        OSCE Course
                      </a>
                    </li>
                    <li className="nav-item li-has-children">
                      <a href="javascript:;" className="nav-link">
                        Resources
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          style={{ marginLeft: 6 }}
                        />
                      </a>

                      <ul className="sub-menu">
                        <li>
                          <a href="https://app.pharmaepass.com/blog">Blog</a>
                        </li>
                        <li>
                          <a href="https://pharmaepass.com/eesg.html">
                            EE Study Guide
                          </a>
                        </li>
                        <li>
                          <a href="https://pharmaepass.com/mcqsg.html">
                            MCQ Study Guide
                          </a>
                        </li>
                        <li>
                          <a href="https://pharmaepass.com/oscesg.html">
                            OSCE Study Guide
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item li-has-children">
                      <a href="javascript:;" className="nav-link">
                        Connect
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          style={{ marginLeft: 6 }}
                        />
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://pharmaepass.com/about-us.html">
                            About us
                          </a>
                        </li>
                        <li>
                          <a href="https://pharmaepass.com/about-us.html#our-team">
                            Our Team
                          </a>
                        </li>
                        <li>
                          <a href="https://pharmaepass.com/faq.html">FAQ</a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://app.pharmaepass.com/auth/create-account/basic-details"
                        className="btn login-btn mt-3 mt-lg-0"
                      >
                        LOGIN
                      </a>
                    </li>
                    <li className="nav-item ml-lg-3 sign-up">
                      <a
                        href="https://pharmaepass.com/pricing.html"
                        className="btn signup-btn"
                      >
                        SIGN UP
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="list-blog__container">
        <div className="header">
          <h2>
            <span className="red-text">PharmaEpass</span> Blog
          </h2>
          <p>
            At Pharmaepass, we believe in educating first. Our blog was created
            to reflect this value. Our articles are written by Licensed
            Pharmacists. We also partner with guest contributors regularly, both
            professionals and industry experts, to make sure that you are
            getting information that is not only relevant, but accurate.
          </p>
          <br />
          <p>
            Learn from the best with this free resource, updated with new
            articles regularly. If you need to know about tips and tricks,
            changes being made to the exams, and general information, our blog
            is the perfect place for you.
          </p>
        </div>
        <div className="brand-new">
          <BrandNew backgroundImage={bgImage} title="" description="" />
        </div>
        <div className="list-blog">
          {blogs.map((blog: any) => (
            <BrandCard
              id={blog?.id}
              img={blog?.image?.url || ""}
              title={blog?.title || ""}
              descritpion={blog?.content || ""}
              date={blog?.createdAt}
              detail
            />
          ))}
        </div>
        <UIPagenation
          onPageChange={handlePageChange}
          defaultActivePage={page}
          totalPages={totalPage}
          nextItem={nextItem}
          prevItem={prevItem}
        />

        <div className="popular-food">
          <div className="title">
            <h1>Popular Posts</h1>
          </div>
          <div className="card-wrapper">
            {[...blogs]?.splice(0, 3)?.map((blog: Blog) => (
              <BrandCard
                id={blog?.id}
                img={blog?.image?.url || ""}
                title={blog?.title || ""}
                descritpion={blog?.content || ""}
                date={blog?.createdAt}
                detail
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default blogContainer(ListBlog);
