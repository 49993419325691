import { takeEvery, call, put, delay } from 'redux-saga/effects'
import { ApiService } from "../services/ApiService";
import { COUNTRY_META_API_URL, COURSE_META_API_URL, GET_COURSE_FOR_USER, LIST_SUBSCRIPTION } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { LIST_COUNTRY_META, LIST_COUNTRY_META_SUCCESS, LIST_COURSE_META_SUCCESS, LIST_COURSE_META, SET_USER_COURSE_SUCCESS, SET_USER_COURSE, UPDATE_SELECTED_COURSE, SUBSCRIPTION } from "../definitions/metaConstants";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import { CountryMeta } from "../models/Meta/CountryMeta/countryMeta.model";
import { Courses } from '../models/Meta/CourseMeta/CourseMeta.model';
import { SEARCH_API } from '../../routes/routeConstants/apiRoutes';
import { SEARCH } from '../definitions/metaConstants';

const apiService = new ApiService();

/* Executor Sagas */

function* getCountriesFlow(action: any) {
    const { successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, COUNTRY_META_API_URL);
        if (response.data) {
            const countryMetas = deserialize(CountryMeta, response.data['countries']);
            yield put({ type: LIST_COUNTRY_META_SUCCESS, payload: countryMetas });
            successCallback();
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* getCoursesFlow(action: any) {
    const { successCallback, errCallback 
    } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, COURSE_META_API_URL);
        if (response.data) {
            const { courses } = deserialize(Courses, response.data);
            yield put({ type: LIST_COURSE_META_SUCCESS, payload: courses });
            successCallback(courses);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}


function* getUserCourse(action: any) {
    const { successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, GET_COURSE_FOR_USER);
        if (response.data) {
            const { courses } = response.data;
            yield put({ type: SET_USER_COURSE_SUCCESS, payload: courses });
            successCallback(courses);
        } else {
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        if (error?.response?.status === 422) {
            errCallback(errorResponse);
            return;
        }
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* updateSelectedCourse(action: any) {
    const { courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, `${GET_COURSE_FOR_USER}/${courseId}`);
        if (response.data) {
            successCallback();
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* search(action: any) {
    const { search, courseId, successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, `${SEARCH_API(courseId)}?search=${search}`);
        if (response.data) {
            successCallback(response?.data);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

function* listSubscriptions(action: any) {
    const { successCallback, errCallback } = action.payload;
    let response: any;
    try {
        response = yield call(apiService.get, LIST_SUBSCRIPTION);
        if (response.data) {
            successCallback(response?.data);
        } else {
            errCallback();
            yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: response['message'] } });
        }
    } catch (error) {
        const errorResponse = error.response.data;
        errCallback();
        yield put({ type: INVOKE_TOASTER, payload: { type: 'error', message: errorResponse['error'] } });
    }
}

/*Watcher sagas*/

export default function* MetaSaga() {
    yield takeEvery(LIST_COUNTRY_META, getCountriesFlow);
    yield takeEvery(LIST_COURSE_META, getCoursesFlow);
    yield takeEvery(SET_USER_COURSE, getUserCourse);
    yield takeEvery(UPDATE_SELECTED_COURSE, updateSelectedCourse);
    yield takeEvery(SEARCH, search);
    yield takeEvery(SUBSCRIPTION, listSubscriptions);
}
function* S(get: (url: string, queryParams?: any) => Promise<import("axios").AxiosResponse<any>>, S: any) {
    throw new Error('Function not implemented.');
}

