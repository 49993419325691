import { CreateReducer } from "../../shared/utils/createReducer";
import {
    SET_QUESTIONS,
    UPDATE_QUESTIONS,
} from "../definitions/questionContants";

interface Question {
    questions: any;
}

const initState: Question = {
    questions: {},
};

const questionReducer = CreateReducer(initState, {
    [SET_QUESTIONS](state: Question, action: any): Question {
        const data: any = {};
        action?.payload?.forEach((question: any, index: number) => {
            data[`a${index}`] = "";
        });
        return {
            ...state,
            questions: data,
        };
    },
    [UPDATE_QUESTIONS](state: Question, action: any): Question {
        const { questionNo, question } = action.payload;
        const payload = {
            ...state,
            questions: {
                ...state.questions,
                [`a${questionNo}`]: question,
            },
        };
        return payload;
    },
});

export default questionReducer;
