import React from "react";
import "./button.scss";

interface buttonProps {
    children?: any;
    label?: string;
    clickHandler?: () => void;
}

export default function UICustomButton({
    children,
    label,
    clickHandler = () => {},
}: buttonProps) {
    return (
        <div
            className="ui-custom-btn__container"
            onClick={() => clickHandler()}
        >
            <p>{children || label}</p>
        </div>
    );
}
