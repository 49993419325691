import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as routes from '../../routes/routeConstants/appRoutes';
import BasicDetailsForm from "./BasicDetailsForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


function CreateAccountRoutes() {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
    return (
        <div>
            <Elements stripe={stripePromise}>
                <Switch>
                    <Redirect exact from={routes.CREATE_ACCOUNT} to={routes.CREATE_BASIC_DETAILS} />
                    <Route exact path={routes.CREATE_BASIC_DETAILS} component={BasicDetailsForm} />
                </Switch>
            </Elements>
        </div>
    );
}

CreateAccountRoutes.propTypes = {};

export default CreateAccountRoutes;

