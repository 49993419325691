import React, { Fragment, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import { Formik } from "formik";
import * as yup from "yup";
import "./verifyEmailForm.scss";
import AuthContainer from "../../../store/containers/authContainer";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useLockBodyScroll } from "../../../shared/components/LockBodyScroll";
import { User } from "../../../store/models/User/user.model";
import { resendVerifyEmailAction } from "../../../store/actions/authActions";
import Card from "../../../shared/components/Card";
import Reminder from "../../Dashboard/Reminder";
import Email from "../../../assets/images/email.svg";

const otpValidationSchema = yup.object({
    confirmationToken: yup.string().required("OTP is required"),
});

function VerifyEmailForm(props: any) {
    const initialValues: User = { confirmationToken: "" };
    const { authenticated, user, history } = props;
    useLockBodyScroll();
    useEffect(() => {
        if (authenticated && user && user.confirmedAt) {
            history.push(appRoutes.HOME);
        }
    }, []);
    const handleResendEmailVerification = () => {
        props.resendVerifyEmailAction(
            () => {},
            () => {}
        );
    };
    return (
        <div>
            <div>
                <h3 className="mt-2 mb-3 text-center text-light-grey">
                    {" "}
                    Verify Email OTP{" "}
                </h3>
                <Formik
                    initialValues={initialValues}
                    validationSchema={otpValidationSchema}
                    onSubmit={(values, actions) => {
                        const user = Object.assign(new User(), values);
                        props.emailVerifyAction(
                            user,
                            () => {
                                history.push(appRoutes.HOME);
                            },
                            () => {}
                        );
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        isValid,
                        dirty,
                    }) => {
                        return (
                            <div className="verify-email-form">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Field>
                                        <label>Error OTP</label>
                                        <input
                                            name="confirmationToken"
                                            placeholder="Enter OTP"
                                            type="text"
                                            value={values.confirmationToken}
                                            onChange={handleChange}
                                        />
                                        <FormErrorMessage
                                            message={errors.confirmationToken}
                                        />
                                    </Form.Field>
                                    <Button
                                        primary
                                        className="w-100"
                                        type="submit"
                                        disabled={!isValid || !dirty}
                                    >
                                        {" "}
                                        Reset Password{" "}
                                    </Button>
                                </Form>
                                <div className="text-center mt-2 mb-2">
                                    <Button
                                        primary
                                        className="link-button"
                                        type="button"
                                        onClick={handleResendEmailVerification}
                                    >
                                        Resend OTP
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <div className="mail-conformation">
                <Card title="REACH OUR SUPPORT THROUGH EMAIL FOR" secondary titleWhite>
                    <Reminder
                        img={Email}
                        head="24/7"
                        body={
                            <span>
                                <p>Email Support</p>
                                <p>
                                    1. Receiving emails for the paid
                                    subscription
                                </p>
                                <p>
                                    2. Update your wrong email address given
                                    during sign up
                                </p>
                            </span>
                        }
                        calender
                    />
                </Card>
            </div>
        </div>
    );
}

export default AuthContainer(VerifyEmailForm);
