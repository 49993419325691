import React, { useState, useEffect, useCallback } from "react";
import Notification from "./Notification";
import "./dashboard.scss";
import MetaContainer from "../../store/containers/metaContainer";
import AuthContainer from "../../store/containers/authContainer";
import Content from "./Content";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../routes/routeConstants/appRoutes";

const Dashboard = ({ authenticated }: { authenticated: boolean }) => {
	const [showVerify, setShowVerify] = useState<boolean>(false);
	const [user, setUser] = useState<
		| {
				firstName: string;
				lastName: string;
		  }
		| undefined
	>();
	const history = useHistory();

	useEffect(() => {
		if (!authenticated) {
			history.push(LOGIN);
		}
		const user = JSON.parse(localStorage.getItem("user") as string);
		setUser(user);
		if (user) {
			setShowVerify(!user.confirmedAt && true);
		}
	}, []);

	const onClose = useCallback(() => {
		setShowVerify(false);
	}, []);

	return (
		<div className="dashboard application-body">
			{showVerify && (
				<Notification
					message="Your Email is Not Verified"
					link="Verify Email"
					type="error"
					onClose={onClose}
				/>
			)}

			<div className="dashboard-inner__container">
				<div className="dashboard-header">
					<p>DASHBOARD</p>
					<h1>Welcome {`${user?.firstName} ${user?.lastName}`}</h1>
				</div>
				<div className="dashboard-body">
					<Content />
				</div>
			</div>
		</div>
	);
};

Dashboard.propTypes = {};

export default AuthContainer(MetaContainer(Dashboard));
