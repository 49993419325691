import React from "react";
import { Button, Icon } from "semantic-ui-react";
import "./button.scss";

type ButtonType = "submit" | "reset" | "button" 

interface IButton {
	type: ButtonType,
	label?: string;
	children?: any;
	icon?: string;
	secondary?: boolean;
	iconLeft?: boolean;
	iconPrimary?: boolean;
	classes?: string;
	disabled?: boolean;
	clickHandler?: any;
}


function UIButton({
	type,
	children,
	label,
	secondary,
	icon,
	iconLeft = false,
	iconPrimary = false,
	classes,
	disabled = false,
	clickHandler
}: IButton) {
	return (
		<Button
			type={type}
			icon
			labelPosition={iconLeft ? "left" : icon ? "right" : undefined}
			className={`pe-btn ${
				secondary ? "pe-secondary-btn" : "pe-primary-btn"
			} ${classes && classes} ${
				iconLeft ? "pe-icon-left" : icon ? "pre-icon-right" : ""
			}`}
			onClick={clickHandler}
			disabled={disabled}
		>
			{icon && (
				<Icon
					className={`${icon} pe-btn-icon ${
						iconPrimary && "pe-btn-primary-icon"
					}`}
				/>
			)}
			{children || label}
		</Button>
	);
}

export default UIButton;
