import {
    REQUEST_FORGOT_PASSWORD,
    REQUEST_LOGIN,
    REQUEST_LOGIN_WITH_REFRESH_TOKEN,
    REQUEST_LOGOUT,
    REQUEST_REGISTER_ACCOUNT,
    REQUEST_RESET_PASSWORD,
    REQUEST_VALIDATE_TOKEN,
    REQUEST_VERIFY_EMAIL,
    RESEND_EMAIL_VERIFICATION,
    MAKE_PAYMENT,
    GET_USER,
    UPDATE_USER,
    DISABLE_NAVIGATION,
    VALIDATE_COUPON_CODE,
} from "../definitions/authConstants";
import { Login } from "../models/Login/login.model";
import { User } from "../models/User/user.model";
import { PURCHASE_COURSE } from "../definitions/authConstants";

export function signInAction(
    login: Login,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_LOGIN,
        payload: {
            login,
            successCallback,
            errCallback,
        },
    };
}

export function signInWithRefreshTokenAction(
    login: Login,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_LOGIN_WITH_REFRESH_TOKEN,
        payload: {},
    };
}

export function forgotPasswordAction(
    login: Login,
    history: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_FORGOT_PASSWORD,
        payload: {
            login,
            history,
            successCallback,
            errCallback,
        },
    };
}

export function validateTokenAction(
    login: Login,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_VALIDATE_TOKEN,
        payload: {
            login,
            successCallback,
            errCallback,
        },
    };
}

export function resetPasswordAction(
    login: Login,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_RESET_PASSWORD,
        payload: {
            login,
            successCallback,
            errCallback,
        },
    };
}

export function registerUserAction(
    user: User,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_REGISTER_ACCOUNT,
        payload: {
            user,
            successCallback,
            errCallback,
        },
    };
}

export function emailVerifyAction(
    user: User,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: REQUEST_VERIFY_EMAIL,
        payload: {
            user,
            successCallback,
            errCallback,
        },
    };
}

export function resendVerifyEmailAction(
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: RESEND_EMAIL_VERIFICATION,
        payload: {
            successCallback,
            errCallback,
        },
    };
}

export function signOutAction() {
    return {
        type: REQUEST_LOGOUT,
        payload: {
            message: "Request for logout",
        },
    };
}

export function makePayment(
    paymentId: string,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: MAKE_PAYMENT,
        payload: {
            paymentId,
            successCallback,
            errCallback,
        },
    };
}

export function purchaseCourse(
    data: {
        courseId: string,
        paymentId: string,
    },
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: PURCHASE_COURSE,
        payload: {
            ...data,
            successCallback,
            errCallback,
        },
    };
}




export function getUserDetails(
    userId: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: GET_USER,
        payload: {
            userId,
            successCallback,
            errCallback,
        },
    };
}

export function updateUser(
    userId: any,
    data: any,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: UPDATE_USER,
        payload: {
            userId,
            successCallback,
            data,
            errCallback,
        },
    };
}

export function disableNavigation(
    disable: boolean
) {
    return {
        type: DISABLE_NAVIGATION,
        payload: disable
    }
}

export function validateCouponCodeAction(
    couponCode: string,
    successCallback: Function,
    errCallback: Function
) {
    return {
        type: VALIDATE_COUPON_CODE,
        payload: {
            couponCode,
            successCallback,
            errCallback,
        },
    };
}