import {serializable, alias, primitive, object, list} from 'serializr';
import { Topic } from './topic.model';
import { StudentModuleDetails } from './studentModuleDetails.model';

export class CourseModule {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('completion', primitive()))
    completion?: Float32Array;

    @serializable(alias('average', primitive()))
    average?: Float32Array;

    @serializable(alias('topics', list(object(Topic))))
    topics?: Topic[];

    @serializable(alias('student_module_details', list(object(StudentModuleDetails))))
    studentModuleDetails?: StudentModuleDetails[];   

}

export class CourseModules {
    @serializable(alias('course_modules', list(object(CourseModule))))
    courseModules?: CourseModule;
}