import React, { useMemo, useState } from "react";
import "./resultProgress.scss";
import Percentage from "./Percentage";
import UIButton from "../../../../shared/components/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import examContainer from "../../../../store/containers/examContainer";
import metaContainer from "../../../../store/containers/metaContainer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ExamBody } from "../../../../store/models/Exam/Attempt.model";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { ExamAttempt } from "../../../../store/models/Module/Attempt.model";
import ModuleContainer from "../../../../store/containers/moduleContainer";
import "react-circular-progressbar/dist/styles.css";

const progressBarStyle = buildStyles({
	rotation: 0.25,
	strokeLinecap: "butt",
	pathTransitionDuration: 0.5,
	pathColor: `#44C47A`,
	trailColor: "#F0394F",
	backgroundColor: "transparent",
});

interface IResultProgress extends RouteComponentProps {
	result: any;
	examSession: any;
	course: any;
	createAttempt: Function;
	setQuestions: Function;
	getAllQuestions: Function;
	createQuizAttempt: Function;
}

function calcPercentage(value: any, total: any) {
	return Math.floor((value / total) * 100);
}

function ResultProgress({
	result,
	course,
	history,
	createAttempt,
	setQuestions,
	getAllQuestions,
	createQuizAttempt,
}: IResultProgress) {
	const [loading, setLoading] = useState(false);
	const correctPercentage = useMemo(
		() => calcPercentage(result?.correct, result?.total),
		[]
	);
	const wrongPercentage = useMemo(
		() =>
			calcPercentage(result?.wrong + result?.unattempted, result?.total),
		[]
	);
	const percentage = correctPercentage;

	const reTakeMockExam = () => {
		if (course) {
			setLoading(true);
			createAttempt(
				course.id,
				(examSession: ExamBody) => {
					setQuestions(
						examSession.id,
						() => {
							history.push(AppRoutes.MOCK_EXAMS_EXAM);
							setLoading(false);
						},
						() => {
							setLoading(false);
						}
					);
				},
				() => {
					setLoading(false);
				}
			);
		}
	};

	const handleExamMode = (quiz_mode: string) => {
		const payload = {
			quiz_attempt: {
				quiz_mode,
			},
		};
		if (course) {
			createQuizAttempt(
				course.id,
				result?.quizId,
				payload,
				(createdAttempt: ExamAttempt) => {
					getAllQuestions(
						course.id,
						createdAttempt.id,
						() => {
							history.push(
								`${AppRoutes.MOCK_EXAMS_EXAM}?type=${quiz_mode}`
							);
						},
						() => {}
					);
				},
				() => {}
			);
		}
	};

	const handleRetakeTest = () => {
		if (result?.quizMode) {
			handleExamMode(result?.quizMode);
			return;
		}
		reTakeMockExam();
	};

	return (
		<div className="result-progress__container">
			<div className="result-progress__left">
				<CircularProgressbar
					value={percentage}
					className="result-progress__progress__bar"
					styles={progressBarStyle}
				/>
				<Percentage value={correctPercentage} correct />
				<Percentage value={wrongPercentage} />
			</div>
			<div className="result-progress__right">
				<UIButton
					type="button"
					clickHandler={handleRetakeTest}
					secondary
				>
					Reset-Retake
				</UIButton>
			</div>
		</div>
	);
}

export default withRouter(
	examContainer(ModuleContainer(metaContainer(ResultProgress)))
);
