import { takeEvery, call, put, delay } from "redux-saga/effects";
import { ApiService } from "../services/ApiService";
import { deserialize } from "serializr";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import {
  LIST_BLOGS,
  GET_BLOG_CONTENT,
  LIST_BLOGS_BY_PAGE,
} from "../definitions/blogConstants";
import {
  LIST_BLOG,
  GET_BLOG,
  LIST_BLOGS_BY_PAGE_NO,
} from "../../routes/routeConstants/apiRoutes";
import { Blogs } from "../models/Blog/Blog.model";
import { PaginationMeta } from "../models/Meta/PaginationMeta/PaginationMeta.model";
const apiService = new ApiService();

/* Executor Sagas */

function* listBlog(action: any) {
  const { successCallback, errCallback } = action.payload;
  let response: any;
  try {
    response = yield call(apiService.get, LIST_BLOG);
    if (response.data) {
      const blogs = deserialize(Blogs, response.data).blogs;
      successCallback(blogs);
    } else {
      errCallback();
      yield put({
        type: INVOKE_TOASTER,
        payload: { type: "error", message: response["message"] },
      });
    }
  } catch (error) {
    const errorResponse = error.response.data;
    errCallback();
    yield put({
      type: INVOKE_TOASTER,
      payload: { type: "error", message: errorResponse["error"] },
    });
  }
}

function* getBlog(action: any) {
  const { blogId, successCallback, errCallback } = action.payload;
  let response: any;
  try {
    response = yield call(apiService.get, GET_BLOG(blogId));
    if (response.data) {
      successCallback();
    } else {
      errCallback();
      yield put({
        type: INVOKE_TOASTER,
        payload: { type: "error", message: response["message"] },
      });
    }
  } catch (error) {
    const errorResponse = error.response.data;
    errCallback();
    yield put({
      type: INVOKE_TOASTER,
      payload: { type: "error", message: errorResponse["error"] },
    });
  }
}

function* getBlogByPage(action: any) {
  const { pageData, successCallback, errCallback } = action.payload;
  const { pageNumber } = pageData;
  let response: any;
  try {
    response = yield call(apiService.get, LIST_BLOGS_BY_PAGE_NO(pageNumber));
    if (response.data) {
      const blogs = deserialize(Blogs, response.data).blogs;
      const paginationMeta = deserialize(PaginationMeta, response.data.meta);
      successCallback(blogs, paginationMeta);
    } else {
      errCallback();
      yield put({
        type: INVOKE_TOASTER,
        payload: { type: "error", message: response["message"] },
      });
    }
  } catch (error) {
    const errorResponse = error.response.data;
    errCallback();
    yield put({
      type: INVOKE_TOASTER,
      payload: { type: "error", message: errorResponse["error"] },
    });
  }
}

/*Watcher sagas*/

export default function* blogSaga() {
  yield takeEvery(LIST_BLOGS, listBlog);
  yield takeEvery(GET_BLOG_CONTENT, getBlog);
  yield takeEvery(LIST_BLOGS_BY_PAGE, getBlogByPage);
}
