import { alias, primitive, serializable } from "serializr";

export class Option {
    @serializable(alias('a', primitive()))
    a?: string;

    @serializable(alias('b', primitive()))
    b?: string;

    @serializable(alias('c', primitive()))
    c?: string;

    @serializable(alias('d', primitive()))
    d?: string;

    @serializable(alias('e', primitive()))
    e?: string;
}