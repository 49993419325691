import { CreateReducer } from "../../shared/utils/createReducer";
import {
    SET_QUESTIONS,
    PREVIOUS_QUESTION,
    SET_VISITED,
    NEXT_QUESTION,
    SET_FLAG,
    SET_QUESTION_NO,
    SET_EXAM_ATTEMPT_SUCCESS,
    SET_QUESTIONS_SUCCESS,
    SET_RESULTS_SUCCESS,
    UPDATE_STRIKE_THROUNG,
} from "../definitions/examConstants";
import { store } from "../../store";
import * as QuestionDefinitions from "../definitions/questionContants";
import { shuffle } from "lodash";

const initiState = {
    questions: [],
    strikeThroungh: {},
    questionNo: 0,
    visitedQuestions: {},
    bunchQuestions: [9, 10, 11],
    examSession: null,
    results: null,
};

const generateOptions = (options: any[]) => {
    const parsedOptions = JSON.parse(JSON.stringify(options));
    const keys = Object.keys(parsedOptions);
    const [lastElement] = keys.splice(-1);
    return [...shuffle(keys), lastElement];
}

const examReducer = CreateReducer(initiState, {
    [SET_EXAM_ATTEMPT_SUCCESS](state: any, action: any) {
        return {
            ...state,
            examSession: action.payload,
        };
    },
    [SET_QUESTIONS_SUCCESS](state: any, action: any) {
        const visitedQuestions: any = {};
        const strikeThroungh: any = {};
        action.payload.forEach((question: any, index: number) => {
            visitedQuestions[question.id] = {
                visited: false,
                flagged: false,
            };

            strikeThroungh[`a${index}`] = (() => {
                const options: any = {};
                Object.keys(question?.options).forEach((key: string) => {
                    options[key] = false;
                });
                return options;
            })();
        });
        return {
            ...state,
            questions: action.payload?.map((question: any) => ({
                ...question,
                optionKeys: generateOptions(question?.options)
            })),
            // questions: action.payload,
            visitedQuestions,
            strikeThroungh,
        };
    },
    [SET_QUESTION_NO](state: any, action: any) {
        return { ...state, questionNo: action.payload };
    },
    [NEXT_QUESTION](state: any) {
        if (state.questionNo < state.questions.length) {
            return { ...state, questionNo: state.questionNo + 1 };
        }
        return state;
    },
    [PREVIOUS_QUESTION](state: any) {
        if (state.questionNo > 0) {
            return { ...state, questionNo: state.questionNo - 1 };
        }
        return state;
    },
    [SET_VISITED](state: any, action: any) {
        const { id, data } = action.payload;
        return {
            ...state,
            visitedQuestions: {
                ...state.visitedQuestions,
                [id]: {
                    ...state.visitedQuestions[id],
                    ...data,
                },
            },
        };
    },
    [SET_FLAG](state: any, action: any) {
        const { id } = action.payload;
        return {
            ...state,
            visitedQuestions: {
                ...state.visitedQuestions,
                [id]: {
                    ...state.visitedQuestions[id],
                    flagged: !state.visitedQuestions[id].flagged,
                },
            },
        };
    },
    [SET_RESULTS_SUCCESS](state: any, action: any) {
        return {
            ...state,
            results: action.payload,
        };
    },
    [UPDATE_STRIKE_THROUNG](state: any, action: any) {
        const { option, questionNo } = action.payload;
        const payload = {
            ...state,
            strikeThroungh: {
                ...state?.strikeThroungh,
                [`a${questionNo}`]: {
                    ...state?.strikeThroungh[`a${questionNo}`],
                    [option]: state?.strikeThroungh[`a${questionNo}`][option]
                        ? false
                        : true,
                },
            },
        };
        return payload;
    },
});

export default examReducer;
