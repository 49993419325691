import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { updateQuestion } from "../actions/questionAction";

export default function ToastContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({ updateQuestion }, dispatch);
}

function mapStateToProps(state: any, ownProps: any) {
    return {
        questions: state?.question?.questions,
        ...ownProps
    };
}
