import React from "react";
import "./results.scss";
import { CorrectIcon, WrongIcon } from "../Icon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MOCK_EXAMS_RESULT } from "../../../../../routes/routeConstants/appRoutes";
import Content from "./content";

interface IResults extends RouteComponentProps {
	results: any;
}

function Results({ results, history }: IResults) {
	const handleClick = (id: number) => {
		history.push(`${MOCK_EXAMS_RESULT}/${id}`);
    };


	return (
		<div className="results__container">
			{results.map((result: any, index: number) => {
				return (
					<div
						className={`result__container ${index === results?.length - 1 ? "no-border" : ""}`}
						onClick={() => handleClick(result.questionId)}
					>
						{result.isCorrect ? (
							<CorrectIcon classes="result-icon" />
						) : (
							<WrongIcon classes="result-icon" />
						)}
                        <Content sno={`Q${result.queNo}`} text={result?.questionContent}/>
						<div className="result__view__more">
							<i className="right arrow icon checkout-icon"></i>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default withRouter(Results);
