import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Menu, Sidebar, Modal } from "semantic-ui-react";
import AuthContainer from "../../../store/containers/authContainer";
import companyLogo from "../../../assets/images/pharma-epass-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { sidebarPaths } from "../AppSidebar/data";
import { Link, withRouter } from "react-router-dom";
import MetaContainer from "../../../store/containers/metaContainer";
import { Course } from "../../../store/models/Meta/CourseMeta/CourseMeta.model";
import BuyCourseCard from "../BuyCourseCard";
import { EEInfo, MCQInfo } from "../../data/courseInfo";
import SearchIcon from "../../../assets/images/search.svg";
import {
	QUIZ,
	TOPICS,
	PROFILE,
} from "../../../routes/routeConstants/appRoutes";
import { checkWebSite } from "../../utils/isBlog";
import Logo from "../../../assets/images/logo.svg";
import userLogo from "../../../assets/images/user-logo.svg";
import file from "../../../assets/images/file.svg";
import Button from "./Button";
import { HOME } from "../../../routes/routeConstants/appRoutes";
import ProfileIcon from "../../../assets/icon/profile.svg";
import InfoIcon from "../../../assets/icon/info.svg";
import "./appHeader.scss";
import { LOGIN } from "../../../routes/routeConstants/dist/appRoutes";
import LocalStorage from "../../utils/localstorageHelper";
//@ts-ignore
import AutoComplete from "react-autocomplete";

function AppHeader(props: any) {
	const {
		authenticated,
		signOutAction,
		location: { pathname },
		history,
	} = props;
	const [visible, setVisible] = useState(false);
	const [active, setActive] = useState<any>(undefined);
	const [activeItem, setActiveItem] = useState(undefined);
	const [showPurchase, setShowPurchase] = useState(false);
	const [choosedCourse, setChoosedCourse] = useState("");
	const [search, setSearch] = useState<any>("");
	const [options, setOptions] = useState<any>([]);
	const [staticVisible, setStaticVisible] = useState(false);
	const [showSearch, setShowSearch] = useState<boolean>(true);
	const [showAllPurchase, setShowAllPurchase] = useState(false);

	useEffect(() => {
		if (props.authenticated) {
			props.getUserCourse(
				(data: Array<Course>) => {
					const selectedCourse = localStorage.getItem(props.user?.id);
					if (selectedCourse) {
						setActive(selectedCourse || "");
						data?.forEach((course) => {
							if (
								course?.name?.toUpperCase() === selectedCourse
							) {
								props?.setSelectedCourse(course);
							}
						});
					} else {
						if(data?.length) {
							props.setSelectedCourse(data[0]);
							setActive(data[0]?.name?.toUpperCase());
						}
					}
				},
				(error: Error) => {
					if (props.authenticated) {
						setShowAllPurchase(true);
						setShowPurchase(true);
						props?.disableNavigation(true);
					}
				}
			);
		}
	}, [props.authenticated]);

	const handleClick = (e: any, { children }: any) => {
		const selectedCourse = props.userCourse.find(
			(course: Course) => course.name?.toUpperCase() === children
		);
		setActive(children);
		localStorage.setItem(props?.user?.id, children);
		props.setSelectedCourse(selectedCourse);
		props.updateSelectedCourse(
			selectedCourse.id,
			() => {
				history.push(HOME);
			},
			() => {}
		);
	};

	const handlePurchaseModal = useCallback(() => {
		setShowAllPurchase(false);
		setShowPurchase((state) => !state);
	}, []);

	const handlePurchase = (courseName: string) => {
		// setChoosedCourse(courseName);
		// setShowPurchase(true);
		window.location.replace(`https://pharmaepass.com/pricing.html?course=${courseName}`);
	};

	const onChange = (event: any) => {
		setSearch(event.target.value);
		props?.search(
			event.target.value,
			props?.course?.id,
			(data: any) => {
				const { module_results, topic_results } = data;
				const module = module_results?.map((module: any) => ({
					...module,
					type: "Module",
				}));
				const topic = topic_results?.map((topic: any) => ({
					...topic,
					type: "Topic",
				}));
				setOptions([...module, ...topic]);
			},
			() => {}
		);
	};

	const handleItemClick = (_: any, { name }: any) => {
		setActiveItem(name);
		localStorage.setItem(props?.user?.id, name);
	};

	const links = [
		"home",
		"EE Course",
		"MCQ Course",
		"OSCE Course",
		"About Us",
		"Blog",
	];

	const linkValues = [
		"/home",
		"/course/1",
		"/course/2",
		"/course/3",
		"/about-us",
		"/blog",
	];

	if (checkWebSite()) {
		return null;
	}

	// if (checkWebSite()) {
	// 	return (
	// 		<Fragment>
	// 			<Menu size="large" className="static-app-header">
	// 				<Menu.Item>
	// 					<img src={Logo} alt="" className="header-logo" />
	// 				</Menu.Item>
	// 				<Menu.Item className="title" name="PharmaEpass" />
	// 				{links?.map((link, index) => (
	// 					<Link
	// 						to={linkValues[index]}
	// 						style={{ margin: "auto 0" }}
	// 						className="large-link"
	// 					>
	// 						<Menu.Item
	// 							name={link}
	// 							active={activeItem === link}
	// 							onClick={handleItemClick}
	// 						/>
	// 					</Link>
	// 				))}
	// 				<Menu.Menu position="right">
	// 					<Menu.Item className="static-right-btn">
	// 						<Link to={PURCHASE_COURSES}>
	// 							<Button icon={userLogo} text="Login" />
	// 						</Link>
	// 					</Menu.Item>
	// 					<Menu.Item className="static-right-btn">
	// 						<Link to={PURCHASE_COURSES}>
	// 							<Button icon={file} text="Enroll" secondary />
	// 						</Link>
	// 					</Menu.Item>
	// 					<Menu.Item className="static-right-sl">
	// 						<i
	// 							className="bars icon"
	// 							onClick={() => setStaticVisible(true)}
	// 						/>
	// 					</Menu.Item>
	// 				</Menu.Menu>
	// 			</Menu>
	// 			<Sidebar
	// 				as={Menu}
	// 				animation="overlay"
	// 				direction="right"
	// 				inverted
	// 				vertical
	// 				visible={staticVisible}
	// 				className="sidebar-sm sidebar-auth"
	// 			>
	// 				<Menu.Item position="right" header>
	// 					<p
	// 						className="sidebar-close-icon"
	// 						onClick={() => setStaticVisible(false)}
	// 					>
	// 						X
	// 					</p>
	// 					<div style={{ marginTop: "20%" }}>
	// 						{links?.map((link, index) => (
	// 							<Link
	// 								to={linkValues[index]}
	// 								style={{ margin: "auto 0" }}
	// 								className="large-link"
	// 							>
	// 								<Menu.Item
	// 									className="app-sidebar__menu-item"
	// 									name={link}
	// 									active={activeItem === link}
	// 									onClick={handleItemClick}
	// 								/>
	// 							</Link>
	// 						))}
	// 					</div>
	// 				</Menu.Item>
	// 			</Sidebar>
	// 		</Fragment>
	// 	);
	// }

	const searchStyle = {
		borderRadius: '3px',
		boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
		background: 'rgba(255, 255, 255, 0.9)',
		padding: '2px 0',
		fontSize: '90%',
		position: 'fixed',
		overflowX: 'hidden',
		maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
	  }
	  

	return authenticated ? (
		<Fragment>
			<Menu className="app-header">
				<Menu.Item onClick={() => props.history?.push(HOME)}>
					<img src={companyLogo} alt="Pharma E Pass Logo" />
				</Menu.Item>
				<Menu.Item className="search-bar-global search-bar-global-xl">
					<AutoComplete
						menuStyle={searchStyle}
						getItemValue={(item: any) => item.label}
						inputProps={{
							placeholder: "Search",
						}}
						items={options?.map((option: any) => ({
							label: `${option?.name} - ${option?.type}`,
						}))}
						renderItem={(item: any, isHighlighted: any) => (
							<div
								style={{
									background: isHighlighted
										? "#F7F8FA"
										: "white",
									width: "100%"
								}}
							>
								{item.label}
							</div>
						)}
						value={search}
						onChange={onChange}
						onSelect={(val: any) => {
							const [name, type] = val?.split("-");
							setSearch(name);
							const item = options?.find((option: any) => {
								return option.name.trim() === name.trim();
							});
							if (type?.trim() === "Module") {
								setSearch("");
								props?.history?.push(TOPICS(item?.id));
							} else {
								setSearch("");
								props?.history?.push(QUIZ(item?.id));
							}
						}}
					/>
					<img
						src={SearchIcon}
						alt=""
						className="global-search-icon"
						onClick={() => setShowSearch(false)}
					/>
				</Menu.Item>
				{showSearch ? (
					<Menu.Item className="search-bar-global search-bar-global-sl">
						<AutoComplete
							getItemValue={(item: any) => item.label}
							inputProps={{
								placeholder: "Search",
							}}
							items={options?.map((option: any) => ({
								label: `${option?.name} - ${option?.type}`,
							}))}
							renderItem={(item: any, isHighlighted: any) => (
								<div
									style={{
										background: isHighlighted
											? "#F7F8FA"
											: "white",
									}}
								>
									{item.label}
								</div>
							)}
							value={search}
							onChange={onChange}
							onSelect={(val: any) => {
								const [name, type] = val?.split("-");
								setSearch(name);
								const item = options?.find((option: any) => {
									return option.name.trim() === name.trim();
								});
								if (type?.trim() === "Module") {
									setSearch("");
									props?.history?.push(TOPICS(item?.id));
								} else {
									setSearch("");
									props?.history?.push(QUIZ(item?.id));
								}
							}}
						/>
						<img
							src={SearchIcon}
							alt=""
							className="global-search-icon"
						/>
					</Menu.Item>
				) : null}

				<Menu.Menu position="right">
					{props?.userCourse
						?.map((course: any) => course?.name)
						?.includes("ee") ? (
						<Menu.Item
							className="app-header__menu-item menu-item-xl"
							active={active === "EE"}
							onClick={handleClick}
						>
							EE
						</Menu.Item>
					) : (
						<Menu.Item
							className="menu-item-disabled-xl"
							active={active === "EE"}
							onClick={() => handlePurchase("ee")}
						>
							EE
						</Menu.Item>
					)}
					{props?.userCourse
						?.map((course: any) => course?.name)
						?.includes("mcq") ? (
						<Menu.Item
							className="app-header__menu-item menu-item-xl"
							active={active === "MCQ"}
							onClick={handleClick}
						>
							MCQ
						</Menu.Item>
					) : (
						<Menu.Item
							className="menu-item-disabled-xl"
							active={active === "MCQ"}
							onClick={() => handlePurchase("mcq")}
						>
							MCQ
						</Menu.Item>
					)}
					<Menu.Item className="menu-item-xl">
						<FontAwesomeIcon
							icon={faSignOutAlt}
							onClick={() => {
								signOutAction();
								setTimeout(() => {
									history.push(LOGIN);
								}, 500);
							}}
						/>
					</Menu.Item>

					{!showSearch && (
						<Menu.Item
							className="app-header__menu-item-responsive"
							onClick={() => setShowSearch(true)}
						>
							<img
								src={SearchIcon}
								alt=""
								className="global-search-icon search-bar-global-sl search-icon__alone"
							/>
						</Menu.Item>
					)}

					<Menu.Item className="app-header__menu-item-responsive">
						<i
							className="bars icon"
							onClick={() => setVisible(!visible)}
						/>
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			<Sidebar
				as={Menu}
				animation="overlay"
				direction="right"
				inverted
				vertical
				visible={visible}
				className="sidebar-sm sidebar-auth"
			>
				<div>
					<Menu.Item position="right" header>
						<p
							className="sidebar-close-icon"
							onClick={() => setVisible(false)}
						>
							X
						</p>
					</Menu.Item>
					{sidebarPaths.map((item) => {
						return (
							<Menu.Item
								className="app-sidebar__menu-item"
								as={Link}
								to={item.link}
								active={
									pathname.split("/")[1] ===
									item.link.split("/")[1]
								}
							>
								<div>
									<img src={item.icon} alt="" />
									<p>{item.text}</p>
								</div>
							</Menu.Item>
						);
					})}
					{props?.userCourse
						?.map((course: any) => course?.name)
						?.includes("ee") ? (
						<Menu.Item
							className="app-header__menu-item menu-item-xl app-sidebar__select__sub"
							active={active === "EE"}
							onClick={handleClick}
						>
							EE
						</Menu.Item>
					) : (
						<Menu.Item
							className="menu-item-sx"
							active={active === "EE"}
							onClick={() => handlePurchase("ee")}
						>
							EE
						</Menu.Item>
					)}

					{props?.userCourse
						?.map((course: any) => course?.name)
						?.includes("mcq") ? (
						<Menu.Item
							className="app-header__menu-item menu-item-xl app-sidebar__select__sub"
							active={active === "MCQ"}
							onClick={handleClick}
						>
							MCQ
						</Menu.Item>
					) : (
						<Menu.Item
							className="menu-item-sx"
							active={active === "MCQ"}
							onClick={() => handlePurchase("mcq")}
						>
							MCQ
						</Menu.Item>
					)}
				</div>
				<div>
					<Menu.Item
						className="app-sidebar__menu-item app-sidebar__menu-item-bottom"
						active={pathname.split("/")[1] === "profile"}
						as={Link}
						to={PROFILE}
					>
						<img src={ProfileIcon} alt="" />
						<p>Profile</p>
					</Menu.Item>
					<Menu.Item>
						<a
							className="app-sidebar__menu-item-bottom"
							href="mailto:email-support@pharmaepass.com?subject=Support for pharmaepass (as of now) will confirm with client."
						>
							<img src={InfoIcon} alt="" />
							<p>Support</p>
						</a>
					</Menu.Item>
					<Menu.Item
						className="app-sidebar-logout"
						onClick={() => {
							signOutAction();
							setTimeout(() => {
								history.push(LOGIN);
							}, 500);
						}}
					>
						<FontAwesomeIcon icon={faSignOutAlt} />
						<p>Logout</p>
					</Menu.Item>
				</div>
			</Sidebar>

			<Modal
				onClose={() => {
					if (showAllPurchase) {
						return;
					}
					setShowPurchase(false);
				}}
				onOpen={() => setShowPurchase(true)}
				open={showPurchase}
				className="buy-course__modal"
			>
				<p className="text-center description">
					You are not active in tis course kindly, buy and access this
					course.
				</p>
				{choosedCourse === "mcq" ? (
					<BuyCourseCard
						courseName={MCQInfo.courseName}
						accessPeriod={MCQInfo.accessPeriod}
						features={MCQInfo.features}
						amount={MCQInfo.amount}
						purchaseCourseModal={handlePurchaseModal}
						isAvailable={MCQInfo.isAvailable}
					/>
				) : choosedCourse === "ee" ? (
					<BuyCourseCard
						courseName={EEInfo.courseName}
						accessPeriod={EEInfo.accessPeriod}
						features={EEInfo.features}
						amount={EEInfo.amount}
						purchaseCourseModal={handlePurchaseModal}
						isAvailable={EEInfo.isAvailable}
					/>
				) : null}

				{showAllPurchase ? (
					<div className="pruchase-all-course">
						{["mcq", "ee"].map((course) => {
							const courseData: any =
								course === "mcq"
									? MCQInfo
									: course === "ee"
									? EEInfo
									: "";
							return (
								<BuyCourseCard
									courseName={courseData.courseName}
									accessPeriod={courseData.accessPeriod}
									features={courseData.features}
									amount={courseData.amount}
									purchaseCourseModal={handlePurchaseModal}
									isAvailable={courseData.isAvailable}
								/>
							);
						})}
					</div>
				) : null}
			</Modal>
		</Fragment>
	) : null;
}

AppHeader.propTypes = {};

export default withRouter(AuthContainer(MetaContainer(AppHeader)));
