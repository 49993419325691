import React, { useState, useEffect } from "react";
import { Tab, Menu } from "semantic-ui-react";
import { CorrectIcon, WrongIcon } from "./Icon";
import "./reveal.scss";
import Results from "./Results";
import { ExamAnswer } from "../../../../store/models/Exam/Result.model";

interface IReveal {
	results: any[] | undefined;
}

function Reveal({ results }: IReveal) {
	const panes = [
		{
			menuItem: "All",
			render: () => (
				<Tab.Pane attached={false}>
					<Results results={results?.map((result: ExamAnswer, index: number) => ({
						...result,
						queNo: index + 1,
					}))} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item key="Correct">
					<CorrectIcon classes="result-icon" />
					Correct
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					{" "}
					<Results
						results={results
							?.filter((result: ExamAnswer) => result.isCorrect)
							?.map((result: any, index: number) => ({
								...result,
								queNo: index + 1,
							}))}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item key="Incorrect">
					<WrongIcon classes="result-icon" />
					Incorrect
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<Results
						results={results
							?.filter((result: ExamAnswer) => !result.isCorrect)
							?.map((result: any, index: number) => ({
								...result,
								queNo: index + 1,
							}))}
					/>
				</Tab.Pane>
			),
		},
	];

	return (
		<div className="reveal__container">
			<p className="reveal__header">REVEAL BY:</p>
			<Tab menu={{ secondary: true }} panes={panes} />
		</div>
	);
}

export default Reveal;
