import {
    AUTHENTICATED,
    UNAUTHENTICATED,
    DISABLE_NAVIGATION
} from '../definitions/authConstants';
import { CreateReducer } from "../../shared/utils/createReducer";
import { AuthToken } from "../models/AuthToken/authToken.model";
import { User } from "../models/User/user.model";

interface IAuthState {
    authenticated: boolean;
    authToken?: AuthToken | null;
    successMessage?: string | null;
    user?: User | null;
    disable: boolean;
}

const initState: IAuthState = {
    authenticated: false,
    authToken: null,
    user: null,
    successMessage: null,
    disable: false
};

const authReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: any, action: any) {
        const { authToken, user } = action.payload;
        return {
            ...state,
            authenticated: true,
            authToken: authToken ? authToken : state.authToken,
            user: user ? user : state.user
        };
    },
    [UNAUTHENTICATED](state: any, action: any) {
        return { ...state, authenticated: false };
    },
    [DISABLE_NAVIGATION](state: any, action: {
        payload: boolean
    }): IAuthState {
        return {
            ...state,
            disable: action.payload
        }
    }
});

export default authReducer;
