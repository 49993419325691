
import {serializable, alias, primitive } from 'serializr';

export class Transaction {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('payment_intent_id', primitive()))
    PaymentIntentId?: string;
    
    @serializable(alias('client_secret', primitive()))
    clientSecret?: string;

    @serializable(alias('amount_cents', primitive()))
    amountCents?: number;

    @serializable(alias('payment_intent_status', primitive()))
    PaymentIntentStatus?: string;
}
