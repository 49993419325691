import {serializable, alias, primitive, object, list } from 'serializr';

export class Result {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('user_id', primitive()))
    userId?: number;

    @serializable(alias('exam_id', primitive()))
    examId?: number;

    @serializable(alias('duration_in_sec', primitive()))
    durationInSec?: number;

    @serializable(alias('end_time', primitive()))
    endTime?: string;

    @serializable(alias('updated_at', primitive()))
    updatedAt?: string;

    @serializable(alias('result', primitive()))
    result?: string;
}

export class Results {
    @serializable(alias('exam_attempts', list(object(Result))))
    examAttempts?: Result[];
}