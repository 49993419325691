import React from "react";
import QuestionNumber from "./QuestionNumber";
import examContainer from "../../../../store/containers/examContainer";
import "./QuestionNumberPannel.scss";

function QuestionNumberPannel({
	questions,
	visitedQuestions,
	questionNo,
	setQuestionNo,
	setVisited,
	reloadHandler = () => {}
}: any) {
	const handleClick = (index: any, id: any) => {
		reloadHandler();
		setQuestionNo(index);
		setVisited(id, { visited: true });
	};

	return (
		<div className="number-pannel__container">
			{questions.map((question: any, index: number) => {
				return (
					<QuestionNumber
						key={question.id}
						index={index}
						questionNo={index + 1}
						completed={visitedQuestions[question.id].visited}
						flag={visitedQuestions[question.id].flagged}
						current={questions[questionNo].id === question.id}
						clickHandler={handleClick}
					/>
				);
			})}
		</div>
	);
}

export default examContainer(QuestionNumberPannel);
