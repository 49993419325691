import { alias, primitive, serializable, object } from "serializr";
import { Option } from "./Option.model";
import { QuestionType } from "./Question.model";

export class ExamAnswer {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('exam_attempt_id', primitive()))
    examAttemptId?: number;

    @serializable(alias('question_id', primitive()))
    questionId?: number;

    @serializable(alias('answer', primitive()))
    answer?: number;

    @serializable(alias('correct_answer', primitive()))
    correctAnswer?: number;

    @serializable(alias('is_correct', primitive()))
    isCorrect?: number;

    @serializable(alias('answer_value', primitive()))
    answerValue?: number;
}

export class AnswerResponse {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('content', primitive()))
    content?: string;

    @serializable(alias('options', object(Option)))
    options?: Option;

    @serializable(alias('exam_answer_id', object(ExamAnswer)))
    examAnswerId?: ExamAnswer;

    @serializable(alias('question_type', object(QuestionType)))
    questionType?: QuestionType;
} 

export class QuestionWithAnswer {
    @serializable(alias('question', object(AnswerResponse)))
    question?: AnswerResponse;
}