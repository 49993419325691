import React from 'react';
import PropTypes from 'prop-types';
import * as yup from "yup";
import {Formik} from "formik";
import {Button, Form} from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import {Link} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/containers/authContainer";
import {Login} from "../../../store/models/Login/login.model";
import './forgotPasswordForm.scss';
import {useLockBodyScroll} from "../../../shared/components/LockBodyScroll";

const forgotPasswordValidationSchema = yup.object({
    email: yup.string().email('Email is not valid').required('Email is required'),
    redirectUrl: yup.string().required('Redirect URL is required'),
});

interface IForgotPasswordForm {
    email: string;
    redirectUrl: string;
}

function ForgotPasswordForm(props: any){
    useLockBodyScroll();
    const initialValues: IForgotPasswordForm = {
        email: '',
        redirectUrl: `${appRoutes.APP_URL}${appRoutes.CREATE_NEW_PASSWORD}`
    };
    return (
        <div>
            <h3 className='mt-2 mb-3 text-center text-light-grey'> Forgot Password </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={forgotPasswordValidationSchema}
                onSubmit={(values, actions) => {
                    const loginObject = Object.assign(new Login(),values);
                    props.forgotPasswordAction(loginObject, props.history, ()=> {

                    }, () => {
                    });
                }}>
                {({ handleSubmit, handleChange, values, errors, isValid, dirty}) => {
                    return(
                        <div className='forgot-password-form'>
                            <Form onSubmit={ handleSubmit }>
                                <Form.Field>
                                    <input
                                        name='email'
                                        placeholder='Email'
                                        type='email'
                                        value={ values.email }
                                        onChange={ handleChange }/>
                                    <FormErrorMessage message={errors.email}/>
                                </Form.Field>
                                <Button primary className='w-100'
                                        type='submit'
                                        disabled={ (!isValid) || !dirty }> Reset Password </Button>
                            </Form>
                            <div className='back-to-signin text-center mt-2 mb-2'>
                                Back to
                                <Link to={appRoutes.LOGIN} className='text-primary ml-2'>
                                    Sign in
                                </Link>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
}

ForgotPasswordForm.propTypes = {};

export default AuthContainer(ForgotPasswordForm);
