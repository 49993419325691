import React, { useState, useEffect, Fragment } from "react";
import Footer from "../../../../shared/components/Layouts/Footer";
import BrandCard from "../blogCard";
import { Blog } from "../../../../store/models/Blog/Blog.model";
import "./showBlog.scss";
import blogContainer from "../../../../store/containers/blogContainer";
import {
	useParams,
	useHistory,
	withRouter,
	RouteComponentProps,
} from "react-router-dom";
import { ControllerType } from "../blogCard";
import { SHOW_BLOG } from "../../../../routes/routeConstants/appRoutes";
import Loader from "../../../../shared/components/Loader";
interface allBlogs extends RouteComponentProps {
	getAllBlogs: Function;
	getBlogById: Function;
}

function ShowBlog({ getAllBlogs, history, location }: allBlogs) {
	const [blogs, setBlogs] = useState<Blog[]>([]);
	const [blog, setBlog] = useState<Blog>({});
	const [loading, setLoading] = useState(false);
	const [nextArticles, setNextArticles] = useState<Blog[]>([]);
	const [index, setIndex] = useState(0);
	const params: any = useParams();
	const state: any = location.state;

	useEffect(() => {
		setLoading(true);
		getAllBlogs(
			(blogs: Blog[]) => {
				setBlogs(blogs);
				const blog = blogs?.find((blog, index) => {
					const isPresent = blog?.id === parseInt(params?.id);
					if (isPresent) {
						const blogsCopy = [...blogs];
						setIndex(index);
						blogsCopy.splice(0, index + 1);
						setNextArticles(blogsCopy);
					}
					return isPresent;
				}) as Blog;
				setBlog(blog);
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	}, [state?.reload]);

	const handleController = (type: ControllerType) => {
		if (type === "next") {
			history.push({
				pathname: SHOW_BLOG(blogs[index + 1]?.id),
				state: {
					reload: blogs[index + 1]?.id,
				},
			});
			return;
		}

		if (type === "prev") {
			history.push({
				pathname: SHOW_BLOG(blogs[index - 1]?.id),
				state: {
					reload: blogs[index - 1]?.id,
				},
			});
			return;
		}
	};

	return (
		<Fragment>
			<Loader loading={loading} />
			<div className="show-blog__container">
				<div className="article-container">
					<div className="current-article">
						<h1 className="title">{blog?.title || ""}</h1>
						<BrandCard
							id={blog?.id}
							img={blog?.image?.url || ""}
							title={blog?.title || ""}
							descritpion={blog?.content || ""}
							date={blog?.createdAt}
							show
							control
							handleController={handleController}
							disableController={{
								next: index === blogs.length - 1,
								prev: index === 0,
							}}
						/>
					</div>
					<div className="next-articles">
						<div className="title">
							<h1>New articles</h1>
						</div>
						<div className="card-wrapper">
							{!nextArticles?.length ? "No Article to show" : ""}
							{[...nextArticles]
								?.splice(0, 3)
								?.map((blog: Blog) => (
									<BrandCard
										id={blog?.id}
										img={blog?.image?.url || ""}
										title={blog?.title || ""}
										descritpion={blog?.content || ""}
										date={blog?.createdAt}
										show
										short
										detail
									/>
								))}
						</div>
					</div>
				</div>
				<div className="popular-food">
					<div className="title">
						<h1>Popular Posts</h1>
					</div>
					<div className="card-wrapper">
						{[...blogs]?.splice(0, 3)?.map((blog: Blog) => (
							<BrandCard
								id={blog?.id}
								img={blog?.image?.url || ""}
								title={blog?.title || ""}
								descritpion={blog?.content || ""}
								date={blog?.createdAt}
								detail
							/>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</Fragment>
	);
}

export default withRouter(blogContainer(ShowBlog));
