import React from "react";
import { Menu } from "semantic-ui-react";
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/containers/authContainer";
import { sidebarPaths } from "./data";
import { checkWebSite } from "../../utils/isBlog";
import ProfileIcon from "../../../assets/icon/profile.svg";
import InfoIcon from "../../../assets/icon/info.svg";
import "./appSidebar.scss";

function AppSidebar(props: any) {
  const { pathname } = props.location;
  const { authenticated } = props;
  const location: any = useLocation();
  const type = new URLSearchParams(location?.search).get("type");
  const history = useHistory();

  if (checkWebSite()) {
    return null;
  }

  return authenticated ? (
    <Menu
      secondary
      vertical
      className={`app-sidebar ${
        pathname.split("/")[2] === "exams" ? "app-sidebar-disabled" : ""
      }`}
    >
      <div className="app-sidebar__top">
        {sidebarPaths.map((item) => (
          <Menu.Item
            className={`app-sidebar__menu-item ${
              props?.disable ? "disable-app-sidebar" : ""
            }`}
            as={Link}
            to={item.link}
            active={
              type
                ? (type === "study_mode" || type === "exam_mode") &&
                  "modules-lectures" === item.link.split("/")[1]
                : pathname.split("/")[1] === item.link.split("/")[1]
            }
          >
            <div className="nav-item-container">
              <img src={item?.icon} alt="" /> <p>{item.text}</p>
            </div>
          </Menu.Item>
        ))}
      </div>
      <div className="app-sidebar__bottom">
        <Menu.Item
          className="app-sidebar__menu-item "
          as={Link}
          to={appRoutes.PROFILE}
          active={pathname.split("/")[1] === "profile"}
        >
          <div className="nav-item-container">
            <img src={ProfileIcon} alt="" /> <p>Profile</p>
          </div>
        </Menu.Item>
        <Menu.Item
          className="app-sidebar__menu-item"
          // active={pathname.split("/")[1] === item.link.split("/")[1]}
        >
          <a href="mailto:email-support@pharmaepass.com?subject=Support for pharmaepass (as of now) will confirm with client.">
            <div className="nav-item-container">
              <img src={InfoIcon} alt="" /> <p>Support</p>
            </div>
          </a>
        </Menu.Item>
      </div>
    </Menu>
  ) : null;
}

AppSidebar.propTypes = {};

export default withRouter(AuthContainer(AppSidebar));
