import React from 'react'
import "./box.scss"

interface IBox {
    color?: string;
}

function Box({ color }: IBox) {
    return (
        <span className={`box-container ${color}-box`}>
        </span>
    )
}

export default Box;