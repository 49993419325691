import {serializable, alias, primitive, object, list} from 'serializr';

export class ExamAttempt {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('user_id', primitive()))
    userId?: number;

    @serializable(alias('quiz_id', primitive()))
    quizId?: number;

    @serializable(alias('quiz_mode', primitive()))
    quizMode?: string;

    @serializable(alias('duration_in_sec', primitive()))
    durationInSec?: number;

    @serializable(alias('is_filtered', primitive()))
    isFiltered?: boolean = false;
}