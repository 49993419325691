import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  getAllBlogs,
  getBlogById,
  getBlogsByPage,
} from "../actions/blogAction";

export default function ToastContainer(component: any) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    { getAllBlogs, getBlogById, getBlogsByPage },
    dispatch
  );
}

function mapStateToProps(state: any) {
  return {
    blog: null,
  };
}
