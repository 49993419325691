export const GET_MODULES = "GET_MODULES";
export const GET_MODULE = "GET_MODULES";
export const GET_MODULE_SUCCESS = "GET_MODULES";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_TOPIC = "GET_TOPIC";
export const GET_TOPIC_SUCCESS = "GET_TOPIC_SUCCESS";
export const GET_QUIZ = "GET_QUIZ";
export const EXAM_ATTEMPT = "EXAM_ATTEMPT";
export const SET_ALL_QUESTION = "SET_ALL_QUESTION";
export const GET_QUESTION = "GET_QUESTION";
export const GET_A_ANSWER = "GET_A_ANSWER";
export const UPDATE_QUIZ_ANSWER = "UPDATE_QUIZ_ANSWER";
export const END_TEST = "END_TEST";
export const GET_FILTER_QUIZ_ATTEMPT = "GET_FILTER_QUIZ_ATTEMPT";
export const START_FILTER_QUIZ_ATTEMPT = "START_FILTER_QUIZ_ATTEMPT";