import { alias, primitive, serializable, object, list } from "serializr";

export class Result {
    @serializable(alias('correct', primitive()))
    correct?: number;

    @serializable(alias('wrong', primitive()))
    wrong?: number;

    @serializable(alias('unattempted', primitive()))
    unattempted?: number;

    @serializable(alias('total', primitive()))
    total?: number;

    @serializable(alias('quiz_mode', primitive()))
    quizMode?: string;

    @serializable(alias('quiz_id', primitive()))
    quizId?: string;
    
}

export class ExamAnswer {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('question_id', primitive()))
    questionId?: number;

    @serializable(alias('question_title', primitive()))
    questionTitle?: string;

    @serializable(alias('question_content', primitive()))
    questionContent?: string;

    @serializable(alias('is_correct', primitive()))
    isCorrect?: boolean;
}

export class Results {
    @serializable(alias('result', object(Result)))
    result?: Result;

    @serializable(alias('quiz_question_answers', list(object(ExamAnswer))))
    examQuestionAnswers?: ExamAnswer[];
}