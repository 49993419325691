import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {invokeToastAction} from "../actions/toastActions";

export default function ToastContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({ invokeToastAction }, dispatch);
}

function mapStateToProps(state: any) {
    return {
        type: state.toast.type,
        message: state.toast.message
    };
}
