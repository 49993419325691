import React from 'react'
import "./percentage.scss"

interface IPercentage {
    value: number | string;
    correct?: boolean;
}

function Percentage({ value, correct }: IPercentage) {
    return (
        <div className="percentage__container">
            <h1>{value}<span className="percentage__perc">%</span></h1>
            <p>{correct ? "Correct" : "Incorrect"}</p>
        </div>
    )
}

export default Percentage