import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PERFORMANCE_METRICES } from "../../routes/routeConstants/appRoutes";
import PerformanceMeasure from "./PerformanceMetrices";
import "./analytics.scss";

function Analytics() {
	return (
		<div className="application-body">
			<Switch>
				<Route
					exact
					path={PERFORMANCE_METRICES}
					component={(props: any) => (
						<PerformanceMeasure {...props} />
					)}
				/>
                <Redirect to={PERFORMANCE_METRICES} />
			</Switch>
		</div>
	);
}

export default Analytics;
