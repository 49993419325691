import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom";
import "./backButton.scss";

interface IBackButton extends RouteComponentProps {
	text: string;
	classes?: string;
}

function BackButton({ history, text, classes }: IBackButton) {
	const location = useLocation();
	const [buttonText, setButtonText] = useState("");
	useEffect(() => {
		const [key] = location?.pathname?.split("/").reverse();
		if (key === "pdf" || key === "video" || key === "quiz") {
			setButtonText("to Topics");
			return;
		}
		if (key === "exam") {
			setButtonText("to Quiz");
			return;
		}
		setButtonText("to Modules");
	}, []);

	const handleBack = () => {
		history.goBack();
	};
	return (
		<div
			className={`back-button-container ${classes}`}
			onClick={handleBack}
		>
			<i className="left arrow icon"></i> Back {buttonText}
		</div>
	);
}

export default withRouter(BackButton);
