import * as appRoutes from '../../../routes/routeConstants/appRoutes';
import DashboardIcon from "../../../assets/images/dashboard.svg";
import ModuleIcon from "../../../assets/images/module.svg";
import MockExamIcon from "../../../assets/images/mockExam.svg";
import AnalyticsIcon from "../../../assets/images/analytics.svg";
import DrugChartIcon from "../../../assets/images/drugChart.svg";

export const sidebarPaths = [
    {
        text: "Dashboard",
        icon: DashboardIcon,
        link: appRoutes.HOME
    }, {
        text: "Modules",
        icon: ModuleIcon,
        link: appRoutes.MODULES_LECTURES
    }, {
        text: "Generate Exams",
        icon: MockExamIcon,
        link: appRoutes.MOCK_EXAMS
    }, {
        text: "Performance",
        icon: AnalyticsIcon,
        link: appRoutes.ANALYTICS
    }, {
        text: "Announcements",
        icon: DrugChartIcon,
        link: appRoutes.DRUGS_CHART
    }
]