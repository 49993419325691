import {alias, primitive, serializable, object, list} from "serializr";
import { Option } from "./Option.model";

type Category = "single" | "case";

export class QuestionType {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('content', primitive()))
    content?: any;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('category', primitive()))
    category?: Category;
} 

export class Question {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('content', primitive()))
    content?: string;
    
    @serializable(alias('options',object(Option)))
    options?: Option;

    @serializable(alias('exam_answer_id', primitive()))
    examAnswerId?: any;

    @serializable(alias('question_type', object(QuestionType)))
    questionType?: QuestionType;
}

export class Questions {
    @serializable(alias('questions', list(object(Question))))
    questions?: Question
}