import axiosInstance from "../interceptors/axiosInterceptor";

export class ApiService {
    get(url: string, queryParams?: any) {
        return axiosInstance.get(url, {params: queryParams})
    }

    post(url: string, data: any) {
        return axiosInstance.post(url, data)
    }

    put(url: string, data: any) {
        return axiosInstance.put(url, data)
    }

    delete(url: string) {
        return axiosInstance.delete(url)
    }
}
