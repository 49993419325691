import React, { useEffect, useState, Fragment } from "react";
import "./basicDetailsForm.scss";
import { Button, Form, Input } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { Formik, Field } from "formik";
import AuthContainer from "../../../store/containers/authContainer";
import { User } from "../../../store/models/User/user.model";
import * as yup from "yup";
import MetaContainer from "../../../store/containers/metaContainer";
import { CountryMeta } from "../../../store/models/Meta/CountryMeta/countryMeta.model";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { Link } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Course } from "../../../store/models/Meta/CourseMeta/CourseMeta.model";
import { Transaction } from "../../../store/models/Transaction/transaction.model";

const basicDetailsValidationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Email is not valid").required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Email doesn't match")
    .required("Confirm email is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Password confirmation is required"),
  countryId: yup.string().required("Country is required"),
  address: yup.string().required("Address is required"),
  postalCode: yup.string().required("Postal code is required"),
  courseId: yup.string().required("Course is required"),
  nameOnCard: yup.string().required("Name on card is required"),
  billingAddress: yup.string().required("Billing address is required"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

const policyVerificationLink = (
  <span onClick={(e) => e.stopPropagation()}>
    By clicking &lt;Create Account&gt; I agree to{" "}
    <a
      target="_blank"
      href="https://pharmaepass.com/terms-conditions.html"
      className="policy-verification-link"
    >
      Terms of service
    </a>{" "}
    and{" "}
    <a
      target="_blank"
      href="https://pharmaepass.com/privacy-policy.html"
      className="policy-verification-link"
    >
      Privacy Policy
    </a>
  </span>
);

function BasicDetailsForm(props: any) {
  const stripe = useStripe();
  const elements = useElements();
  const basicDetailsInitialValues: User = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    countryId: undefined,
    address: "",
    postalCode: "",
    courseId: undefined,
    nameOnCard: "",
    billingAddress: "",
    termsAndConditions: false,
    couponCode: "",
  };

  const [countryLoader, setCountryLoader] = useState(false);
  const [courseLoader, setCourseLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [validCoupon, setValidCoupon] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const {
    getCountriesAction,
    countryMetas,
    courseMetas,
    getCoursesAction,
    history,
    registerUserAction,
    validateCouponCodeAction,
  } = props;

  const preSelectedCourse: any = props.location.search
    ? new URLSearchParams(props.location.search).get("courseId")
    : undefined;

  useEffect(() => {
    setCountryLoader(true);
    getCountriesAction(
      () => {
        setCountryLoader(false);
      },
      () => {
        setCountryLoader(false);
      }
    );
    setCourseLoader(true);
    getCoursesAction(
      () => {
        setCourseLoader(false);
      },
      () => {
        setCourseLoader(false);
      }
    );
  }, []);
  const countryOptions = countryMetas.map((countryMeta: CountryMeta) => {
    return {
      key: countryMeta.id,
      text: countryMeta.name,
      value: countryMeta.id,
    };
  });

  const courseOptions = courseMetas.map((courseMeta: Course) => {
    return {
      key: courseMeta.id,
      text: `${ courseMeta?.displayName ?? courseMeta.name?.toUpperCase()} ($${courseMeta.amountDollars})`,
      value: courseMeta.id,
    };
  });

  const CardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#172B4D",
        fontSize: "18px",
        "::placeholder": {
          color: "rgba(34,36,38,.3)",
        },
      },
    },
  };

  const onSubmitHandler = async (values: User) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: values.nameOnCard ?? "",
        email: values.email,
        address: {
          line1: values.billingAddress,
          country: values.country?.name,
          postal_code: values.postalCode,
        },
      },
    });

    if (error) {
      return;
    }
    const data = {
      ...values,
      pm_id: paymentMethod?.id,
      save_card: false,
    };
    const payload = Object.assign(new User(), data);
    setLoading(true);
    registerUserAction(
      payload,
      async ({ clientSecret, PaymentIntentStatus }: Transaction) => {
        if (PaymentIntentStatus === "requires_action") {
          try {
            await stripe.confirmCardPayment(
              clientSecret ?? "",
              {
                payment_method: {
                  card: cardElement,
                },
              },
              {
                handleActions: true,
              }
            );
            return history.push(appRoutes.HOME);
          } catch (error) {
            return;
          }
        }
        setLoading(false);
        return history.push(appRoutes.HOME);
      },
      () => {
        setLoading(false);
      }
    );
  };


  const validateCouponCode = async (values: User) => {
    if (values.couponCode) {
      validateCouponCodeAction(
        values.couponCode, 
        (discount: string) => {
          setValidCoupon(true);
          setDiscountValue(discount)
          setCouponApplied(true);
        },
        () => {
          setValidCoupon(false);
          setCouponApplied(true);
        });
    }
  }

  const couponCodeKeyPressed = async (e: any, values: User) => {
    if (e.key === "Enter") {
      validateCouponCode(values);
    }
  }

  return (
    <div className="basic-details-form">
      <h2>Personal Information</h2>
      <Formik
        initialValues={basicDetailsInitialValues}
        validationSchema={basicDetailsValidationSchema}
        onSubmit={onSubmitHandler}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <div>
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Form.Field width={8}>
                    <label>First Name</label>
                    <input
                      name="firstName"
                      placeholder="First name"
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    <FormErrorMessage message={errors.firstName} />
                  </Form.Field>
                  <Form.Field width={8}>
                    <label>Last Name</label>
                    <input
                      name="lastName"
                      placeholder="Last name"
                      type="text"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    <FormErrorMessage message={errors.lastName} />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label>Email</label>
                  <input
                    name="email"
                    placeholder="Email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <FormErrorMessage message={errors.email} />
                </Form.Field>
                <Form.Field>
                  <label>Confirm Email</label>
                  <input
                    name="confirmEmail"
                    placeholder="Re-enter your email"
                    type="text"
                    value={values.confirmEmail}
                    onChange={handleChange}
                  />
                  <FormErrorMessage message={errors.confirmEmail} />
                </Form.Field>
                <Form.Field>
                  <label>Address</label>
                  <input
                    name="address"
                    placeholder="Address"
                    type="text"
                    value={values.address}
                    onChange={handleChange}
                  />
                  <FormErrorMessage message={errors.address} />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field width={8}>
                    <label>Country</label>
                    <Field name="countryId">
                      {({ field, form: { touched, errors }, meta }: any) => (
                        <div>
                          <Form.Dropdown
                            selection
                            placeholder="Select Country"
                            name="countryId"
                            selectOnBlur
                            loading={countryLoader}
                            options={countryOptions}
                            value={values.countryId}
                            error={!!touched.type && !!errors.type}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value);
                            }}
                          />
                          <FormErrorMessage message={errors.countryId} />
                        </div>
                      )}
                    </Field>
                  </Form.Field>
                  <Form.Field width={8}>
                    <label>Postal code</label>
                    <input
                      name="postalCode"
                      placeholder="Postal code"
                      value={values.postalCode}
                      onChange={handleChange}
                    />
                    <FormErrorMessage message={errors.postalCode} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field width={8}>
                    <label>Password</label>
                    <input
                      name="password"
                      placeholder="Password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <FormErrorMessage message={errors.password} />
                  </Form.Field>
                  <Form.Field width={8}>
                    <label>Confirm Password</label>
                    <input
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                    />
                    <FormErrorMessage message={errors.confirmPassword} />
                  </Form.Field>
                </Form.Group>
                <h2>Choose Course</h2>
                <Form.Field>
                  <Field name="courseId">
                    {({ field, form: { touched, errors }, meta }: any) =>
                      preSelectedCourse ? (
                        <div>
                          <Form.Dropdown
                            selection
                            placeholder="Choose Course"
                            name="courseId"
                            selectOnBlur
                            defaultValue={parseInt(preSelectedCourse)}
                            loading={courseLoader}
                            options={courseOptions}
                            value={values.courseId}
                            error={!!touched.type && !!errors.type}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value);
                            }}
                          />
                          <FormErrorMessage message={errors.courseId} />
                        </div>
                      ) : (
                        <div>
                          <Form.Dropdown
                            selection
                            placeholder="Choose Course"
                            name="courseId"
                            selectOnBlur
                            loading={courseLoader}
                            options={courseOptions}
                            value={values.courseId}
                            error={!!touched.type && !!errors.type}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value);
                            }}
                          />
                          <FormErrorMessage message={errors.courseId} />
                        </div>
                      )
                    }
                  </Field>
                </Form.Field>
                <Form.Field>
                  <Input
                    name="couponCode"
                    icon='tags'
                    iconPosition='left'
                    label={{ 
                      tag: true, 
                      content: 'Apply', 
                      onClick: () => validateCouponCode(values),
                    }}
                    labelPosition='right'
                    placeholder='Coupon Code'
                    value={values.couponCode}
                    onChange={handleChange}
                    onKeyPress={(e: any) => couponCodeKeyPressed(e, values)}
                  />
                  {couponApplied && <span className={`coupon-code ${validCoupon ? "" : "invalid"}`}>
                    {validCoupon ? `Coupon successfully applied. ${discountValue} will be applied at checkout.` : "This coupon code is invalid."}
                  </span>}
                </Form.Field>
                <h2>Billing Information</h2>
                <Form.Field>
                  <input
                    name="nameOnCard"
                    placeholder="Enter Name on Card"
                    type="text"
                    value={values.nameOnCard}
                    onChange={handleChange}
                  />
                  <FormErrorMessage message={errors.nameOnCard} />
                </Form.Field>
                <Form.Field>
                  <CardElement options={CardOptions} className="card-field" />
                  <span className="text-light-grey mt-1">
                    <sup>*</sup>
                    {"Visa, Master card & Amex"}
                  </span>
                </Form.Field>
                <Form.Field>
                  <input
                    name="billingAddress"
                    placeholder="Billing Address"
                    type="text"
                    value={values.billingAddress}
                    onChange={handleChange}
                  />
                  <FormErrorMessage message={errors.billingAddress} />
                </Form.Field>
                <Field name="courseId">
                  {({ field, form: { touched, errors }, meta }: any) => (
                    <Fragment>
                      <Form.Checkbox
                        className="form-checkbox"
                        name="termsAndConditions"
                        onChange={(e, { name, checked }) => {
                          setFieldValue(name ?? "", checked);
                        }}
                        label={{
                          children: policyVerificationLink,
                        }}
                      />
                      <FormErrorMessage message={errors.termsAndConditions} />
                    </Fragment>
                  )}
                </Field>
                <div className="login-link">
                  <p>
                    Already have an Account?{" "}
                    <Link className="policy-verification-link" to="">
                      Log In
                    </Link>{" "}
                    here
                  </p>
                </div>

                <Button
                  primary
                  type="submit"
                  disabled={!isValid || !dirty || loading}
                >
                  {" "}
                  {loading ? "Processing . . ." : "Create Account"}{" "}
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

BasicDetailsForm.propTypes = {};

export default AuthContainer(MetaContainer(BasicDetailsForm));
