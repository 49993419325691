import React, { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { Progress } from "semantic-ui-react";
import "./listItem.scss";

interface IListItem {
	item: any;
	index: number;
	minimal?: boolean;
	avegrage?: boolean;
	description?: boolean;
}

function ListItem({ item, index, minimal, avegrage, description }: IListItem) {
	const [hide, setHide] = useState<boolean>(true);
	const handleHide = () => {
		setHide((state) => !state);
	};
	const score = avegrage ? item?.average : item?.completion;
	return (
		<div className="list-item-container" onClick={handleHide}>
			<div className="list-item-inner__container">
				<div className="left">
					{!minimal && <p>{"MODULE " + (index + 1)}</p>}
					<h2>{item.name}</h2>
				</div>
				<div
					className={`right ${
						minimal ? "minimal-right list-sl" : ""
					}`}
				>
					{!minimal && (
						<h2 className="percentage">
							{score % 1 === 0 ? score : score.toFixed(2)}%
						</h2>
					)}
					<i
						className={hide ? "angle down icon" : "angle up icon"}
					></i>
				</div>
			</div>
			{minimal ? (
				<AnimateHeight duration={500} height={hide ? 0 : "auto"}>
					<div className="topic-progress-container">
						<Progress
							percent={score % 1 === 0 ? score : score.toFixed(2)}
							className={
								score >= 70
									? "list-progress_bar-success"
									: score >= 50
									? "list-progress_bar-warning"
									: `list-progress_bar-danger`
							}
							progress
						/>
					</div>
				</AnimateHeight>
			) : (
				<AnimateHeight duration={500} height={hide ? 0 : "auto"}>
					{description ? (
						<div className="score-description">
							<p>
								Score are calculated based on the average of the
								latest 2 attempts taken in{" "}
								<span>Exam Mode</span> with{" "}
								<span>All Questions</span> selected
							</p>
						</div>
					) : null}
					<div className="topic-progress-container">
						{item?.topics?.map(
							({ name, average, completion }: { name: string, average: number, completion: number }) => {
								const comp = completion % 1 === 0 ? completion : completion.toFixed(2);
								const avg = average % 1 === 0 ? average : average.toFixed(2);
								return (
									<div>
										<p>{name?.toUpperCase()}</p>
										<Progress
											percent={
												avegrage ? avg : comp
											}
											className={
												(avegrage
													? avg
													: comp) >= 70
													? "list-progress_bar-success"
													: (avegrage
															? avg
															: comp) >= 50
													? "list-progress_bar-warning"
													: `list-progress_bar-danger ${
															avg > 1 &&
															avg < 4 &&
															"max-width"
													  }`
											}
											progress
										/>
									</div>
								);
							}
						)}
					</div>
				</AnimateHeight>
			)}
		</div>
	);
}

export default ListItem;
