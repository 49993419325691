import {CreateReducer} from "../../shared/utils/createReducer";
import { PERFORMANCE_AREA_SUCCESS, GET_CHART_DATA_SUCCESS } from "../definitions/performaceConstants";


interface IPerformanceState {
  weakness: any,
  strength: any,
  chart: any;
}

const initState: IPerformanceState = {
  weakness: null,
  strength: null,
  chart: null
};

const performanceReducer = CreateReducer(initState, {
  [PERFORMANCE_AREA_SUCCESS] (state: IPerformanceState, action: any) {
      const { weakness, strength } = action.payload;
      return {
          ...state,
          weakness,
          strength 
      }
  },
  [GET_CHART_DATA_SUCCESS] (state: IPerformanceState, action: any) {
    return {
      ...state,
      chart: action.payload
    }
  }
});

export default performanceReducer;
