import React from "react";
import moment from "moment";
import NewIcon from "../../../../assets/icon/new.svg";
import "./brandNew.scss";

interface IBrandNew {
  backgroundImage: any;
  title: string;
  description: string;
  date?: string;
  hasNewIcon?: boolean;
}

export default function BrandNew({
  backgroundImage,
  title,
  description,
  date,
  hasNewIcon = false,
}: IBrandNew) {
  return (
    <div
      className="brand-new__container"
      style={{ background: `url(${backgroundImage})` }}
    >
      {hasNewIcon && <img src={NewIcon} alt="" />}
      <div className="content">
        <h2>{title}</h2>
        <p className="desc">{description}</p>
        {date && (
          <p className="date">
            {moment(new Date(date)).format("DD MMMM yyyy")}
          </p>
        )}
      </div>
    </div>
  );
}
