import React, { useRef, useEffect } from "react";
import moment from "moment";
import "./blogCard.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SHOW_BLOG } from "../../../../routes/routeConstants/appRoutes";
import parse from "html-react-parser";

export type ControllerType = "next" | "prev";

interface IBlogCard extends RouteComponentProps {
	id: any;
	img: string;
	title: string;
	descritpion: string;
	date: any;
	detail?: boolean;
	show?: boolean;
	short?: boolean;
	control?: boolean;
	handleController?: (type: ControllerType) => void;
	disableController?: {
		next?: boolean;
		prev?: boolean;
	};
}

function BrandCard({
	handleController = () => {},
	id,
	img,
	title,
	descritpion,
	date,
	history,
	detail = false,
	show = false,
	short = false,
	control = false,
	disableController = {
		next: false,
		prev: false,
	},
}: IBlogCard) {
	const descRef = useRef<HTMLDivElement>(null);

	const handleOpenBlog = () => {
		!control &&
			history.push({ pathname: SHOW_BLOG(id), state: { reload: id } });
	};

	return (
		<div className="brand-card__container" onClick={handleOpenBlog}>
			{!short ? (
				<div className="img-container">
					<img src={img} alt="" />
				</div>
			) : null}
			<div className="content">
				{detail ? <h2 className="title">{title}</h2> : null}
				<div
					className={`description ${show ? "" : "description-card"}`}
					ref={descRef}
				>
					{parse(descritpion)}
				</div>
				{detail ? (
					<p className="date">
						{moment(new Date(date)).format("DD MMMM yyyy")}
					</p>
				) : null}
			</div>
			{control ? (
				<div className="control">
					<button
						className="article-controller"
						disabled={disableController.prev}
						onClick={(event) => {
							handleController("prev");
						}}
					>
						Prev Article
					</button>
					<button
						className="article-controller"
						disabled={disableController.next}
						onClick={(event) => {
							handleController("next");
						}}
					>
						Next ticle
					</button>
				</div>
			) : null}
		</div>
	);
}

export default withRouter(BrandCard);
