import { serializable, alias, primitive, object, list, } from "serializr";
import { CountryMeta } from "../Meta/CountryMeta/countryMeta.model";

export class Course {
    @serializable(alias("course_name", primitive()))
    courseName?: string;

    @serializable(alias("expiry_date", primitive()))
    expiryDate?: string;
}

export class User {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("first_name", primitive()))
    firstName?: string;

    @serializable(alias("last_name", primitive()))
    lastName?: string;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("confirmEmail", primitive()))
    confirmEmail?: string;

    @serializable(alias("password", primitive()))
    password?: string;

    @serializable(alias("confirm_password", primitive()))
    confirmPassword?: string;

    @serializable(alias("country", object(CountryMeta)))
    country?: CountryMeta;

    @serializable(alias("address", primitive()))
    address?: string;

    @serializable(alias("postal_code", primitive()))
    postalCode?: string;

    @serializable(alias("country_id", primitive()))
    countryId?: string;

    @serializable(alias("course_id", primitive()))
    courseId?: string;

    @serializable(alias("pm_id", primitive()))
    pm_id?: string;

    @serializable(alias("name_on_card", primitive()))
    nameOnCard?: string;

    @serializable(alias("billing_address", primitive()))
    billingAddress?: string;

    @serializable(alias("save_card", primitive()))
    save_card?: string;

    @serializable(alias("confirmed_at", primitive()))
    confirmedAt?: string;

    @serializable(alias("confirmation_token", primitive()))
    confirmationToken?: string;

    @serializable(alias("terms_and_conditions", primitive()))
    termsAndConditions?: boolean;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("updated_at", primitive()))
    updatedAt?: string;

    @serializable(alias("active_subscription", list(object(Course))))
    activeSubscription?: Course[] | null;

    @serializable(alias("coupon_code", primitive()))
    couponCode?: string;
}
