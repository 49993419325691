import { Link, withRouter } from "react-router-dom";
import React, { Fragment } from "react";
import Facebook from "../../../../assets/icon/facebook.svg";
import Instagram from "../../../../assets/icon/instagram.svg";
import { TERMS_AND_CONDITIONS } from "../../../../routes/routeConstants/dist/appRoutes";
import "./footer.scss";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";


const data = {
  Products: ["EE Exam", "MCQ Exam", "OSCE Exam"],
  Education: ["References", "About us", "Contact us"],
  Resources: ["Privacy Policy", "Terms of Use", "FAQ"],
  "FOLLOW US": ["text"],
};

const heading = Object.keys(data);
const values = Object.values(data);
const Footer = () => {
  return (
    <footer className="wraper-footer">
      <div className="footer-dot">
        <img src="https://pharmaepass.com/assets/images/footer-dots.svg" />
      </div>

      <div className="wraper-footer-main">
        <div className="container">
          <div className="footer-main row">
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div
                  className="
                    footer-main-item
                    widget
                    col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12
                  "
                >
                  <p className="widget-title">Products</p>
                  <ul className="menu">
                    <li>
                      <a href="https://pharmaepass.com/ee-course.html">EE Course</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/mcq-course.html">MCQ Course</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/osce.html">OSCE Course</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="
                    footer-main-item
                    widget
                    col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12
                  "
                >
                  <p className="widget-title">Education</p>
                  <ul className="menu">
                    <li>
                      <a href="https://pharmaepass.com/references.html">References</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/about-us.html">About us</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/contact-us.html">Contact us</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="
                    footer-main-item
                    widget
                    col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12
                  "
                >
                  <p className="widget-title">Resources</p>
                  <ul className="menu">
                    <li>
                      <a href="https://pharmaepass.com/privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/terms-conditions.html">Terms of Use</a>
                    </li>
                    <li>
                      <a href="https://pharmaepass.com/faq.html">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="row">
                <div className="footer-main-item widget col-12">
                  <p className="widget-title">Subscribe to Pharmaepass</p>

                  <div className="footer-newsletter-form">
                    <form>
                      <div
                        className="
                          newsletter-form-group
                          position-relative
                          d-block
                          mx-0
                        "
                      >
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter Your Email Address"
                        />
                        <button
                          className="btn-send position-absolute"
                          type="submit"
                        >
                          <span className="ic-send-arrow"></span>
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="footer-social-holder">
                    <p className="widget-title">Follow Us, We Follow Back</p>
                    <ul className="footer-social">
                      <li className="facebook">
                        <a href="https://www.facebook.com/PharmaEpass-353324298639873" target="_blank">
                          <FaFacebookF />
                        </a>
                      </li>
                      {/* <li className="twitter">
                        <a href="#" target="_blank">
                          <span className="ic-twitter"></span>
                        </a>
                      </li> */}
                      <li className="instagram">
                        <a href="https://www.instagram.com/pharmaepass/?utm_medium=copy_link" target="_blank">
                          {/* <i className="lab la-instagram"></i> */}
                          <FaInstagram />
                        </a>
                      </li>

                      <li className="youtube">
                        <a href="https://www.youtube.com/channel/UCqRn556Uh8d8fZeygNscLfQ" target="_blank">
                          {/* <span className="ic-youtube"></span> */}
                          <FaYoutube />
                        </a>
                      </li>
                      {/* 
                      <li className="linkedin">
                        <a href="#" target="_blank">
                          <span className="ic-linkedin"></span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copy-right-row row align-items-center mt-0">
            <div className="col-lg-8 order-2 order-lg-1 mt-4 mt-lg-0">
              <span className="logoText">PHARMAEPASS</span>
              <span className="copyRightText">
                © <span id="copy-rights-year">2022</span> PharmaEpass. All
                rights reserved.
              </span>
            </div>

            {/* <div className="col-lg-4 text-right order-1 order-lg-2">
              <div className="footer-social-holder">
                <p className="widget-title">Follow Us, We Follow Back</p>
                <ul className="footer-social">
                  <li className="facebook">
                    <a href="#" target="_blank">
                      <span className="ic-facebook"></span>
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#" target="_blank">
                      <span className="ic-twitter"></span>
                    </a>
                  </li>
                  <li className="instagram">
                    <a href="#" target="_blank">
                      <i className="lab la-instagram"></i>
                    </a>
                  </li>

                  <li className="youtube">
                    <a href="#" target="_blank">
                      <span className="ic-youtube"></span>
                    </a>
                  </li>

                  <li className="linkedin">
                    <a href="#" target="_blank">
                      <span className="ic-linkedin"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="wraper-footer-cta">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-10">
              <div className="footer-cta">
                <div className="footer-cta-data">
                  <p className="copy-rights">
                    Material may not be distributed or reproduced without
                    permission. PEBC® is a registered trademark of The Pharmacy
                    Examining Board of Canada. PharmEpass and its services are
                    neither affiliated with nor endorsed by PEBC®.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
