import axios from 'axios';
import {store} from "../../store";
import {REQUEST_LOGIN_WITH_REFRESH_TOKEN, REQUEST_LOGOUT} from "../definitions/authConstants";
import * as ApiRoutes from '../../routes/routeConstants/apiRoutes';
import {AuthToken} from "../models/AuthToken/authToken.model";

export const getHeaders = (): any => {
    let headers, authToken;
    if(localStorage.getItem('authToken')) {
        authToken = JSON.parse(localStorage.getItem('authToken') || '');
    }
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${(authToken && authToken.accessToken) ? authToken.accessToken : ''}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
        const { response } = error;
        const originalRequest = error?.config;
        originalRequest._retry = true;
        if (response.status === 401) {
            store.dispatch({ type: REQUEST_LOGIN_WITH_REFRESH_TOKEN, payload: originalRequest});
        }
        return {
            message: response.data['error'],
            status: response.status
        }
    }
);

export default axiosInstance;
