import React, { useEffect, useState } from "react";
import "./performanceMetrices.scss";
import Card from "../../../shared/components/Card";
import ModuleContainer from "../../../store/containers/moduleContainer";
import MetaContainer from "../../../store/containers/metaContainer";
import List from "./List";
import performanceContainer from "../../../store/containers/performaceContainer";
import PrepScore from "./PrepScore";
import { Bar } from "react-chartjs-2";
import { Result } from "../../../store/models/Performance/Chart.model";
import moment from "moment";
import Loader from "../../../shared/components/Loader";
import examContainer from "../../../store/containers/examContainer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { localStorageClean } from "../../MockExam/ExamDescription";
import { MOCK_EXAMS_RESULT } from "../../../routes/routeConstants/appRoutes";
interface IPerformanceMetrices extends RouteComponentProps {
  course: any;
  getModules: Function;
  courseModules: any;
  strength: any;
  weakness: any;
  getStrengthAndWeakness: Function;
  getPrepScore: Function;
  getChartData: Function;
  setQuestions: Function;
  getResults: Function;
  setExamAttempt: Function;
}

function PerformanceMetrices({
  course,
  getModules,
  courseModules,
  strength,
  weakness,
  getStrengthAndWeakness,
  getPrepScore,
  getChartData,
  setQuestions,
  history,
  getResults,
  setExamAttempt,
}: IPerformanceMetrices) {
  const [prepScore, setPrepScore] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [recentAttempt, setRecentAttempt] = useState<number | undefined>();
  const [config, setConfig] = useState<any>({
    labels: [],
    datasets: [
      {
        backgroundColor: "#EAEDF2",
        data: [],
        hoverBackgroundColor: "#44C37A",
      },
    ],
  });
  useEffect(() => {
    if (course) {
      setLoading(true);
      getModules(
        course?.id,
        () => {
          getStrengthAndWeakness(
            course?.id,
            () => {
              getPrepScore(
                course?.id,
                (score: number) => {
                  setPrepScore(score ? score : 0);
                  getChartData(
                    course?.id,
                    (data: Result[] | null) => {
                      if (data?.length) {
                        setRecentAttempt(data[0]?.id);
                      }
                      if (data === null) {
                        setConfig(null);
                      }
                      const reverseData = data?.reverse();
                      const labels = reverseData?.map((result) => {
                        return moment(result?.updatedAt).format("MMM DD");
                      });

                      const values = reverseData?.map((result) => {
                        return Math.round(parseInt(result?.result as string));
                      });

                      setConfig((state: any) => {
                        return {
                          ...state,
                          labels,
                          datasets: [
                            {
                              ...state.datasets[0],
                              data: values,
                            },
                          ],
                        };
                      });
                      setLoading(false);
                    },
                    () => {
                      setLoading(false);
                    }
                  );
                },
                () => {
                  setLoading(false);
                }
              );
            },
            () => {
              setLoading(false);
            }
          );
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [course]);

  return (
    <div className="performace-measure__container">
      <Loader loading={loading} />
      <div className="header">
        <p>ANALYTICS</p>
        <h1>PERFORMANCE Metrics</h1>
      </div>
      <div className="body">
        <div className="body-left">
          <div className="module-outlet__container">
            <Card title="Completion by Module">
              <List items={courseModules} />
            </Card>
          </div>
        </div>
        <div className="body-right">
          <div className="prepscore-outlet__container">
            <PrepScore score={prepScore} />
          </div>
          <div className="strength-outlet__container">
            <Card title="Areas of Weakness ">
              <List items={weakness} minimal />
            </Card>
          </div>
          <div className="weakness-outlet__container">
            <Card title="Areas of Strength ">
              <List items={strength} minimal />
            </Card>
          </div>
        </div>
      </div>
      <div className="score-based-last-attempt">
        <Card title="Average score on each topic - improve your scores to increase your PrepSCORE ">
          <List items={courseModules} avegrage description />
        </Card>
      </div>
      {config ? (
        <div className="chart-view">
          <Card
            title="Mock Exam History by Date"
            btnText="Review Most Recent Exam"
            clickHandler={() => {
              setLoading(true);
              setExamAttempt({ id: recentAttempt });
              setQuestions(
                recentAttempt,
                () => {
                  getResults(
                    recentAttempt,
                    () => {
                      setLoading(false);
                      localStorageClean();
                      history.push(MOCK_EXAMS_RESULT);
                    },
                    () => {
                      setLoading(false);
                    }
                  );
                },
                () => {
                  setLoading(false);
                }
              );
            }}
          >
            <Bar
              data={config}
              options={{
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        stepSize: 25,
                        callback: (value: number) => `${value}%`,
                      },
                    },
                  ],
                },
                responsive: true,
                tooltips: {
                  backgroundColor: "white",
                  bodyFontColor: "#494A50",
                  callbacks: {
                    label: (toolTips: any) => {
                      return `${toolTips?.value}%`;
                    },
                  },
                },
              }}
            />
          </Card>
        </div>
      ) : null}
    </div>
  );
}
export default withRouter(
  ModuleContainer(
    MetaContainer(performanceContainer(examContainer(PerformanceMetrices)))
  )
);
