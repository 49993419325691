import React from "react";
import PropTypes from "prop-types";
import CreateAccountRoutes from "../createAccountRoutes";
import "./createAccount.scss";

function CreateAccount() {
  return (
    <div className="create-account">
      <h1 className="text-center">
        Start preparing today using the most intuitive platform.
      </h1>
      <p className="text-center">
        Sign up now for instant access to PharmaEpass.
      </p>
      <CreateAccountRoutes />
    </div>
  );
}

CreateAccount.propTypes = {};

export default CreateAccount;
