import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as yup from 'yup';
import './loginForm.scss';
import { Login } from "../../../store/models/Login/login.model";
import AuthContainer from "../../../store/containers/authContainer";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import {Link} from 'react-router-dom';
import * as appRoutes from '../../../routes/routeConstants/appRoutes';
import {useLockBodyScroll} from "../../../shared/components/LockBodyScroll";

const loginValidationSchema = yup.object({
    email: yup.string().email('Email is not valid').required('Email is required'),
    password: yup.string().required('Password is required'),
});

interface ILoginForm {
    email: string;
    password: string;
    grantType: string;
}

function LoginForm(props: any){
    const initialValues: ILoginForm = { email: '', password: '', grantType: 'password' };
    useLockBodyScroll();
    return (
        <div>
            <h3 className='mt-2 mb-3 text-center text-light-grey'> Login </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={loginValidationSchema}
                onSubmit={(values) => {
                    const loginObject = Object.assign(new Login(),values);
                    props.signInAction(loginObject, () => {
                        setTimeout(() => {
                            props.history.push(appRoutes.HOME);
                        }, 500);
                        }, (error: Error) => {
                    });
                }}>
                {({ handleSubmit, handleChange, values, errors, isValid, dirty}) => {
                    return(
                        <div className='login-form'>
                            <Form onSubmit={ handleSubmit }>
                                <Form.Field>
                                    <input
                                        name='email'
                                        placeholder='Email'
                                        type='email'
                                        value={ values.email }
                                        onChange={ handleChange }/>
                                    <FormErrorMessage message={errors.email}/>
                                </Form.Field>
                                <Form.Field>
                                    <input
                                        name='password'
                                        placeholder='Password'
                                        type='password'
                                        value={ values.password }
                                        onChange={ handleChange }/>
                                    <FormErrorMessage message={ errors.password }/>
                                </Form.Field>
                                <Button primary className='w-100'
                                        type='submit'
                                        disabled={ (!isValid) || !dirty }> Login </Button>
                            </Form>
                            <div className='text-center mt-2 mb-2'>
                                <Link to={appRoutes.FORGOT_PASSWORD} className='mt-1 text-primary'>
                                    Forgot Password?
                                </Link>
                                <Link to={appRoutes.CREATE_ACCOUNT} className='mt-1 text-primary ml-2'>
                                    Create Account
                                </Link>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
}

export default AuthContainer(LoginForm);
