import React, { FC } from "react";
import "./modeContent.scss";

type ContentType = "mode" | "quiz";

interface IModeContent {
	type: ContentType;
	text: string;
	active: boolean;
	updateClickHandler: (value: string) => void;
	left?: boolean;
	disabled?: boolean;
}

const ModeContent: FC<IModeContent> = ({
	type,
	text = "",
	active = false,
	updateClickHandler,
	left = false,
	disabled = false,
}) => {
	return (
		<div className="mode-content__container">
			<div
				className={`content ${active ? "content-active" : ""} ${
					left ? "content-left" : "content-right"
				} ${disabled ? "content-disabled" : ""}`}
				onClick={() => updateClickHandler(text)}
			>
				<p>{text}</p>
			</div>
		</div>
	);
};

export default ModeContent;
