import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
	getModules,
	getTopic,
	getQuiz,
	createQuizAttempt,
	getAllQuestions,
	getSingleQuestion,
	getAAnswer,
	updateExamAnswer,
	endTest,
	getModule,
	getFilteredAttempt,
	createFilterAttempt
} from "../actions/moduleAction";

export default function ModuleContainer(component: any) {
	return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators(
		{
			getModule,
			getModules,
			getTopic,
			getQuiz,
			createQuizAttempt,
			getAllQuestions,
			getSingleQuestion,
			getAAnswer,
			updateExamAnswer,
			endTest,
			getFilteredAttempt,
			createFilterAttempt
		},
		dispatch
	);
}

function mapStateToProps(state: any, ownProps: any) {
	return {
		courseModule: state.module.courseModule,
		courseModules: state.module.courseModules,
		topic: state.module.topic,
		...ownProps,
	};
}
