
import {serializable, alias, primitive, object, list } from 'serializr';

export class topicWeakArea {
    @serializable(alias('topic_name', primitive()))
    name?: string;

    @serializable(alias('topic_average', primitive()))
    completion?: string;
}

export class topicWeakAreas {
    @serializable(alias('topic_weak_areas', list(object(topicWeakArea))))
    topicWeakAreas?: topicWeakArea[];
}