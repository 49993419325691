import React, { useEffect, Fragment, useState } from "react";
import { Button } from "semantic-ui-react";
import BackButton from "../../../shared/components/BackButton";
import Vimeo from "@u-wave/react-vimeo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MetaContainer from "../../../store/containers/metaContainer";
import ModuleContainer from "../../../store/containers/moduleContainer";
import Loader from "../../../shared/components/Loader";
import { QUIZ, TOPIC_PDF } from "../../../routes/routeConstants/appRoutes";
import "./video.scss";

interface IViewPDF extends RouteComponentProps {
	course: any;
	getTopic: Function;
	topic: any;
}

function Video({
	history,
	match: { params },
	course,
	getTopic,
	topic,
}: IViewPDF) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const param: any = params;
		if (course) {
			setLoading(true);
			getTopic(
				course.id,
				param.id,
				(data: any) => {
					// setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		}
	}, [course]);

	const handleRead = () => {
		history.push(TOPIC_PDF(topic.id));
	};

	const handleTakeQuiz = () => {
		history.push(QUIZ(topic.id));
	};

	return (
		<div className="topic-vide-container">
			<Loader loading={loading} />

			<div className="topic-redirect">
				<div className="topic-top watch-video">
					<p>Read Topic lecture</p>
					<Button primary onClick={handleRead}>
						Read
					</Button>
				</div>
				<div className="topic-top test-model">
					<p>Ready to test yourself?Take a quiz</p>
					<Button primary onClick={handleTakeQuiz}>
						Take
					</Button>
				</div>
			</div>
			<div className="topic-video-player-container">
				<BackButton text="Back to Module" />
				{topic ? (
					<div className="vimeo-player">
						<p>TOPIC</p>
						<h2>{topic.name}</h2>
						<Vimeo
							video={
								topic?.videoUrl
							}
							className="video-player"
                            style={{ maxWidth: "100%" }}
                            onLoaded={() => setLoading(false)}
							responsive
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default withRouter(MetaContainer(ModuleContainer(Video)));
