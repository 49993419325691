import {INVOKE_TOASTER, REMOVE_TOAST, SET_TOAST} from "../definitions/toastConstants";

export function invokeToastAction(type: string, message: any) {
    return {
        type: INVOKE_TOASTER,
        payload: {
            type,
            message
        }
    }
}
