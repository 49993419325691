import {
  LIST_BLOGS,
  GET_BLOG_CONTENT,
  LIST_BLOGS_BY_PAGE,
} from "../definitions/blogConstants";

export function getAllBlogs(successCallback: Function, errCallback: Function) {
  return {
    type: LIST_BLOGS,
    payload: {
      successCallback,
      errCallback,
    },
  };
}

export function getBlogById(
  blogId: number,
  successCallback: Function,
  errCallback: Function
) {
  return {
    type: GET_BLOG_CONTENT,
    payload: {
      blogId,
      successCallback,
      errCallback,
    },
  };
}

export function getBlogsByPage(
  pageData: { pageNumber: number },
  successCallback: Function,
  errCallback: Function
) {
  return {
    type: LIST_BLOGS_BY_PAGE,
    payload: {
      pageData,
      successCallback,
      errCallback,
    },
  };
}
