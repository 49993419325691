import React from "react";
import {
  Router,
  Route,
  Switch,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import AppHeader from "../shared/components/AppHeader";
import AppSidebar from "../shared/components/AppSidebar";
import AppToast from "../shared/components/AppToast";
import { ToastProvider } from "react-toast-notifications";
import { requireAuth } from "../shared/components/HOC/requireAuth";
import { createBrowserHistory } from "history";
import ScrollToTop from "../shared/components/ScrollToTop/ScrollToTop";
import Dashboard from "../views/Dashboard";
import MockExam from "../views/MockExam";
import Module from "../views/ModulesLectures";
import Analytics from "../views/Analytics";
import DrugChart from "../views/DrugChart";
import PurchaseCourse from "../views/PurchaseCourse";
import CoursePage from "../views/Website/CoursePage";
import PurchaseCourses from "../views/Website/PurchaseCourse";
import ListBlog from "../views/Website/Blog/ListBlog";
import profile from "../views/Profile";
import ShowBlog from "../views/Website/Blog/ShowBlog";

export const appHistory = createBrowserHistory();

const routes = [
  {
    exact: false,
    path: appRoutes.AUTH,
    component: AuthWrapper,
    authenticated: false,
  },
  {
    exact: true,
    path: appRoutes.HOME,
    component: Dashboard,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.MOCK_EXAMS,
    component: MockExam,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.MODULES_LECTURES,
    component: Module,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.ANALYTICS,
    component: Analytics,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.DRUGS_CHART,
    component: DrugChart,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.PURCHASE_COURSE,
    component: PurchaseCourse,
    authenticated: true,
  },
  {
    exact: false,
    path: appRoutes.LIST_BLOG,
    component: ListBlog,
    authenticated: false,
  },
  {
    exact: false,
    path: "/show-blog/:id",
    component: ShowBlog,
    authenticated: false,
  },
  {
    exact: false,
    path: appRoutes.PROFILE,
    component: profile,
    authenticated: true,
  },
];

function AppRoutes() {
  return (
    <BrowserRouter>
      <AppHeader />
      <AppSidebar />
      <ToastProvider>
        <AppToast />
      </ToastProvider>
      <ScrollToTop>
        <Switch>
          {routes.map((route, i) => {
            return (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                component={
                  route.authenticated
                    ? requireAuth(route.component)
                    : route.component
                }
              />
            );
          })}
          <Redirect to={appRoutes.LOGIN} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default AppRoutes;
