import { SEARCH } from "../definitions/metaConstants";
import {
	LIST_COUNTRY_META,
	LIST_COURSE_META,
	SET_SELECTED_COURSE,
	SET_USER_COURSE,
	UPDATE_SELECTED_COURSE,
} from "../definitions/metaConstants";
import { Course } from "../models/Meta/CourseMeta/CourseMeta.model";

export function search(
	search: string,
	courseId: number,
	successCallback: Function,
	errorCallback: Function
) {
	return {
		type: SEARCH,
		payload: {
			search,
			courseId,
			successCallback,
			errorCallback,
		},
	};
}

export function getCountriesAction(
	successCallback: Function,
	errorCallback: Function
) {
	return {
		type: LIST_COUNTRY_META,
		payload: {
			successCallback,
			errorCallback,
		},
	};
}

export function getCoursesAction(
	successCallback: Function,
	errorCallback: Function
) {
	return {
		type: LIST_COURSE_META,
		payload: {
			successCallback,
			errorCallback,
		},
	};
}

export function setSelectedCourse(data: Course) {
	return {
		type: SET_SELECTED_COURSE,
		payload: data,
	};
}

export function getUserCourse(successCallback: Function, errCallback: Function) {
	
	return {
		type: SET_USER_COURSE,
		payload: {
			successCallback,
			errCallback,
		}
	}
}

export function updateSelectedCourse(courseId: number, successCallback: Function, errCallback: Function) {
	return {
		type: UPDATE_SELECTED_COURSE,
		payload: {
			courseId,
			successCallback,
			errCallback,
		}
	}
}


