import React, { useEffect, useState, Fragment } from "react";
import "./examDescription.scss";
import ModuleContainer from "../../../store/containers/moduleContainer";
import { RouteComponentProps } from "react-router-dom";
import MetaContainer from "../../../store/containers/metaContainer";
import { ExamAttempt } from "../../../store/models/Module/Attempt.model";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import BackButton from "../../../shared/components/BackButton";
import ModeContent from "./ModeContent";
import Button from "../../../shared/components/Button";
import { localStorageClean } from "../../MockExam/ExamDescription";

type Mode = "study_mode" | "exam_mode";

const studyMode = [
  <span className="text-bold">TIMER IS OFF</span>,
  <span>Answers &amp; explanations appear after answering EACH question</span>,
];

export const examMode = [
  <span>
    <span className="text-bold">TIMER activated - </span>using the same question
    to time ratio as the real exam
  </span>,
  <span>
    Right click an answer option to
    <span className="text-bold"> STRIKE IT OUT</span>
  </span>,
  <span>
    Highlight &amp; right click to
    <span className="text-bold"> HIGHLIGHT the text </span>
  </span>,
  <span>
    Answers available <span className="text-bold"> after </span> the quiz is
    completed
  </span>,
];

export const allQuestionStatus = [
  <span>
    <span className="text-bold">All </span>quiz questions will be included
  </span>,
  <span>
    Quiz scores calculated as part of
    <span className="text-bold"> ALL </span>results in
    <span className="text-bold"> Performance </span>
    when taken in exam mode
  </span>,
];

export const selectiveQuestionsStatus = [
  <span>
    Only questions previously answered
    <span className="text-bold"> incorrectly </span>and
    <span className="text-bold"> unseen </span> will be included
  </span>,
  <span>
    Quiz scores calculated as part of
    <span className="text-bold"> 'Completion' </span>results in Performance when
    taken in exam mode
  </span>,
];

interface IExamDescription extends RouteComponentProps {
  createQuizAttempt: Function;
  course: any;
  getAllQuestions: Function;
  getFilteredAttempt: Function;
  createFilterAttempt: Function;
}

function ExamDescription({
  history,
  location,
  match: { params },
  createQuizAttempt,
  course,
  getAllQuestions,
  getFilteredAttempt,
  createFilterAttempt,
}: IExamDescription) {
  const [mode, setMode] = useState<string>("Study Mode");
  const [status, setStatus] = useState<string>("All");
  const [disabled, setDisabled] = useState<boolean>(true);
  const nos: any = location.state;

  useEffect(() => {
    const param: any = params;
    if (course?.id) {
      getFilteredAttempt(
        course?.id,
        param?.id,
        mode === "Study Mode" ? "study_mode" : "exam_mode",
        (nos: any) => {
          if (nos) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        },
        () => {}
      );
    }
  }, [course, mode]);

  const handleExamMode = (mode: Mode) => {
    const param: any = params;
    const payload = {
      quiz_attempt: {
        quiz_mode: mode,
      },
    };
    if (course) {
      createQuizAttempt(
        course.id,
        param.id,
        payload,
        (createdAttempt: ExamAttempt) => {
          getAllQuestions(
            course.id,
            createdAttempt.id,
            () => {
              history.push(
                `${AppRoutes.MOCK_EXAMS_EXAM}?type=${createdAttempt.quizMode}`
              );
              localStorageClean();
            },
            () => {}
          );
        },
        () => {}
      );
    }
  };

  const handleExam = () => {
    const param: any = params;

    if (mode === "Study Mode" && status === "All") {
      handleExamMode("study_mode");
      return;
    }
    if (mode === "Exam Mode" && status === "All") {
      handleExamMode("exam_mode");
      return;
    }

    if (course) {
      const payload = {
        quiz_attempt: {
          quiz_mode: mode === "Study Mode" ? "study_mode" : "exam_mode",
        },
      };
      createFilterAttempt(
        course.id,
        param.id,
        payload,
        (createdAttempt: ExamAttempt) => {
          getAllQuestions(
            course.id,
            createdAttempt.id,
            () => {
              history.push(
                `${AppRoutes.MOCK_EXAMS_EXAM}?type=${createdAttempt.quizMode}`
              );
              localStorageClean();
            },
            () => {}
          );
        },
        () => {}
      );
    }
  };

  return (
    <div className="exam-description-content">
      <div className="back-btn__container">
        <BackButton text="Back to Modules" classes="back-btn" />
      </div>
      <div className="exam-description__container">
        <div className="exam-description__wrapper exam-description__layout">
          <div className="header">
            <p>TOPIC {nos?.no}</p>
            <h2>{nos?.topicName}</h2>
          </div>
          <div className="start-desc">
            <p>ACS</p>
          </div>
          <div className="create-quiz">
            <h2>Customize Your Quiz</h2>

            <div className="choose-mode">
              <div className="text">
                <p>Choose mode:</p>
              </div>
              <div className="mode-content-wrapper">
                <ModeContent
                  type="mode"
                  text="Study Mode"
                  active={mode === "Study Mode"}
                  updateClickHandler={(value) => setMode(value)}
                  left
                />
                <ModeContent
                  type="mode"
                  text="Exam Mode"
                  active={mode === "Exam Mode"}
                  updateClickHandler={(value) => setMode(value)}
                />
              </div>
            </div>
            <div className="list-description list-description-1">
              <ul className="list-options">
                {(mode === "Study Mode" ? studyMode : examMode)?.map(
                  (option, index) => (
                    <li key={index}>{option}</li>
                  )
                )}
              </ul>
            </div>
            <div className="choose-mode">
              <div className="text">
                <p>Question Inclusion:</p>
              </div>
              <div className="mode-content-wrapper">
                <ModeContent
                  type="mode"
                  text="All"
                  active={status === "All"}
                  updateClickHandler={(value) => setStatus(value)}
                  left
                />
                <ModeContent
                  type="mode"
                  text={"Incorrect & Unseen"}
                  active={status === "Incorrect & Unseen"}
                  updateClickHandler={(value) => setStatus(value)}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="list-description">
              <ul className="list-options">
                {(status === "All"
                  ? allQuestionStatus
                  : selectiveQuestionsStatus
                )?.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
            <div className="start-quiz-btn" onClick={handleExam}>
              <p>{"Start Quiz"}</p>
              <i className="right arrow icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleContainer(MetaContainer(ExamDescription));
