import React, { Component } from "react";
import "./card.scss";

interface ICard {
	title: string;
	btnText?: string;
	titleWhite?: boolean;
	children?: any;
	secondary?: boolean;
	clickHandler?: () => void;
}

function Card({
	title,
	btnText = "",
	children,
	secondary = false,
	titleWhite = false,
	clickHandler = () => {},
}: ICard) {
	return (
		<div className="card-container">
			<div
				className={`card-container__header ${
					secondary ? "card-secondary" : btnText && "space-between" 
				} ${titleWhite && "white-color-title"}`}
			>
				<p>{title}</p>
				{btnText ? (
					<div className="btn-preset">
						<p onClick={clickHandler}>{btnText}</p>
					</div>
				) : null}
			</div>
			<div className="card-container__body">{children}</div>
		</div>
	);
}

export default Card;
